import Homepage from "../pages/Home/Homepage";
import StartPhase from "../pages/StartingPhase/StartingPhase";
import FirstVerificationPhase from "../pages/FirstVerificationPhase/FirstVerificationPhase";
import DataAnalysis from "../pages/DataAnalysis/DataAnalysis";
import FinalResults from "../pages/FinalResults/FinalResults";

export const NAV_LINKS = [
  // {
  //   id: 1,
  //   label: "الرئيسية",
  //   path: "/bi/home",
  //   icon: "homepage",
  //   component: Homepage,
  // },
  {
    id: 1,
    label: "التقييم النهائى",
    path: "/bi/final-results",
    icon: "final-results",
    component: FinalResults,
  },
  // {
  //   id: 3,
  //   label: "تحليل البيانات",
  //   path: "/bi/data-analysis",
  //   icon: "data-analysis",
  //   component: DataAnalysis,
  // },
  // {
  //   id: 4,
  //   label: "المراحل",
  //   icon: "steps",
  //   children: [
  //     {
  //       id: 1,
  //       label: "مرحلة بدء النظام",
  //       path: "/bi/starting-phase",
  //       icon: "starting-phase",
  //       component: StartPhase,
  //     },
  //     {
  //       id: 2,
  //       label: "مرحلة التحقق الأولى",
  //       path: "/bi/first-verification",
  //       icon: "first-verification",
  //       component: FirstVerificationPhase,
  //     },
  //   ],
  // },
];
