import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import AppButton from "../../../components/buttons/appButton";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import {
  isAdmin,
  isExpertManager
} from "../../../helpers/isPermission";
import environment from "../../../services/environment";
import { axiosGetRequest, axiosPostRequest } from "../../../services/Request";

const AssignStandardToExpertManager = ({
  showAssignModal,
  setShowAssignModal,
  standardId,
}) => {
  const { surveyId } = useParams();
  console.log(surveyId, "SURVEY ID");
  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [selectedPOCOfficers, setSelectedPOCOfficers] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reviewers, setReviewers] = useState([]);
  const [experts, setExperts] = useState([]);
  const [pocOfficers, setPocOfficers] = useState([]);


  console.log(experts, "EXPERTS");
  console.log(reviewers, "REVIEWERS");

  const handleChangeExperts = (value) => {
    setSelectedExperts(value || []);
  };
  const handleChangeReviewers = (value) => {
    setSelectedReviewers(value || []);
  };

  const handleChangePOCOfficers = (value) => {
    setSelectedPOCOfficers(value || []);
  };

  useEffect(() => {
    if (isAdmin || isExpertManager) {
      getExperts();
      getReviewers();
      getPOCOfficers();
    }

    if (standardId) {
      setSelectedExperts([]);
      setSelectedReviewers([]);
      setSelectedPOCOfficers([]);
      getAssignedUsers();
    }
  }, [standardId]);

  const getExperts = async () => {
    const res = await axiosGetRequest(environment.getAllExpertLookUp);
    let arr = [];
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });
    setExperts(arr);
    if (res?.success) {
    }
  };

  const getPOCOfficers = async () => {
    const res = await axiosGetRequest(environment.getAllPocOfficerLookUp);
    let arr = [];
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });
    setPocOfficers(arr);
    if (res?.success) {
    }
  };

  const getReviewers = async () => {
    const res = await axiosGetRequest(environment.getAllReviewersLookUp);
    let arr = [];
    //  setTotalPages(res.data.result.totalCount / 6)
    res?.result?.items?.forEach((element) => {
      let obj = {
        id: element.id,
        value: element.id,
        label: element.fullName,
      };
      arr.push(obj);
    });

    setReviewers(arr);
    if (res?.success) {
    }
  };

    useEffect(()=>{
        if(surveyId){
          getAssignedUsers()
        }
    },[])

  const getAssignedUsers = async () => {
    // setLoading(true);
    const res = await axiosGetRequest(
      environment.getExpertAndReviewerAndPocOfficerByStandardId,
      {
        // surveyId: localStorage.getItem("surveyId"),
        // MaxResultCount: 1000000,
        // SkipCount: 0 * 5,
        surveyId,
      }
    );

    if (res?.success) {
      // res?.result?.items.forEach((item,index)=>{
      let newExperts = [];
      let newPocOfficers = [];
      let newReviewers = [];
      res?.result?.assignedExperts.forEach((expert, i) => {
        newExperts.push({
          label: expert.fullName,
          value: expert.id,
          id: expert.id,
        });
      });
      setSelectedExperts(newExperts);
      res?.result?.assignedPocOfficiers.forEach((pocOfficer, j) => {
        newPocOfficers.push({
          label: pocOfficer.fullName,
          value: pocOfficer.id,
          id: pocOfficer.id,
        });
      });
      setSelectedPOCOfficers(newPocOfficers);
      res?.result?.assignedReviewer.forEach((reviewer, j) => {
        newReviewers.push({
          label: reviewer.fullName,
          value: reviewer.id,
          id: reviewer.id,
        });
      });
      setSelectedReviewers(newReviewers);
      console.log(newReviewers);
      console.log(newExperts);
      console.log(newPocOfficers);

      // })
      console.log(res?.result);
      // setAssignedExperts(res?.result?.items);
      // setAssignedPocOfficers(res?.result?.items);
      // setAssignedReviewers(res?.result?.items);

      // setCount(res?.result?.totalCount);
    }
    // setLoading(false);
  };

  const assignStandardToReviewer = async () => {
    setLoading(true);
    let newAssignedReviewers = [];
    selectedReviewers.forEach((reviewer, index) => {
      newAssignedReviewers.push({ reviewerId: reviewer.id });
    });
    let data = {
      // standardId: standardId,
      surveyId,
      assignedReviewers: newAssignedReviewers,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToReviewer,
      data
    );
    if (res?.success) {
      let arr = [];
      //  setTotalPages(res.data.result.totalCount / 6)
      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setReviewers(arr);
      toast.success("تم تعيين مدققين بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      // successToast("تم إضافة المعيار للمدقق");
    }
  };
  const assignStandardToExpert = async () => {
    setLoading(true);
    let newAssignedExperts = [];
    // console.log(assignedExperts)
    selectedExperts.forEach((expert, index) => {
      newAssignedExperts.push({ expertId: expert.id });
    });
    let data = {
      surveyId,
      assignedExperts: newAssignedExperts,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToExpert,
      data
    );
    if (res?.success) {
      let arr = [];
      //  setTotalPages(res.data.result.totalCount / 6)
      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setExperts(arr);
      toast.success("تم تعيين خبراء بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      // successToast("تم إضافة المعيار للخبير");
    }
  };

  const assignStandardToPOCOfficer = async () => {
    setLoading(true);
    let newAssignedPOCOfficers = [];
    selectedPOCOfficers.forEach((reviewer, index) => {
      newAssignedPOCOfficers.push({ pocOfficierId: reviewer.id });
    });
    let data = {
      surveyId,
      assignedPocOfficiers: newAssignedPOCOfficers,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToPocOfficer,
      data
    );
    if (res?.success) {
      let arr = [];
      //  setTotalPages(res.data.result.totalCount / 6)
      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setPocOfficers(arr);
      toast.success("تم تعيين ضباط اتصال بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false, setShowAssignModal(false));
      // successToast("تم إضافة المعيار للمدقق");
    }
  };

  //   const handleRemoveItemFromList = () => {
  //     getAllStanderds();
  //   };
  const handleAssignStandard = () => {
    // setLoading(true);
    if (selectedExperts && selectedExperts.length != 0) {
      assignStandardToExpert();
    }
    if (selectedReviewers && selectedReviewers.length != 0) {
      assignStandardToReviewer();
    }
    if (selectedPOCOfficers && selectedPOCOfficers.length != 0) {
      assignStandardToPOCOfficer();
    }
    // setLoading(false);
    // if (!loading) setShowAssignModal(false);
  };
  return (
    <div>
      {/* <AppModal
          handleSubmit={handleAssignStandard}
          open={showAssignModal}
          setOpen={setShowAssignModal}
          loading={loading}
          submitLabel="إضافة"
        > */}
      <NewDropdownMultiSelect
        label={"الخبراء"}
        placeholder={"اختر خبراء"}
        options={experts}
        handleChange={handleChangeExperts}
        value={selectedExperts}
        isMultiple={true}
      />
      {/* <div className="text-sm text-red-600 mt-3">
            {errors.experts && touched.experts && errors.experts}
          </div> */}
      <div className="mt-3">
        <NewDropdownMultiSelect
          label={"المدققين"}
          placeholder={"اختر مدققين"}
          options={reviewers}
          handleChange={handleChangeReviewers}
          value={selectedReviewers}
          isMultiple={true}
        />
      </div>

      <div className="mt-3">
        <NewDropdownMultiSelect
          label={"ضباط الاتصال"}
          placeholder={"اختر ضباط اتصال"}
          options={pocOfficers}
          handleChange={handleChangePOCOfficers}
          value={selectedPOCOfficers}
          isMultiple={true}
        />
      </div>
      <div className="flex py-4 justify-end">
        <AppButton
          onClick={handleAssignStandard}
          // type={type}
          variant="purple"
          loading={loading}
        >
          {"حفظ"}
        </AppButton>
      </div>
      {/* </AppModal> */}
    </div>
  );
};
export default AssignStandardToExpertManager;
