import procedures from "../../../../../../assets/icons/procedures.svg";
import { isAgency } from "../../../../../../helpers/isPermission";
import { useAnswerContext } from "../answerContext";

import SaveBtn from "../components/buttons/saveBtn";

const StandardAnswerNotes = ({ createAnswer, children }) => {
  const { createAnswerLoading } = useAnswerContext();

  return (
    <>
      <div className="min-h-[440px]  p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={procedures}
              alt="procedures"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              {" "}
              ملاحظات التدقيق
            </h1>
          </div>
        </div>

        <div className="w-[100%] mt-7 pr-[88px] flex flex-col items-start overflow-y-auto scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100 scrollGroup">
          {children}
        </div>
      </div>
      {!isAgency() && (
        <SaveBtn onClick={createAnswer} loading={createAnswerLoading} />
      )}

      {/* {permissionsCheck("Pages.UserType.Reviewer") && (
        <SaveBtn onClick={addAnswerReviwer} />
      )} */}
      {/* {(permissionsCheck("Pages.UserType.Expert") ||
        permissionsCheck("Pages.UserType.ExpertManager")) && (
        <SaveBtn onClick={addAnswerExpert} />
      )} */}
      {/* <SaveBtn onClick={addAnswerExpert} /> */}
    </>
  );
};

export default StandardAnswerNotes;
