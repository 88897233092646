import { produce } from "immer";
import { useContext, useState } from "react";
import {
  AppInput,
  DropdownMultiSelect,
  InputLabel,
} from "../../../../../../../components";
import AppAccordion from "../../../../../../../components/appAccordion";
import AppButton from "../../../../../../../components/buttons/appButton";
import { AddStandardContext } from "../../addStandardContext";
import { toast } from "react-toastify";

const StandardSubRequirementItem = ({
  index,
  title,
  recommendation,
  answerMandatory,
  haveAttachments,
  requirementType,
  requirementIndx,
  numOfTextFields,
  number,
  disabled,
}) => {
  const [subRequirement, setSubRequirement] = useState({
    title,
    recommendation,
    answerMandatory,
    haveAttachments,
    requirementType,
    numOfTextFields,
  });

  console.log("subRequirement", subRequirement);
  const { classifications, setSubRequirementCount, setAddFinalValue } =
    useContext(AddStandardContext);
  const [selectedClassifications, setSelectedClassifications] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
          label: item.title,
          value: item.id,
          id: item.id,
        }))
      : [];

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    setSubRequirement({
      ...subRequirement,
    });
  };

  const handleChange = (e) => {
    setSubRequirement({ ...subRequirement, [e.target.name]: e.target.value });
  };

  const handleChangeSubStandardRequirementAnswerMandatory = (value) => {
    setSubRequirement({
      ...subRequirement,
      answerMandatory: value.value,
    });
  };

  const handleChangeSubStandardRequirementHaveAttachments = (value) => {
    setSubRequirement({
      ...subRequirement,
      haveAttachments: value.value,
    });
  };

  const handleNumberOfTextBoxes = (e) => {
    setSubRequirement({
      ...subRequirement,
      numOfTextFields: e.target.value,
    });
  };

  const handleSubmit = () => {
    setAddFinalValue(
      produce((draft) => {
        draft.standardRequirements[requirementIndx].subStandardRequirements[
          index
        ] = subRequirement;
      })
    );
    // show toaster
    toast.success("تم تعديل السؤال بنجاح");
    setIsOpen(true);
  };

  const handleDeleteClick = () => {
    setSubRequirementCount((prev) => prev - 1);
    setAddFinalValue(
      produce((draft) => {
        const deleteItem = draft.standardRequirements[
          requirementIndx
        ].subStandardRequirements.filter(
          (item, itemIndex) => itemIndex != index
        );
        draft.standardRequirements[requirementIndx].subStandardRequirements =
          deleteItem;
      })
    );
  };

  return (
    <div className="mt-4">
      <AppAccordion
        number={number}
        title={title}
        variant="purple"
        handleDeleteClick={handleDeleteClick}
        setIsOpen={() => setIsOpen(true)}
        isOpen={isOpen}
      >
        <div className=" p-5 pb-7 rounded-lg">
          <h2 className="font-semibold mb-4">تعديل السؤال</h2>
          <div className="row gap-y-3">
            <div className="col-md-5 ">
              <AppInput
                variant="gray"
                label="عنوان السؤال"
                placeholder="اكتب هنا"
                name="title"
                value={subRequirement.title}
                onChange={handleChange}
                // errorMessage={errors.title}
              />
            </div>
            <div className="col-md-5 ">
              <AppInput
                variant="gray"
                label="وصف السؤال"
                placeholder="اكتب هنا"
                name="recommendation"
                value={subRequirement.recommendation}
                onChange={handleChange}
                // errorMessage={errors.title}
              />
            </div>
            <div className="col-md-5">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={
                  [
                    { label: "اجباري", value: 1 },
                    { label: "اختياري", value: 2 },
                  ].find(
                    (item) => item.value === subRequirement.answerMandatory
                  ) || ""
                }
                handleChange={handleChangeSubStandardRequirementAnswerMandatory}
                label="هل الإجابة على السؤال اختياري، أم إجباري"
                placeholder="اجباري"
                options={[
                  { label: "اجباري", value: 1 },
                  { label: "اختياري", value: 2 },
                ]}
                isMultiple={false}
                isRequired={true}
              />
            </div>
            <div className="col-md-5">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={
                  [
                    { label: "نعم او لا ", value: 1 },
                    { label: "نص ", value: 2 },
                  ].find(
                    (item) => item.value === subRequirement.requirementType
                  ) || ""
                }
                handleChange={handleChangeSubStandardRequirementAnswerMandatory}
                label="نوع السؤال"
                placeholder="اختار نوع السؤال"
                options={[
                  { label: "نعم او لا ", value: 1 },
                  { label: "نص ", value: 2 },
                ]}
                isMultiple={false}
                isRequired={true}
              />
            </div>
            {subRequirement.requirementType === 2 && (
              <div className="col-md-5">
                <InputLabel
                  min={1}
                  value={subRequirement.numOfTextFields}
                  type={"number"}
                  onChange={handleNumberOfTextBoxes}
                  name={"numOfTextFields"}
                  label={"عدد النصوص"}
                />
              </div>
            )}
            <div className="col-md-5">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                value={
                  [
                    { label: "نعم", value: 1 },
                    { label: "لا", value: 2 },
                  ].find(
                    (item) => item.value === subRequirement.haveAttachments
                  ) || ""
                }
                handleChange={handleChangeSubStandardRequirementHaveAttachments}
                label="هل له مرفقات"
                options={[
                  { label: "نعم", value: 1 },
                  { label: "لا", value: 2 },
                ]}
                isMultiple={false}
                isRequired={true}
                // errorMessage={errors?.agencyStandardRequirements}
              />
            </div>
          </div>
          <div className="row gap-y-4 mt-8">
            <div className="col-xl-3 align-self-end">
              <div className="text-end">
                <AppButton onClick={handleSubmit}>تعديل السؤال</AppButton>
              </div>
            </div>
          </div>
        </div>
      </AppAccordion>
    </div>
  );
};

export default StandardSubRequirementItem;
