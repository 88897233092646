// import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import Results from "../Results/Results";
import SimulationResults from "../SimulationResults/SimulationResults";
import SelectFilterInput from "../../components/SelectFilterInput/SelectFilterInput";

const RESULTS_LINKS = [
  {
    id: 1,
    label: "التقييم النهائي",
    path: "/bi/final-results/all",
    icon: "",
    component: Results,
  },
  {
    id: 2,
    label: "محاكاة",
    path: "/bi/final-results/simulation",
    icon: "",
    component: SimulationResults,
  },
];

const OPTIONS = [
  {
    id: 1,
    label: "وزارة العدل",
    value: "ministry_of_justice",
  },
  {
    id: 2,
    label: "وزارة الموارد البشرية والتنمية الاجتماعية",
    value: "ministry_of_human_resources",
  },
  {
    id: 3,
    label: "هيئة الاتصالات والفضاء والتقنيه",
    value: "communications_and_technology",
  },
  {
    id: 4,
    label: "جامعة القصيم",
    value: "qassim_university",
  },
];

const FinalResults = ({ year }) => {
  const [currentOption, setCurrentOption] = useState(null);
  // const { data, isLoading, isError } = useQuery({
  //   queryKey: "finalResults",
  //   queryFn: () =>
  //     fetch(
  //       `${process.env.REACT_APP_BI_URL}DashboardStatistics/GetFinalResultOfAgencyOverYear?surveyId=174`
  //     ).then((res) => res.json()),
  // });
  // if (isLoading) return <div>Loading...</div>;
  // if (isError) return <div>Error</div>;
  // console.log(data);

  return (
    <div className="flex-1">
      <div className="bg-blue mx-8 h-[52px] flex items-center">
        <div className="flex items-center gap-2 h-full">
          {RESULTS_LINKS.map((link) => (
            <NavLink
              key={link.id}
              to={link.path}
              className="h-full inline-flex justify-center items-center py-4 px-8 rounded-t-3xl text-white leading-5 text-xl font-semibold pt-1 border-2 border-b-0 border-bi_purple_light"
              activeClassName="bg-white !text-blue !border-0"
            >
              {link.label}
            </NavLink>
          ))}
        </div>
        <div className="flex gap-4 item-center ms-auto">
          <span className="text-white leading-5 text-xl font-semibold pt-1">
            الجهات الحكومية
          </span>
          <div className="w-[300px]">
            <SelectFilterInput
              options={OPTIONS}
              currentValue={currentOption}
              setFilter={setCurrentOption}
              placeholder="اختر الجهات الحكومية"
              searchInputPlaceholder="أبحث"
            />
          </div>
        </div>
      </div>
      <div className="bg-white rounded-tr-[32px] px-8 py-6 overflow-auto h-full max-h-[calc(100vh-156px)]">
        <Switch>
          {RESULTS_LINKS.map((link) => (
            <Route
              key={link.id}
              path={link.path}
              render={(props) => <link.component {...props} year={year} />}
            />
          ))}
          <Redirect to="/bi/final-results/all" />
        </Switch>
      </div>
    </div>
  );
};

export default FinalResults;
