import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { NAV_LINKS } from "../../models/SideNav";
import QiyasLogo from "../../../../assets/icons/BI/qiyas-logo.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/BI/arrow-left.svg";
import NavigationIcon from "../NavigationIcon";
import { ReactComponent as Chevron } from "../../../../assets/icons/BI/chevron.svg";

const SideNav = () => {
  const [linkId, setLinkId] = useState(null);

  const handleNavCollapse = (navLink) => {
    setLinkId(linkId === navLink.id ? null : navLink.id);
  };

  return (
    <>
      <aside className="w-[268px] bg-white flex flex-col gap-4 z-20">
        <Link to="/bi/home" className="px-8 py-6">
          <img
            src={QiyasLogo}
            alt="قياس التقنيات الناشئة"
            title="قياس التقنيات الناشئة"
          />
        </Link>
        <ul className="flex flex-col gap-2">
          {NAV_LINKS.map((navLink) => {
            return (
              <li key={navLink.id} className="px-4">
                {navLink.children ? (
                  <>
                    <button
                      type="button"
                      className="w-full cursor-pointer px-4 py-5 flex gap-6 items-center justify-between rounded-xl hover:bg-gray-200"
                      onClick={() => handleNavCollapse(navLink)}
                    >
                      <div className="flex items-center gap-4">
                        <NavigationIcon
                          icon={navLink.icon}
                          iconType={
                            navLink.icon === "final-results" ? "stroke" : "fill"
                          }
                        />
                        <span className="inline-block pb-2 font-semibold text-lg leading-5">
                          {navLink.label}
                        </span>
                      </div>
                      <div className="w-6 h-6 justify-center items-center flex">
                        <Chevron
                          className={`${
                            linkId === navLink.id ? "-rotate-90" : "rotate-0"
                          } transition duration-300`}
                        />
                      </div>
                    </button>
                    <ul
                      className={`${
                        linkId === navLink.id ? "max-h-96 pb-5" : "max-h-0 pb-0"
                      } overflow-hidden flex flex-col gap-2 transition-all duration-700 pt-2`}
                    >
                      {navLink.children.map((child) => (
                        <li key={child.id} className="ps-6 pe-2">
                          <NavLink
                            to={child.path}
                            className="group px-4 py-2 flex gap-6 items-center justify-between rounded-xl hover:bg-gray-200  [&.active]:shadow-[0px_12px_15px_0px_#450B5A21] [&.active]:bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.06%)] [&.active]:text-white"
                          >
                            <div className="flex items-center gap-4">
                              <NavigationIcon icon={child.icon} />
                              <span className="inline-block pb-2 font-medium text-base leading-5 w-max">
                                {child.label}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <NavLink
                    to={navLink.path}
                    className="group px-4 py-5 flex gap-6 items-center justify-between rounded-xl hover:bg-gray-200  [&.active]:shadow-[0px_12px_15px_0px_#450B5A21] [&.active]:bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.06%)] [&.active]:text-white"
                  >
                    <div className="flex items-center gap-4">
                      <NavigationIcon
                        icon={navLink.icon}
                        iconType={
                          navLink.icon === "final-results" ? "stroke" : "fill"
                        }
                      />
                      <span className="inline-block pb-2 font-semibold text-lg leading-5">
                        {navLink.label}
                      </span>
                    </div>
                    <div className="w-6 h-6 justify-center items-center flex">
                      <LeftArrow className="group-[.active]:[&_path]:fill-white [&_path]:fill-blue" />
                    </div>
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      </aside>
    </>
  );
};

export default SideNav;
