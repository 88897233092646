import NavigationIcon from "../NavigationIcon";

const Header = ({ link }) => {
  const { label = "", icon = "" } = link;
  return (
    <header
      className="relative rounded-br-[32px] h-20 flex items-center bg-white py-[30px] px-8 gap-6"
      title={label}
    >
      <NavigationIcon
        icon={icon}
        iconType={icon === "final-results" ? "stroke" : "fill"}
      />
      <h1 className="font-semibold text-3xl text-blue pb-3">{label}</h1>
    </header>
  );
};

export default Header;
