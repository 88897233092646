import { useTranslation } from "react-i18next";
import NewHeader from "../../../../layouts/header/NewHeader";
import { plusLight, programsIcon } from "../../../../assets/icons/Index";
import { DropdownMultiSelect, Title } from "../../../../components";
import { useContext, useEffect, useState } from "react";
import { get, post } from "../../../../services/Request";
import { useParams } from "react-router";
import NewTable from "../../../../components/NewTable";
import AppModal from "../../../../components/Models/appModal";
import environment from "../../../../services/environment";
import { toast } from "react-toastify";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import EditIcon from "../../../../components/EditIcon";
import { NewForm } from "../../../../components/form/NewForm";
import useExcelExport from "../../../../helpers/customHooks/useExcelExport";
import { StageContext } from "../../../../contexts/stageContext";
import { permissionsCheck } from "../../../../helpers/utils";
import AppSwitch from "../../../../components/form/appSwitch";

const Permissions = () => {
  const { id } = useParams();
  const surveyId = localStorage.getItem("surveyId");
  const { t } = useTranslation();
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { currentStage } = useContext(StageContext)

  const [selectedStandard, setSelectedStandard] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState("");

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [standards, setStandards] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  const [standardDelegates, setStandardDelegates] = useState([]);
  // const [isReopenedStandards, setIsReopenedStandards] = useState(false);
  const isThirdStage = currentStage?.displayOrder === 3;
  const stageNumber = currentStage?.displayOrder; 
  const [reOpenedStandardChecked, setReOpenedStandardChecked] = useState(
    (permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.Delegate")) && isThirdStage
  );


  const [users, setUsers] = useState([]);

  console.log(filteredData, "filteredData");

  const getStandardDelegate = (isReopenedStandards) => {
    setGetDataLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const searchPrams = new URLSearchParams();
    searchPrams.append("agencyId", id);
    searchPrams.append("surveyId", surveyId);
    searchPrams.append("isReopenedStandards", isReopenedStandards);
    const url =  environment.getStandardDelegates + "?" + searchPrams.toString();
        

    get(url, config,
      (res) => {
        setGetDataLoading(false);
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setStandardDelegates(res?.data?.result, getStandardAgency());
          setFilteredData(res?.data?.result);
        }
      }
    );
  };


  useEffect(() => {
    getStandardDelegate(
      reOpenedStandardChecked
    );
  }, [currentStage.displayOrder]);

  const getStandardAgency = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllStandard +
        "?MaxResultCount=" +
        6000 +
        "&AgencyId=" +
        id +
        "&SurveyId=" +
        localStorage.getItem("surveyId"),
      config,
      (res) => {
        if (res?.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res?.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res?.status == 200) {
          let newStandards = [];
          res?.data?.result?.items.forEach((standard, index) => {
            let obj = {
              label: standard.code,
              id: standard.id,
              value: standard.id,
            };
            newStandards.push(obj);
          });
          setStandards(newStandards, getAgencyUsers());
        }
      }
    );
  };

  const getAgencyUsers = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAgencyUsers + "?agencyId=" + id, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: "error", message: t("general.authError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: "error", message: t("general.serverError") },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        let newUsers = [];
        res.data.result.forEach((user, index) => {
          let obj = { label: user.fullName, id: user.id, value: user.id };
          newUsers.push(obj);
        });
        setUsers(newUsers);
      }
    });
  };

  const handleChangeStandard = (value) => {
    setSelectedStandard(value);
  };

  function handleChangeReOpenedStatus(value) {
    setReOpenedStandardChecked(value);
    if (value) {
      getStandardDelegate(value);
    } else {
      getStandardDelegate(value);
    }
  }


  const handleChangeUsers = (value) => {
    setSelectedUsers(value);
  };

  const addPermission = () => {
    setAddEditLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    if (!selectedStandard) {
      toast.error("يجب اختيار  معيار ");
    } else if (selectedUsers.length == 0 && !isEdit) {
      toast.error("يجب اختيار مستخدم واحد علي الاقل");
    } else {
      let arr = [];
      selectedUsers.forEach((element) => {
        let obj = { userId: element.value };
        arr.push(obj);
      });

      let data = {
        standardId: selectedStandard.id,
        standardDelegateUsers: arr,
        agencyId: id,
      };
      post(environment.createStandardDelegates, data, config, (res) => {
        setAddEditLoading(false);
        if (res.status == 500) {
          toast.error(res.data.error.message);
        } else {
          isEdit
            ? toast.success("تم تعديل صلاحية بنجاح")
            : toast.success("تم اضافة صلاحية بنجاح");

          setOpen(false);
          getStandardDelegate(reOpenedStandardChecked);
        }
      });
    }
  };

  const downloadExcel = useExcelExport();

  const dataForExcel = standardDelegates?.map((ele) => {
    return {
      "رقم المعيار": ele.code,
      "اسم المعيار": ele.title,
      " المستخدمون": ele?.delegates.map((ele) => ele.fullName).join(" - "),
    };
  });

  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(standardDelegates);
    } else {
      const filtered = standardDelegates.filter((ele) => {
        return (
          ele.code.includes(value) ||
          ele.title.toLowerCase().includes(value.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  };

  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={t("تفاصيل الجهة")}
        onClick={() => {
          setOpen(true);
          setIsEdit(false);
        }}
        subTitle={t("الصلاحيات")}
        textStyle={{ color: "white", fontSize: 18 }}
        seconed={true}
        // subTitleSeconed={agencyDetails?.nameInArabic || ""}
        titleButton={t("اضافة صلاحيات")}
        withoutButton={false}
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: "#2B2969", width: 200 }}
      />

      <AppModal
        loading={addEditLoading}
        handleSubmit={() => {
          addPermission();
        }}
        children={
          <div>
            <div>
              <DropdownMultiSelect
                label={"المعيار"}
                placeholder={"اختر معيار"}
                options={standards}
                handleChange={handleChangeStandard}
                value={selectedStandard}
                isMultiple={false}
              />
            </div>
            <div className="mt-5">
              <NewDropdownMultiSelect
                label={"المستخدمين"}
                placeholder={"اختر مستخدمين"}
                options={users}
                handleChange={handleChangeUsers}
                value={selectedUsers}
                isMultiple={true}
              />
            </div>
          </div>
        }
        open={open}
        setOpen={() => setOpen(false)}
        headerTitle={"اضافة صلاحيات"}
      />
      <div className="p-4">
      {(permissionsCheck("Pages.UserType.POC") ||
          permissionsCheck("Pages.UserType.CIO") ||
          permissionsCheck("Pages.UserType.Delegate")) && stageNumber === 3 ? (
            <div className="h-full flex items-center gap-2">
              <AppSwitch
                checked={reOpenedStandardChecked}
                handleChange={handleChangeReOpenedStatus}
              />
              <span>
                المعايير المعاد فتحها
              </span>
            </div>
          ) : null}
      </div>
      <div className="px-5">
        <NewTable
          withSearch
          withExcel
          onSearch={handleFilterData}
          onExportExcel={() => {
            downloadExcel("الصلاحيات", dataForExcel);
          }}
          loading={getDataLoading}
          data={filteredData}
          columns={[
            {
              name: "رقم المعيار",
              accessorKey: "code",
              selector: (row) => row.code,
              sortable: true,
              cell: (info) => (
                <div className="text-sm text-[#108AE0]">{info.code}</div>
              ),
            },
            {
              name: "اسم المعيار",
              accessorKey: "title",
              selector: (row) => row.title,
              sortable: true,
              cell: (info) => (
                <div className="text-sm text-[#292069]">{info.title}</div>
              ),
            },
            {
              name: "المستخدمون",
              selector: (row) => row.delegates,
              sortable: true,
              cell: (info) => (
                <div className="grid grid-cols-2">
                  {info.delegates.map((item, index) => (
                    <div className="flex p-1 bg-[#1F925433] justify-center items-center rounded m-1">
                      <span key={index} className="text-[10px] text-[#1F9254]">
                        {item.fullName}
                      </span>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              name: "الاجراءات",
              selector: (row) => row.actions,
              header: "actions",
              accessorKey: "actions",
              footer: (props) => props.column.actions,
              cell: (row) => {
                return (
                  <div className="flex gap-2">
                    <EditIcon
                      initialValuesFunction={() => {
                        setSelectedStandard({
                          label: row.title,
                          value: row.standardId,
                          id: row.standardId,
                        });
                        setIsEdit(true);
                        setSelectedUsers(
                          row?.delegates?.map((ele) => {
                            return { label: ele.fullName, value: ele.id };
                          })
                        );
                      }}
                      loading={addEditLoading}
                      editFunction={addPermission}
                      type={"submit"}
                      editedName={`معيار ${row.code}`}
                      editModalContent={
                        <>
                          <div className="mt-5">
                            <NewDropdownMultiSelect
                              label={"المستخدمين"}
                              placeholder={"اختر مستخدمين"}
                              options={users}
                              handleChange={handleChangeUsers}
                              value={selectedUsers}
                              isMultiple={true}
                            />
                          </div>
                        </>
                      }
                    />
                  </div>
                );
              },
            },
          ]}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Permissions;
