import {t} from "i18next";
import {useEffect, useState} from "react";
import {AppInput, DropdownMultiSelect} from "../../../../../components";
import {useParams} from "react-router";
import {post} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import {toast} from "react-toastify";
import AppButton from "../../../../../components/buttons/appButton";
import useAxiosGetRequest from "../../../../../helpers/customHooks/useAxiosGetRequest";
import {ErrorMessage, Form, Formik} from "formik";

const defaultClassificationsValue = {
  title: "",
  weight: "",
};

const AddClassification = ({
                             handleCloseAddClassification,
                             handleUpdateData,
                           }) => {
  const [loading, setLoading] = useState(false);
  const {surveyId} = useParams();

  const [selectedDeterminants, setSelectedDeterminants] = useState([]);

  // for multiSelect
  const [getAllDeterminants, allDeterminants] = useAxiosGetRequest();

  useEffect(() => {
    getAllDeterminants(environment.getAllDeterminants);
  }, []);

  const allDeterminantsOptions =
    allDeterminants?.result?.items &&
    allDeterminants?.result?.items?.map((item) => {
      return {label: item.title, value: item.id};
    });

  const [classification, setClassification] = useState(
    defaultClassificationsValue
  );

  const handleInputsChange = (e) => {
    setClassification({...classification, [e.target.name]: e.target.value});
  };

  const handleDeterminantsChange = (value) => {
    console.log(value);
    setSelectedDeterminants(value || []);
  };

  const clear = () => {
    setClassification(defaultClassificationsValue);
    setSelectedDeterminants([]);
  };

  const determinantsArray = selectedDeterminants?.map((ele) => {
    return {
      determinantId: ele.value,
    };
  });

  const addClassification = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    let data = {
      surveyId: surveyId,
      title: classification.title,
      weight: classification.weight,
      standardRequirementClassificationDeterminants: determinantsArray,
    };

    post(
      environment.createStandardRequirementClassification,
      data,
      config,
      (res) => {
        setLoading(false);
        if (res.status == 403) {
          toast.error("حدث خطأ");
        } else if (res.status == 500) {
          toast.error("حدث خطأ");
        } else if (res.status == 200) {
          toast.success("تم اضافة تصنيف بنجاح");
          handleUpdateData();
          clear();
        }
      }
    );
  };

  const initialValues = {
    title: "",
    weight: "",
    selectedDeterminants: [],
  };

  const validate = (values) => {
    const errors = {};
    if (!classification.title) {
      errors.title = "هذا الحقل مطلوب";
    }
    if (
      !classification.weight ||
      classification.weight < 0 ||
      classification.weight > 100
    ) {
      errors.weight = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";
    }
    if (!selectedDeterminants.length) {
      errors.selectedDeterminants = "هذا الحقل مطلوب";
    }

    return errors;
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={() => addClassification()}
      >
        {({handleChange, values}) => (
          <Form className="">
            <div className="w-full md:flex">
              <div className="m-3 md:w-1/2">
                <AppInput
                  id={`title-${0}-${classification.title}`}
                  label={t("التصنيف")}
                  value={classification.title}
                  name="title"
                  onChange={(e) => handleInputsChange(e)}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="title"
                  component="div"
                />
              </div>

              <div className="m-3 md:w-1/2">
                <AppInput
                  id={`title-${0}-${classification.weight}`}
                  label={t("الوزن")}
                  type="number"
                  value={classification.weight}
                  name="weight"
                  onChange={(e) => handleInputsChange(e)}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="weight"
                  component="div"
                />
              </div>
            </div>

            <div className="flex items-end">
              <div className="m-3 md:w-2/3">
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedDeterminants || []}
                  handleChange={handleDeterminantsChange}
                  placeholder=""
                  label="المحدد"
                  options={allDeterminantsOptions}
                  isMultiple={true}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="selectedDeterminants"
                  component="div"
                />
              </div>
              <div className="text-end mb-3 relative md:w-1/3">
                <AppButton type="submit" loading={loading}>
                  اٍضافة تصنيف جديد
                </AppButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddClassification;
