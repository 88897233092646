import React, { useContext, useState } from "react";
import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import { AddStandardContext } from "../addStandardContext";
import { produce } from "immer";
import { addStandardRequirementValidations } from "../addStandardValidation";
import {
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";

const AddStandardDimensions = ({ from }) => {
  const {
    addRequirmentsObj,
    setAddRequirmentsObj,
    selectedRequirementAgencies,
    setSelectedRequirementAgencies,
    setAddFinalValue,
    setSelectedRequirementAgenciesForEdit,
  } = useContext(AddStandardContext);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [errors, setErrors] = useState({
    title: "",
    recommendation: "",
    agencyStandardRequirements: "",
  });

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardRequirementValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
      setDisabledBtn(false);
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setAddRequirmentsObj({
      ...addRequirmentsObj,
      [name]: value,
    });

    validateInput(name, value);
  };

  const handleSubmit = async () => {
    const { title, recommendation } = addRequirmentsObj;
    const { validForm, formErrors } = await handleFormValidation(
      addStandardRequirementValidations,
      {
        title,
        recommendation,
      }
    );
  
    if(validForm) {
      setAddFinalValue(
        produce((draft) => {
          draft.standardRequirements.unshift({
            ...addRequirmentsObj,
          });
        })
      );

      setAddRequirmentsObj({
        title: "",
        recommendation: "",
        subStandardRequirements: [],
      });
  
    } else if (formErrors) {
      setErrors({ ...errors, ...formErrors });
    }
  };

  return (
    <div className="bg-white p-5 pb-7 rounded-lg">
      <h2 className="font-semibold mb-4">
        {from === "edit" ? "تعديل البعد" : "إضافة بعد"}
      </h2>
      <div className="row gap-y-3">
        <div className="col-md-5 ">
          <AppInput
            variant="gray"
            label="عنوان البعد"
            placeholder="اكتب هنا"
            name="title"
            value={addRequirmentsObj.title}
            onChange={handleChange}
            errorMessage={errors.title}
          />
        </div>
        <div className="col-md-5 ">
          <AppInput
            variant="gray"
            label="وصف البعد"
            placeholder="اكتب هنا"
            name="recommendation"
            value={addRequirmentsObj.recommendation}
            onChange={handleChange}
            errorMessage={errors.recommendation}
          />
        </div>

        <div className="col-xl align-self-end mr-auto">
          <div className="text-end">
            <AppButton onClick={handleSubmit}>إضافة بعد</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStandardDimensions;
