import { useState } from "react";
import SelectFilterInput from "../../../components/SelectFilterInput/SelectFilterInput";
import { ToggleSwitch, Checkbox, Label } from "flowbite-react";

const OPTIONS = [
  {
    id: 1,
    label: "وزارة العدل",
    value: "ministry_of_justice",
  },
  {
    id: 2,
    label: "وزارة الموارد البشرية والتنمية الاجتماعية",
    value: "ministry_of_human_resources",
  },
  {
    id: 3,
    label: "هيئة الاتصالات والفضاء والتقنيه",
    value: "communications_and_technology",
  },
  {
    id: 4,
    label: "جامعة القصيم",
    value: "qassim_university",
  },
];

const AuditorsEvaluation = ({ auditorsEvaluation, setAuditorsEvaluation }) => {
  const [switch1, setSwitch1] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const handleAuditorsEvaluation = (value) => setAuditorsEvaluation(value);

  return (
    <>
      <button
        type="button"
        onClick={toggleModal}
        className="border-2 border-blue h-[76px] rounded-xl w-full text-blue font-semibold text-lg leading-7 hover:border-blue_hover"
      >
        تقييم المدققين
      </button>
      {isModalOpen && (
        <>
          <div className="fixed w-screen h-screen bg-blue opacity-30 top-0 left-0 z-10"></div>
          <div className="flex flex-col gap-6 fixed border-t-[20px] border-blue top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 max-h-2/3 h-2/3 bg-white rounded-xl p-6 z-10 shadow-[0px_8px_8px_-4px_#1018280A,0px_20px_24px_-4px_#1018281A]">
            <div className="flex flex-col gap-1 items-center">
              <h3 className="text-[#101828] leading-7 text-lg font-semibold">
                تقييم المدققين
              </h3>
              <span className="text-[#475467] font-normal text-sm leading-5">
                نص وهمي يمكن استبداله بآي نص آخر
              </span>
            </div>

            <form className="flex flex-col gap-4 max-h-[calc(100%-100px)] overflow-auto">
              <div className="flex gap-6 w-3/4 items-center">
                <span className="text-[#111928] leading-6 text-base font-normal pb-1 flex-shrink-0">
                  إجابة الخبير
                </span>
                <SelectFilterInput
                  placeholder="أختر إجابة الخبير"
                  searchInputPlaceholder="أبحث عن إجابة"
                  currentValue={auditorsEvaluation}
                  setFilter={handleAuditorsEvaluation}
                  options={OPTIONS}
                />
              </div>
              <div className="flex gap-6 w-3/4 items-center">
                <span className="text-[#111928] leading-6 text-base font-normal pb-1 flex-shrink-0">
                  اسم المعيار
                </span>
                <SelectFilterInput
                  placeholder="أختر المعيار"
                  searchInputPlaceholder="أبحث عن المعيار"
                  currentValue={auditorsEvaluation}
                  setFilter={handleAuditorsEvaluation}
                  options={OPTIONS}
                />
              </div>
              <div className="flex flex-col gap-2 py-2 px-4 border border-[#F2F1FF] rounded-lg bg-white">
                <div className="flex gap-5 items-center py-1 text-[#3B3B3B] font-medium text-sm leading-6">
                  <span>العنصر التابع له المعيار :</span>
                  <span>العنصر الاول</span>
                </div>
                <div className="flex gap-5 py-1 text-[#3B3B3B] font-medium text-sm leading-6">
                  <span className="flex-shrink-0">
                    المعايير التابعة للعنصر:
                  </span>
                  <div className="flex flex-wrap gap-5 items-center">
                    <p className="py-1 px-2 bg-[#F2F1FF] rounded-lg pb-2">
                      المعيار الأول
                    </p>
                    <p className="py-1 px-2 bg-[#F2F1FF] rounded-lg pb-2">
                      المعيار الثاني
                    </p>
                    <p className="py-1 px-2 bg-[#F2F1FF] rounded-lg pb-2">
                      المعيار الثالث
                    </p>
                    <p className="py-1 px-2 bg-[#F2F1FF] rounded-lg pb-2">
                      المعيار الرابع
                    </p>
                    <p className="py-1 px-2 bg-[#F2F1FF] rounded-lg pb-2">
                      المعيار الخامس
                    </p>
                    <p className="py-1 px-2 bg-[#F2F1FF] rounded-lg pb-2">
                      المعيار السادس
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className="py-1 px-2 pb-2">المعيار السابع</p>
                <div className="flex items-center gap-4 py-1 px-6">
                  <span className="text-[#111928] font-normal text-sm leading-6 inline-block pb-1">
                    وزن المعيار
                  </span>
                  <span className="text-[#868686] font-normal text-base leading-6 inline-block pb-1">
                    5.3.7
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between px-4">
                <p className="text-[#111928] font-normal text-base leading-6 inline-block pb-1">
                  الأسئلة
                </p>
                <button type="button" onClick={() => {}} className="group p-1">
                  <svg
                    className="group-hover:-rotate-180 transition-all duration-200 ease-linear"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.08918 5.41366C3.20086 3.49505 4.97191 2.04582 7.07259 1.33583C9.17326 0.625841 11.4604 0.703473 13.5081 1.55427C15.5558 2.40507 17.2245 3.97105 18.2035 5.96063C19.1825 7.95021 19.4051 10.2278 18.8299 12.3693C18.2546 14.5108 16.9207 16.3702 15.0765 17.6014C13.2323 18.8326 11.0035 19.3516 8.80508 19.0618C6.60668 18.772 4.58854 17.6931 3.1264 16.026C1.66427 14.359 0.857808 12.2174 0.857178 9.99995"
                      stroke="#292069"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.57143 5.42836H2V0.856934"
                      stroke="#292069"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col px-4 gap-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <Checkbox
                      id="required"
                      className="flex-shrink-0 w-6 h-6 rounded-full"
                    />
                    <Label
                      htmlFor="required"
                      className="text-black font-normal text-sm leading-4"
                    >
                      Required
                    </Label>
                  </div>
                  <ToggleSwitch checked={switch1} onChange={setSwitch1} />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <Checkbox
                      id="required"
                      className="flex-shrink-0 w-6 h-6 rounded-full"
                    />
                    <Label
                      htmlFor="required"
                      className="text-black font-normal text-sm leading-4"
                    >
                      Required
                    </Label>
                  </div>
                  <ToggleSwitch checked={switch1} onChange={setSwitch1} />
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <Checkbox
                      id="required"
                      className="flex-shrink-0 w-6 h-6 rounded-full"
                    />
                    <Label
                      htmlFor="required"
                      className="text-black font-normal text-sm leading-4"
                    >
                      Required
                    </Label>
                  </div>
                  <ToggleSwitch checked={switch1} onChange={setSwitch1} />
                </div>
              </div>
              <div className="flex flex-col gap-2 text-[#111928] font-medium text-lg leading-6">
                <span>إجابة الخبير</span>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center w-full px-4">
                    <h4 className="w-2/3 pb-2">العناصر</h4>
                    <h4 className="flex-auto pb-2">الوزن الحالى</h4>
                    <div className="flex-auto flex items-center gap-4 justify-between">
                      <span className="inline-block pb-2">الوزن الجديد</span>
                      <button
                        type="button"
                        onClick={() => {}}
                        className="group p-1"
                      >
                        <svg
                          className="group-hover:-rotate-180 transition-all duration-200 ease-linear"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.08918 5.41366C3.20086 3.49505 4.97191 2.04582 7.07259 1.33583C9.17326 0.625841 11.4604 0.703473 13.5081 1.55427C15.5558 2.40507 17.2245 3.97105 18.2035 5.96063C19.1825 7.95021 19.4051 10.2278 18.8299 12.3693C18.2546 14.5108 16.9207 16.3702 15.0765 17.6014C13.2323 18.8326 11.0035 19.3516 8.80508 19.0618C6.60668 18.772 4.58854 17.6931 3.1264 16.026C1.66427 14.359 0.857808 12.2174 0.857178 9.99995"
                            stroke="#292069"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.57143 5.42836H2V0.856934"
                            stroke="#292069"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center px-4 py-2 border border-[#EAECF0] rounded-lg">
                      <span className="w-2/3 pb-2 inline-block">
                        وزارة العدل
                      </span>
                      <div className="flex items-center flex-1">
                        <span className="inline-block pb-2 text-[#868686] text-sm font-normal leading-6 w-28 flex-shrink-0">
                          التزام كلي
                        </span>
                        <div className="flex flex-1 gap-4 items-center justify-between">
                          <SelectFilterInput
                            placeholder="الدرجة"
                            searchInputPlaceholder="أبحث عن الدرجة"
                          />
                          <button
                            type="button"
                            onClick={() => {}}
                            className="group p-1"
                          >
                            <svg
                              className="group-hover:-rotate-180 transition-all duration-200 ease-linear"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.08918 5.41366C3.20086 3.49505 4.97191 2.04582 7.07259 1.33583C9.17326 0.625841 11.4604 0.703473 13.5081 1.55427C15.5558 2.40507 17.2245 3.97105 18.2035 5.96063C19.1825 7.95021 19.4051 10.2278 18.8299 12.3693C18.2546 14.5108 16.9207 16.3702 15.0765 17.6014C13.2323 18.8326 11.0035 19.3516 8.80508 19.0618C6.60668 18.772 4.58854 17.6931 3.1264 16.026C1.66427 14.359 0.857808 12.2174 0.857178 9.99995"
                                stroke="#292069"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6.57143 5.42836H2V0.856934"
                                stroke="#292069"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center px-4 py-2 border border-[#EAECF0] rounded-lg">
                      <span className="w-2/3 pb-2 inline-block">
                        وزارة الموارد البشرية والتنمية الاجتماعية
                      </span>
                      <div className="flex items-center flex-1">
                        <span className="inline-block pb-2 text-[#868686] text-sm font-normal leading-6 w-28 flex-shrink-0">
                          عدم التزام
                        </span>
                        <div className="flex flex-1 gap-4 items-center justify-between">
                          <SelectFilterInput
                            placeholder="الدرجة"
                            searchInputPlaceholder="أبحث عن الدرجة"
                          />
                          <button
                            type="button"
                            onClick={() => {}}
                            className="group p-1"
                          >
                            <svg
                              className="group-hover:-rotate-180 transition-all duration-200 ease-linear"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.08918 5.41366C3.20086 3.49505 4.97191 2.04582 7.07259 1.33583C9.17326 0.625841 11.4604 0.703473 13.5081 1.55427C15.5558 2.40507 17.2245 3.97105 18.2035 5.96063C19.1825 7.95021 19.4051 10.2278 18.8299 12.3693C18.2546 14.5108 16.9207 16.3702 15.0765 17.6014C13.2323 18.8326 11.0035 19.3516 8.80508 19.0618C6.60668 18.772 4.58854 17.6931 3.1264 16.026C1.66427 14.359 0.857808 12.2174 0.857178 9.99995"
                                stroke="#292069"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6.57143 5.42836H2V0.856934"
                                stroke="#292069"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="flex gap-3 justify-center w-full items-center">
              <button
                type="submit"
                className="w-full p-2.5 pb-3.5 border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.06%)] text-white font-semibold text-base leading-6 hover:bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.08%)]"
              >
                محاكاة
              </button>
              <button
                type="button"
                onClick={toggleModal}
                className="w-full p-2.5 pb-3.5 border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-white text-blue font-semibold text-base leading-6 hover:bg-blue hover:text-white"
              >
                الغاء
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AuditorsEvaluation;
