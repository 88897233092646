import React from "react";
import Skeleton from "react-skeleton-loader";

const TableSkeletonBody = (numofCols = 5, numOfRows = 4) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 rounded-2xl border-[2px] border-gray-200">
      <tbody>
        {Array.from({ length: 4 }, (_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: 5 }, (_, colIndex) => (
              <td key={colIndex} className="p-2.5 py-6">
                <Skeleton height={"20px"} width={"100%"} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSkeletonBody;
