import Select from "react-tailwindcss-select";

const SelectFilterInput = ({
  options,
  currentValue,
  setFilter,
  isSearchable = true,
  isClearable = true,
  placeholder,
  searchInputPlaceholder,
}) => {
  return (
    <Select
      value={currentValue}
      onChange={setFilter}
      options={options}
      isSearchable={isSearchable}
      isClearable={isClearable}
      placeholder={placeholder}
      searchInputPlaceholder={searchInputPlaceholder}
      noOptionsMessage="لا يوجد نتائج للبحث"
      classNames={{
        menuButton: () =>
          `[&_div_p]:pb-1 flex text-sm text-gray-500 border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20`,
        searchBox:
          "pr-8 w-full py-2 h-9 text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
        listItem: ({ isSelected }) =>
          `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded text-gray-500  ${
            isSelected
              ? "bg-blue text-white"
              : "hover:bg-indigo-100 hover:text-blue_hover"
          }`,
      }}
    />
  );
};

export default SelectFilterInput;
