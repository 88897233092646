import {toast} from "react-toastify";
import {axiosPutRequest} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import AppModal from "../../../../../components/Models/appModal";
import {useRef, useState} from "react";
import {AppInput} from "../../../../../components";
import AppColorInput from "../../../../../components/form/appColorInput";
import {t} from "i18next";
import {NewForm} from "../../../../../components/form/NewForm";
import {ErrorMessage} from "formik";

const UpdateDegreeModal = ({
                             id,
                             openModal,
                             setOpenModal,
                             values,
                             degreeList,
                             setDegreeList,
                           }) => {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(
    values || {
      id: values.id || "",
      surveyId: values.surveyId || "",
      title: "",
      color: "#000000",
      fixedDegree: "",
      rangeFrom: "",
      rangeTo: "",
    }
  );

  const handleInputChange = (e) => {
    setInputs({...inputs, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPutRequest(
      environment.updateCommitmentLevel,
      inputs
    );
    setLoading(false);
    if (res?.success) {
      toast.info("تم تحديث الدرجة بنجاح");

      const editValue = degreeList?.map((item) => {
        if (item.id == id) return {...item, ...res.result};
        return item;
      });
      setDegreeList(editValue);
      console.log(res);
      setOpenModal(false);
    }
  };

  // --------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: "",
    color: "#000000",
    fixedDegree: "",
    rangeFrom: "",
    rangeTo: "",
  };

  // const validate = (values) => {
  //   const errors = {};
  //   if (!inputs.title) {
  //     errors.title = "هذا الحقل مطلوب";
  //   }
  //   if (inputs.fixedDegree < 0 || inputs.fixedDegree > 100) {
  //     errors.fixedDegree = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";
  //   }
  //   if (inputs.rangeFrom < 0 || inputs.rangeFrom > 100) {
  //     errors.rangeFrom = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";
  //   }

  //   if (inputs.rangeTo < 0 || inputs.rangeTo > 100) {
  //     errors.rangeTo = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";
  //   }

  //   return errors;
  // };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = "هذا الحقل مطلوب";
    }
    if (
      // inputs.fixedDegree == "" ||
      !(
        +inputs.fixedDegree >= +inputs.rangeFrom &&
        +inputs.fixedDegree <= +inputs.rangeTo
      )
    ) {
      errors.fixedDegree =
        "هذا الحقل مطلوب ولابد أن تكون القيمة بين النسبة من والنسبة اٍلي";
    }
    if (
      // inputs.rangeFrom == "" ||
      +inputs.rangeFrom > +inputs.rangeTo ||
      +inputs.rangeFrom < 0 ||
      +inputs.rangeFrom > 100
    ) {
      errors.rangeFrom =
        "لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أصغر من (النسبة اٍلي)";
    }
    if (
      // inputs.rangeTo == "" ||
      +inputs.rangeTo < +inputs.rangeFrom ||
      +inputs.rangeTo < 0 ||
      +inputs.rangeTo > 100
    ) {
      errors.rangeTo =
        "لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أكبر من (النسبة من)";
    }

    return errors;
  };

  // ----------------------------------------------------- //

  return (
    <AppModal
      headerTitle="تحديث الدرجة"
      open={openModal}
      setOpen={setOpenModal}
      handleSubmit={handleFormik}
      type={"submit"}
      loading={loading}
      submitLabel="تحديث"
    >
      <NewForm
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t("الدرجة")}
              variant="gray"
              value={inputs.title}
              name="title"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="title"
              component="div"
            />
          </div>
        </div>
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppColorInput
              label={t("اللون")}
              value={inputs.color}
              name="color"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t("النسبة الثابتة")}
              type="number"
              variant="gray"
              value={inputs.fixedDegree}
              name="fixedDegree"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="fixedDegree"
              component="div"
            />
          </div>
        </div>
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t("النسبة من")}
              type="number"
              variant="gray"
              value={inputs.rangeFrom}
              name="rangeFrom"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="rangeFrom"
              component="div"
            />
          </div>
        </div>
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t("النسبة إلى")}
              type="number"
              variant="gray"
              value={inputs.rangeTo}
              name="rangeTo"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="rangeTo"
              component="div"
            />
          </div>
        </div>
      </NewForm>
    </AppModal>
  );
};

export default UpdateDegreeModal;
