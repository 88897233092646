import {Formik} from "formik";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {plusLight, programsIcon} from "../../../../assets/icons/Index";
import {Button, InputLabel, SModal, Title} from "../../../../components";
import NewHeader from "../../../../layouts/header/NewHeader";
import environment from "../../../../services/environment";
import {get, post} from "../../../../services/Request";
import {Accordion} from "flowbite-react";
import {toast} from 'react-toastify'
import {permissionsCheck} from "../../../../helpers/utils";

const Add = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [message, setMessage] = useState({});
  const [modules, setModules] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getAllPermissions();
  }, []);
  const getAllPermissions = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllPermissions, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
      } else if (res.status == 500) {
        toast.error(res.data.error.message,{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
      } else if (res.status == 200) {
        let newPermissions = [];
        const allModule = [];
        res.data.result.items.forEach((element) => {
          allModule.push(element.name);
          setModules(allModule);
          element.children.forEach((permission, index) => {
            permission.label = permission.displayName;
            permission.value = permission.name;
            permission.id = permission.name;
          });
        });
        setRoles(res.data.result.items || [], setLoading(false));
      }
    });
  };

  const handleCheckbox = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids.map((i) => i).includes(id);
  };

  const removeDuplicates =(arr)=> {
    return arr.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t === obj
        ))
    );
}

  const addRole = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    console.log(modules)
    console.log(ids)
    let arr = []
    ids.forEach((item) => {
      // let modified_page_name = ""
      // modified_page_name = item.replace(".", "", 2)
      //  console.log(modified_page_name)
      var result = item.replace(/(\.[^.]*)\..*/, '$1');
      console.log(result);
       arr.push(result)
    });
    console.log(arr)
    // ids.unshift("Pages.Users")
    // ids.unshift("Pages.Roles")
    // ids.unshift("Pages.Indicators")
    // ids.unshift("Pages.Dashboard")



    console.log(removeDuplicates(arr))
    // return true
    let data = {
      name: values.name,
      grantedPermissions: ids.concat(removeDuplicates(arr)),
      displayName: values.name,
      normalizedName: values.name,
      description: values.name
    };

    post(environment.addRole, data, config, (res) => {
      if (res.status == 500) {
        toast.error(res.data.error.message,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
      })
      } else {
        toast.success(t("تم اضافة الدور بنجاح"),{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
      })
      setIds([],history.push("/permissions/1"))
      }
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={t("modules.main.users.permissions.title")}
        subTitleSeconed={"اضافة دور"}
        toPageSeconed={() => history.push("/permissions/1")}
        thired={true}
        subTitle={t("modules.main.users.permissions.subTitle")}
        withoutButton={true}
        onClick={() => history.push("/permissions/add")}
        textStyle={{ color: "white", fontSize: 18 }}
        titleButton={t("modules.main.users.permissions.titleAddButton")}
        style={{ backgroundColor: "#2B2969" }}
      />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <div className="p-5">
        <Formik
          initialValues={{
            name: "",
            grantedPermissions: [],
            displayName: "",
            normalizedName: "",
            description: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = t("modules.main.users.permissions.messages.title");
            }
            if (ids.length == 0) {
              errors.grantedPermissions = t(
                "modules.main.users.permissions.messages.listEntites"
              );
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            addRole(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-[40%]">
                <InputLabel
                  style={{
                    borderColor: errors.name
                      ? "red"
                      : values.name != ""
                      ? "#22bb33"
                      : "transparent",
                  }}
                  label={t("modules.main.users.permissions.form.title")}
                  placeholder={t(
                    "modules.main.users.permissions.form.placeholderTitle"
                  )}
                  type="text"
                  value={values?.name || ""}
                  onChange={handleChange}
                  name="name"
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors?.name && touched?.name && errors?.name}
                </div>
              </div>
              <div className="p-5 bg-gray_light rounded-xl mt-5">
                        <Accordion>
                     {roles.map((role,index)=>(
      <Accordion.Panel key={index}>
        <Accordion.Title>{role.displayName}</Accordion.Title>
        <Accordion.Content>
        <InputLabel
                  handleCheckbox={handleCheckbox}
                  message={"لا يوجد صلاحيات"}
                  isCheckedCheckbox={isCheckedCheckbox}
                  type={"checkbox"}
                  items={role.children}
                />
        </Accordion.Content>
      </Accordion.Panel>
                           ))}

      </Accordion>
                </div>
              <div className="text-sm text-red-600 mt-3">
                {errors?.grantedPermissions &&
                  touched?.grantedPermissions &&
                  errors?.grantedPermissions}
              </div>
              {permissionsCheck("Pages.Roles.Create") ?
              <div className="text-center">
                <Button
                  type={"submit"}
                  withIcon={true}
                  icon={plusLight}
                  title={t("modules.main.users.permissions.titleAddButton")}
                  typeColor={"dark"}
                  style={{ backgroundColor: "#00114E", width: 160 }}
                />
              </div>
              :null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Add;
