const DashboardTab = ({ tab, isActive, isLast, setActiveTab }) => {
  return (
    <button
      type="button"
      className={`pt-2 pb-3 px-4 text-sm font-medium leading-5 ${
        isActive ? "bg-[#1CC081] text-white" : "bg-white text-[#344054]"
      }
          ${isLast ? "" : "border-e border-[#D0D5DD]"}
          `}
      onClick={setActiveTab}
    >
      {tab.label}
    </button>
  );
};

export default DashboardTab;
