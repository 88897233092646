import { CustomFlowbiteTheme } from "flowbite-react";

// TODO: Add only the properties we want to override
const tableTheme: CustomFlowbiteTheme["table"] = {
  root: {
    base: "w-full text-right text-base text-[#475467] dark:text-gray-400",
    shadow:
      "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md dark:bg-black",
    wrapper: "relative",
  },
  body: {
    base: "group/body",
    cell: {
      base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
    },
  },
  head: {
    base: "group/head text-center text-xl uppercase text-[#475467] bg-[#FCFCFD] dark:text-gray-400 border-t border-[#EAECF0]",
    cell: {
      base: "bg-gray-50 font-medium px-6 py-3 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-gray-700",
    },
  },
  row: {
    base: "group/row",
    hovered:
      "hover:bg-gray-50 dark:hover:bg-gray-600 bg-[#FCFCFD] border-t border-[#EAECF0] text-lg",
    striped:
      "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700",
  },
};


export default tableTheme;