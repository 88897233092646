import React, { useState } from "react";

interface TabProps {
  title: string;
  omit?: boolean;
  children: React.ReactNode;
}

interface TabListProps {
  children: React.ReactElement<TabProps>[];
  defaultActiveTab?: number;
  activeTab?: number;
  setActiveTab?: (index: number) => void;
}

const TabList = ({ children, defaultActiveTab = 0, activeTab, setActiveTab }: TabListProps) => {
  const [internalActiveTab, setInternalActiveTab] = useState(defaultActiveTab);
  const activeTabState = activeTab ?? internalActiveTab;
  const setActiveTabState = setActiveTab ?? setInternalActiveTab;


  return (
    <div className="w-full mx-auto mt-4 mr-2  rounded-full pt-2">
      <div className="inline-flex gap-6 mr-10">
        {children.filter(t => !t.props.omit).map((tab, index) => (
          <button
            key={index}
            className={`py-3 px-10 rounded-t-2xl text-sm font-medium ${
              index === activeTabState
                ? "bg-[#42389d] text-white"
                : "bg-white text-gray-600"
            }`}
            onClick={() => setActiveTabState(index)}
          >
            {tab.props.title}
          </button>
        ))}
      </div>
      <div className="">{children.filter(t => !t.props.omit)[activeTabState].props.children}
        </div>
    </div>
  );
};


const Tab = ({ children }: TabProps) => {
  
  return <div className="tab">{children}</div>;
};

const Tabs = {
  TabList,
  Tab,
};

export default Tabs;
