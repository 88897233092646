import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import UploadAdapter from "../../../../../../../components/UploadAdapter";
import { DropdownMultiSelect } from "../../../../../../../components";
import NewDropdownMultiSelect from "../../../../../../../components/NewDropDownMultiSelect";
import { axiosGetRequest } from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { showRequirement } from "../utils";

const ExpertNoteTemplate = () => {
  const { newExpertAnswer, expertAnswer, setNewExpertAnswer, newAgencyAnswer } =
    useAnswerContext();
  const [selectedTemplatesForAgency, setSelectedTemplatesForAgency] =
    useState("");
  const [selectedTemplatesForReviewer, setSelectedTemplatesForReviewer] =
    useState("");

  const [templateListOptions, setTemplateListOptions] = useState([]);

  useEffect(() => {
    const prevTemplateNote = templateListOptions
      ?.filter((item) => item.value === newExpertAnswer?.templateIdForAgency)
      .at(0);

    newExpertAnswer.selectedTemplatesForAgency =
      prevTemplateNote?.value || null;
    setSelectedTemplatesForAgency(prevTemplateNote);
  }, [newExpertAnswer?.templateIdForAgency, templateListOptions.length]);

  function extractTextFromHtml(htmlString) {
    // Remove HTML tags
    var text = htmlString.replace(/<[^>]*>/g, "");
    // Remove backslashes
    text = text.replace(/\\/g, "");
    // Return the extracted text
    return text;
  }

  useEffect(() => {
    getAllTemplates();
  }, []);

  const getAllTemplates = async () => {
    const res = await axiosGetRequest(environment.getAllNoteTemplate, {
      AgencyCommitmentLevelsIds:
        newAgencyAnswer?.checkedAgencyDegree?.id == null
          ? noAnswerDegreeId
          : newAgencyAnswer?.checkedAgencyDegree?.id,

      CorrectorCommitmentLevelsIds:
        newExpertAnswer?.checkedExpertDegree?.id == null
          ? noAnswerDegreeId
          : newExpertAnswer?.checkedExpertDegree?.id,
      MaxResultCount: 1000000,
      SkipCount: 0,
    });

    if (res?.success) {
      let newTemplates = [];
      res?.result?.items.forEach((item) => {
        newTemplates.push({
          label: extractTextFromHtml(item.templateContent) || "",
          value: item.id,
        });
      });
      setTemplateListOptions(newTemplates);
    }
  };

  const handleChangeTemplatesForAgency = (value) => {
    newExpertAnswer.selectedTemplatesForAgency = value?.value || null;
    setSelectedTemplatesForAgency(value || []);
  };

  return (
    <>
      <div className="w-[87%] mb-2 ">
        <div className="w-[87%] mb-6">
          <h2 className="text-[#292069] text-lg font-bold mb-1">
            ملاحظات الي الجهة:
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: newExpertAnswer?.expertNoteTemplate,
            }}
          ></div>
          <ul className="px-2">
            {expertAnswer?.standardRequirements.map((requirement, index) => (
              <li>
                {showRequirement(requirement) ? requirement.title : ""}
                <ul>
                  {requirement.subStandardRequirements.map(
                    (subRequirement, i) => (
                      <>
                        {(subRequirement.decision == 0 ||
                          !subRequirement.decision) &&
                        subRequirement?.donotApply == false ? (
                          <li className="mx-10">{subRequirement.title}</li>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  )}
                </ul>
              </li>
            ))}
          </ul>
          <div
            dangerouslySetInnerHTML={{
              __html: newExpertAnswer?.expertNotContent || "لا يوجد ملاحظات",
            }}
          ></div>
        </div>

        {/* {newExpertAnswer?.noteForReviewer && (
          <div className="w-[87%] mb-14">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الي المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertAnswer?.noteForReviewer || "لا يوجد ملاحظات",
              }}
            ></div>
              <div className="mt-3"
              dangerouslySetInnerHTML={{
                __html: newExpertAnswer?.expertSelectedTemplateForReviewer || "لا يوجد ملاحظات",
              }}
            ></div>
          </div>
        )} */}

        {/* * Agency note templates */}
        <div className="my-6">
          <div className="pb-5">
            <DropdownMultiSelect
              isSearchable={true}
              value={selectedTemplatesForAgency}
              handleChange={handleChangeTemplatesForAgency}
              placeholder={t("اختر من القوالب")}
              label={t("قوالب الملاحظات للجهة")}
              options={templateListOptions}
              isMultiple={false}
            />
          </div>
          {!selectedTemplatesForAgency ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                القالب الذي تم اختياره{" "}
              </div>
              {/* <div className="grid grid-cols-4 md:grid-cols-3 w-[100%] mt-2 mb-3"> */}
              {/* {selectedTemplatesForAgency?.map((item,index)=>( */}
              <div className="bg-[#DBE0EF] p-2 rounded-xl mt-2 mb-3">
                {selectedTemplatesForAgency.label}
              </div>
              {/* ))} */}
              {/* </div> */}
            </div>
          )}
          {newExpertAnswer?.standardRequirements.length == 0 ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                الأسئلة التي لم يتم اختيارها{" "}
              </div>
              <ul className="p-2">
                {newExpertAnswer?.standardRequirements.map(
                  (requirement, index) => (
                    <li>
                      {showRequirement(requirement) ? requirement.title : ""}
                      <ul>
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <>
                              {(subRequirement.decision == 0 ||
                                !subRequirement.decision) &&
                              subRequirement?.donotApply == false ? (
                                <li className="mx-10">
                                  {subRequirement.title}
                                </li>
                              ) : (
                                ""
                              )}
                            </>
                          )
                        )}
                      </ul>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}

          <CKEditor
            editor={ClassicEditor}
            data={
              // selectedTemplatesForAgency?.label ||
              // newExpertAnswer?.expertSelectedTemplateForAgency
              newExpertAnswer?.expertSelectedTemplateForAgency
            }
            onReady={(editor) => {
              setNewExpertAnswer({
                ...newExpertAnswer,
                expertSelectedTemplateForAgency: editor.getData(),
              });
              // setContent(editor.getData());
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
            config={{
              language: "ar",
              simpleUpload: {
                uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                withCredentials: true,

                headers: {
                  // 'X-CSRF-TOKEN': 'CSRF-Token',
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setNewExpertAnswer({
                ...newExpertAnswer,
                expertSelectedTemplateForAgency: data,
              });
              console.log(data);
              // setContent(data);
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
          />
        </div>

        {/* * Reviewer note templates */}
        {/* <div className="my-6">
          <div className="text-lg font-bold text-[#202969] pb-5">ملاحظة للمدقق</div>
          <div className="pb-5">
            <DropdownMultiSelect
              isSearchable={true}
              value={selectedTemplatesForReviewer}
              handleChange={handleChangeTemplatesForReviewer}
              placeholder={t("اختر من القوالب")}
              label={t("قوالب الملاحظات للمدقق")}
              options={templateListOptions}
              isMultiple={false}
            />
          </div>
          {!selectedTemplatesForReviewer ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                القالب الذي تم اختياره{" "}
              </div>
              
              <div className="bg-[#DBE0EF] p-2 rounded-xl mt-2 mb-3">
                {selectedTemplatesForReviewer.label}
              </div>
              
            </div>
          )}
          {newExpertAnswer?.standardRequirements.length == 0 ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                الأسئلة التي لم يتم اختيارها{" "}
              </div>
              <ol className="p-2" style={{ listStyleType: "decimal" }}>
                {newExpertAnswer?.standardRequirements.map(
                  (requirement, index) => (
                    <li>
                      {requirement.decision == 0 || !requirement.decision
                        ? requirement.title
                        : ""}
                         <ul>
                          {requirement.subStandardRequirements.map((subRequirement,i)=>(
                            <>
                            {subRequirement.decision == 0 || !subRequirement.decision ? (
                              <li className="mx-10">
                                {subRequirement.title}
                                </li>
                            ):""}
                            </>
                          ))}
                        </ul>
                    </li>
                  )
                )}
              </ol>
            </div>
          )}

          <CKEditor
            editor={ClassicEditor}
            data={
              newExpertAnswer?.expertSelectedTemplateForReviewer
            }
            onReady={(editor) => {
              setNewExpertAnswer({
                ...newExpertAnswer,
                expertSelectedTemplateForReviewer: editor.getData(),
              });
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
            config={{
              language: "ar",
              simpleUpload: {
                uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                withCredentials: true,

                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              },
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setNewExpertAnswer({
                ...newExpertAnswer,
                expertSelectedTemplateForReviewer: data,
              });
              console.log(data);
              editor.plugins.get("FileRepository").createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
          />
        </div> */}
        {/* <div className="text=[#7D8BAB] text-[14px]">
          {standardProgramContext?.standard.programExpertAnswer == null
            ? null
            : standardProgramContext?.standard.expertToReviewerNoteDate}
        </div> */}
      </div>
    </>
  );
};

export default ExpertNoteTemplate;
