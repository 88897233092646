import { useState } from "react";
import React from "react";
import SelectFilterInput from "../../../components/SelectFilterInput/SelectFilterInput";
import { Label, Select } from "flowbite-react";
import search from "../../../../../assets/icons/search.svg";
import radialGrid from "../../../../../assets/icons/radialGird.svg";
import {
  edit,
  editIcon,
  editOutline,
  trashOutline,
} from "../../../../../assets/icons/Index";
const StandardWeights = () => {
  const inputRef = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStandard, setSelectedStandard] = useState(null);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const STANDARD = [1];

  return (
    <>
      <button
        type="button"
        onClick={toggleModal}
        className="border-2 border-blue h-[76px] rounded-xl w-full text-blue font-semibold text-lg leading-7 hover:border-blue_hover"
      >
        المعايير
      </button>
      {isModalOpen && (
        <>
          <div className="fixed w-screen h-screen bg-blue opacity-30 top-0 left-0 z-10"></div>
          <div className="flex flex-col gap-6 fixed border-t-[20px] border-blue top-1/2 left-[40%] -translate-x-1/2 -translate-y-1/2 w-[620px] max-h-2/3 bg-white rounded-xl p-6 z-10 shadow-[0px_8px_8px_-4px_#1018280A,0px_20px_24px_-4px_#1018281A]">
            <div className="flex gap-1 items-center justify-content-between">
              <button
                type="button"
                onClick={toggleModal}
                className="w-28 p-2.5 pb-3.5 px-[18px] border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-white text-blue font-semibold text-base leading-6 hover:bg-blue hover:text-white"
              >
                حفظ
              </button>
              <div className="text-center">
                <h3 className="text-[#101828] leading-7 text-lg font-semibold">
                  اوزان المعايير
                </h3>
                <span className="text-[#475467] font-normal text-sm leading-5">
                  نص وهمي يمكن استبداله بآي نص آخر
                </span>
              </div>
              <button
                type="button"
                onClick={toggleModal}
                className="w-22 p-2.5 pb-3.5 px-[18px] border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-white text-blue font-semibold text-base leading-6 hover:bg-blue hover:text-white"
              >
                اعادة الضبط
              </button>
            </div>

            <form
              onSubmit={(e) => e.preventDefault()}
              className="flex flex-col gap-4"
            >
              <div className="max-w-md flex items-center">
                <div className="mb-2 block w-32">
                  <Label htmlFor="standards" value="اسم المعيار" />
                </div>
                <select
                  id="standards"
                  required
                  className="w-full leading-[1.7] pt-0 px-4 pb-2 bg-white shadow-sm rounded-lg border border-gray-300 text-sm "
                >
                  <option>المعيار الاول</option>
                  <option>المعيار الثاني</option>
                  <option>المعيار الثالث</option>
                  <option>المعيار الرابع</option>
                </select>
              </div>
              {/* if there is a standard selected */}
              {selectedStandard && (
                <>
                  <div className=" text-sm border border-spacing-0.5 rounded-lg p-4">
                    <p className="mb-3 flex gap-3 justify-content-between">
                      <p>
                        <span className="inline-block mx-2">
                          العنصر التابع له المعيار :
                        </span>
                        <span> العنصر الاول</span>
                      </p>
                      <div className="button-wrapper flex items-center gap-2">
                        <button>
                          <img src={editOutline} alt="edit the standard icon" />
                        </button>
                        <button>
                          <img
                            src={trashOutline}
                            alt="delete the standard icon"
                          />
                        </button>
                      </div>
                    </p>

                    <h4 className="text-nowrap">
                      المعيار المحدد : المعيار الاول
                    </h4>

                    <div className="mt-2 ">
                      <div className="flex  mb-2 px-2 gap-3 items-center  pb-3 ">
                        <p className="w-2/4">اسم السؤال</p>
                        <p className="w-40 text-end">التصنيف الحالي</p>
                        <p className="w-40">التصنيف الجديد</p>
                        <p className="w-10">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.09 7.414A9.143 9.143 0 1 1 2.856 12"
                              stroke="#292069"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.571 7.428H4V2.857"
                              stroke="#292069"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </p>
                      </div>
                      <div
                        className="flex border text-[#3B3B3B] bg-[#F2F1FF] rounded-lg mb-2 px-2 py-3 gap-3 items-center text-sm cursor-pointer"
                        onClick={() => setSelectedStandard(1)}
                      >
                        <p className="w-2/4 ">السؤال الثاني</p>
                        <p className="w-28  text-center text-[#868686]">
                          تصنيف 1
                        </p>
                        <select
                          id="category"
                          required
                          className="leading-[1.8] pb-2 bg-[#F9F9F9] rounded-lg border border-[#EAECF0] p-0 text-sm w-32 flex-grow"
                        >
                          <option> التصنيف</option>
                          <option>المعيار الثاني</option>
                        </select>
                        <button className="px-3 ">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.09 7.414A9.143 9.143 0 1 1 2.856 12"
                              stroke="#292069"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.571 7.428H4V2.857"
                              stroke="#292069"
                              strokeWidth={1.5}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {STANDARD.length > 0 && !selectedStandard && (
                <>
                  <div className=" text-sm border border-spacing-0.5 rounded-lg p-4">
                    <p className="mb-3 flex gap-3">
                      العنصر التابع له المعيار : <span>العنصر الاول</span>
                    </p>
                    <div className="flex gap-3">
                      <h4 className="text-nowrap">المعايير التابعة للعنصر: </h4>
                      <div className="flex gap-5 flex-wrap">
                        {/* using seprated padding to fix the arabic top space */}
                        <span className="bg-[#F2F1FF] inline-block px-2 pb-2 pt-1 rounded-lg">
                          المعيار الاول
                        </span>
                        <span className="bg-[#F2F1FF] inline-block px-2 pb-2 pt-1 rounded-lg">
                          المعيار الثاني
                        </span>
                        <span className="bg-[#F2F1FF] inline-block px-2 pb-2 pt-1 rounded-lg">
                          المعيار الثالث
                        </span>
                        <span className="bg-[#F2F1FF] inline-block px-2 pb-2 pt-1 rounded-lg">
                          المعيار الرابع
                        </span>
                        <span className="bg-[#F2F1FF] inline-block px-2 pb-2 pt-1 rounded-lg">
                          المعيار الخامس
                        </span>
                        <span className="bg-[#F2F1FF] inline-block px-2 pb-2 pt-1 rounded-lg">
                          المعيار السادس
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-between px-8 py-4 text-sm items-center">
                    <span>المعيار السابع</span>

                    <div>
                      وزن المعيار <span className="text-[#868686]">5.3.7</span>
                    </div>
                  </div>
                  <div>
                    <div className="flex  mb-2 px-2 gap-3 items-center sticky top-0 bg-white shadow-sm pb-3 ">
                      <p className="w-2/4">اسم السؤال</p>
                      <p className="w-40 text-end">التصنيف الحالي</p>
                      <p className="w-40">التصنيف الجديد</p>
                      <p className="w-10">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.09 7.414A9.143 9.143 0 1 1 2.856 12"
                            stroke="#292069"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.571 7.428H4V2.857"
                            stroke="#292069"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </p>
                    </div>
                    <div
                      className="flex border text-[#3B3B3B] rounded-lg mb-2 px-2 py-3 gap-3 items-center text-sm cursor-pointer"
                      onClick={() => setSelectedStandard(1)}
                    >
                      <p className="w-2/4 ">السؤال الثاني</p>
                      <p className="w-28  text-center text-[#868686]">
                        تصنيف 1
                      </p>
                      <select
                        id="category"
                        required
                        className="leading-[1.8] pb-2 bg-[#F9F9F9] rounded-lg border border-[#EAECF0] p-0 text-sm w-32 flex-grow"
                      >
                        <option> التصنيف</option>
                        <option>المعيار الثاني</option>
                      </select>
                      <button className="px-3 ">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.09 7.414A9.143 9.143 0 1 1 2.856 12"
                            stroke="#292069"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.571 7.428H4V2.857"
                            stroke="#292069"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {STANDARD.length === 0 && (
                <div className="radial-box">
                  <div className=" w-80 h-80 bg-gradient-to-r from-blue-500 rounded-full flex flex-wrap gap-0.5 items-center justify-center">
                    <img src={radialGrid} alt="radial grid " />
                  </div>
                  <div className="flex flex-col justify-center items-center gap-5">
                    <div className="flex items-center justify-center w-10 h-10 bg-white border rounded-lg">
                      <img src={search} alt="searh icon" />
                    </div>
                    <p className="font-bold text-xl">لا يوجد نتائج</p>
                    <p className="text-[#475467] text-lg">
                      لايوجد نتائج ابحث عن ما تريد
                    </p>
                  </div>
                </div>
              )}
            </form>
            <div className="flex gap-3 justify-center w-full items-center">
              <button
                type="submit"
                className="w-full p-2.5 pb-3.5 border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.06%)] text-white font-semibold text-base leading-6 hover:bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.08%)]"
              >
                محاكاة
              </button>
              <button
                type="button"
                onClick={toggleModal}
                className="w-full p-2.5 pb-3.5 border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-white text-blue font-semibold text-base leading-6 hover:bg-blue hover:text-white"
              >
                الغاء
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StandardWeights;
