import { IExpertEvaluation } from "../../../types";
import { showRequirement } from "../utils";

const AgencyNoteTemplate = ({
  expertEvaluation,
}: {
  expertEvaluation: IExpertEvaluation;
}) => {
  return (
    <>
      <div className="w-[87%] mb-2 ">
        <div className="w-[87%] mb-6">
          <h2 className="text-[#292069] text-lg font-bold mb-1">
            ملاحظات الفريق الوطنى للقياس:
          </h2>
          {expertEvaluation.noteForAgency?.noteTemplate?.templateContent && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  expertEvaluation.noteForAgency?.noteTemplate?.templateContent,
              }}
            ></div>
          )}
          <div className="mt-2 mb-2">
            <div className="text-[#202969] font-bold text-[15px]">
              الأسئلة التي لم يتم اختيارها{" "}
            </div>
          </div>

          <ul className="p-2">
            {expertEvaluation?.expertRequirementAnswers.map((requirement) => (
              <li>
                {showRequirement(requirement)
                  ? requirement.standardRequirementTitle
                  : ""}
                <ul>
                  {requirement.expertSubRequirementAnswers.map(
                    (subRequirement) => (
                      <>
                        {(subRequirement.decision == 0 ||
                          !subRequirement.decision) &&
                        subRequirement?.donotApply == false ? (
                          <li className="mx-10">
                            {subRequirement.subStandardRequirementTitle}
                          </li>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  )}
                </ul>
              </li>
            ))}
          </ul>

          <div
            dangerouslySetInnerHTML={{
              __html:
                expertEvaluation?.noteForAgency?.content || "",
            }}
          ></div>
          {
            !expertEvaluation?.noteForAgency?.noteTemplate?.templateContent &&
            !expertEvaluation?.noteForAgency?.content &&
            expertEvaluation?.expertRequirementAnswers?.length === 0 &&
            <div className="">
              لا يوجد ملاحظات
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default AgencyNoteTemplate;
