import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion } from "flowbite-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-skeleton-loader";
import { avatar, programsIcon } from "../../../assets/icons/Index";
import { Title } from "../../../components";
import { formatDate, formatTime, Lang } from "../../../helpers/utils";
import i18n from "../../../languages/i18n";
import NewHeader from "../../../layouts/header/NewHeader";
import environment from "../../../services/environment";
import { get, put } from "../../../services/Request";

const Inquiries = () => {
  const { t } = useTranslation();
  const [standards, setStandards] = useState([]);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    getAllStandardsAgencyInquiries();
  }, []);

  const getAllStandardsAgencyInquiries = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getStandardsAgenciesInquiries +
        "?userId=" +
        localStorage.getItem("userId"),
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: "error", message: t("general.authError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            { type: "error", message: t("general.serverError") },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          console.log(res);
          //   let arr = []
          //   res.data.result.items.forEach(item => {
          //       let obj = {label:item.title,value:item.id,id:item.id}
          //       arr.push(obj)
          //   });
          //   arr.unshift({label:"اختر تصنيف",value:"",id:""})
          setStandards(res.data.result.standards);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    );
  };

  const changeStatusInquiry = (inquiryId, status) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: inquiryId,
      newStatus: status,
    };
    put(environment.updateInquiryStatus, data, config, (res) => {
      setMessage(
        { type: "success", message: t("تم تغيير حالة الاستفسار بنجاح") },
        setOpenConfirmModal(true, getAllStandardsAgencyInquiries())
      );
    });
  };
  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        style={{ backgroundColor: "#2B2969" }}
        single={true}
        title={"الاستفسارات"}
        titleButton={"اضافة سؤال"}
        withIcon={false}
        withoutButton={true}
      />
      <div className="m-5 p-5 bg-white rounded-xl">
        {standards.length == 0 ? (
          loading ? null : (
            <div className="text-center text-[#2B2969] text-lg mt-5">
              لا يوجد معايير
            </div>
          )
        ) : (
          <div>
            {loading ? (
              standards.map((faq, index) => (
                <Skeleton
                  ke
                  animated={true}
                  color={"#2B296969"}
                  width={"100%"}
                  height={"100px"}
                  count={1}
                />
              ))
            ) : (
              <Accordion collapseAll>
                {standards.map((standard, index) => (
                  <Accordion.Panel key={index}>
                    <Accordion.Title>
                      <div className="text-base text-[#2B2969]">
                        {standard.title}
                      </div>
                    </Accordion.Title>
                    <Accordion.Content>
                      {standard.agencies.length == 0 ? (
                        <div className="flex justify-center items-center">
                          <span className="text-base text-[#2B2969]">
                            {t("لا يوجد جهات")}
                          </span>
                        </div>
                      ) : (
                        <Accordion collapseAll>
                          {standard.agencies.map((agency, index) => (
                            <Accordion.Panel key={index}>
                              <Accordion.Title>
                                <div className="text-base text-[#2B2969]">
                                  {agency.title}
                                </div>
                              </Accordion.Title>
                              <Accordion.Content>
                                {agency.inquires.length == 0 ? (
                                  <div className="flex justify-center items-center">
                                    <span className="text-base text-[#2B2969]">
                                      {t("لا يوجد استفسارات")}
                                    </span>
                                  </div>
                                ) : (
                                  <Accordion collapseAll>
                                    {agency.inquires.map((inquiry, index) => (
                                      <Accordion.Panel key={index}>
                                        <Accordion.Title>
                                          <div className="flex flex-row justify-between">
                                            <div className="text-base text-[#2B2969]">
                                              {inquiry.title}
                                            </div>
                                            <div
                                              className={
                                                i18n.language == Lang.AR
                                                  ? "flex flex-row items-center"
                                                  : "flex flex-row items-center"
                                              }
                                            >
                                              {localStorage.getItem(
                                                "userType"
                                              ) == "Expert" ||
                                              localStorage.getItem(
                                                "userType"
                                              ) == "ExpertManager" ? (
                                                <div>
                                                  {inquiry.status == 1 ? (
                                                    <span
                                                      className="text-lg text-[#bb2124] mr-4 ml-4 cursor-pointer"
                                                      onClick={() =>
                                                        changeStatusInquiry(
                                                          inquiry.id,
                                                          0
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      غلق
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="text-lg text-[#22bb33] mr-4 ml-4 cursor-pointer"
                                                      onClick={() =>
                                                        changeStatusInquiry(
                                                          inquiry.id,
                                                          1
                                                        )
                                                      }
                                                    >
                                                      فتح
                                                    </span>
                                                  )}
                                                </div>
                                              ) : null}
                                              <div>
                                                {inquiry.status == 1 ? (
                                                  <FontAwesomeIcon
                                                    fontSize={25}
                                                    icon={faCheckCircle}
                                                    color="#22bb33"
                                                  />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    fontSize={25}
                                                    icon={faTimesCircle}
                                                    color="#bb2124"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </Accordion.Title>
                                        <Accordion.Content>
                                          {inquiry.comments.length == 0 ? (
                                            <div className="flex justify-center items-center">
                                              <span className="text-base text-[#2B2969]">
                                                {t("لا يوجد تعليقات")}
                                              </span>
                                            </div>
                                          ) : (
                                            <Accordion collapseAll>
                                              {inquiry.comments.map(
                                                (comment, index) => (
                                                  <Accordion.Panel key={index}>
                                                    <Accordion.Title>
                                                      <div className="flex flex-row">
                                                        {/* <div> */}
                                                        <img
                                                          src={
                                                            comment.user
                                                              ?.profilePicture ==
                                                            null
                                                              ? avatar
                                                              : `${process.env.REACT_APP_FRONT_URL}${comment.user?.profilePicture}`
                                                          }
                                                          className="w-[40px] h-[40px] rounded-full border-[#2B2969]"
                                                        />
                                                        <div
                                                          className={
                                                            i18n.language ==
                                                            Lang.AR
                                                              ? "flex flex-col w-[100%]"
                                                              : "flex flex-col w-[100%]"
                                                          }
                                                        >
                                                          {/* <div className="flex flex-row"> */}
                                                          {/* <img src={comment.user?.profilePicture == null ? avatar : `${process.env.REACT_APP_FRONT_URL}${comment.user?.profilePicture}`} className="w-[40px] h-[40px]"/> */}
                                                          <div
                                                            className={
                                                              i18n.language ==
                                                              Lang.AR
                                                                ? "flex flex-row items-center bg-transparent w-[100%] p-3"
                                                                : "flex flex-row items-center bg-transparent w-[100%] p-3"
                                                            }
                                                          >
                                                            {console.log(
                                                              formatDate(
                                                                new Date()
                                                              ) +
                                                                "  " +
                                                                formatTime(
                                                                  new Date()
                                                                )
                                                            )}
                                                            {console.log(
                                                              formatDate(
                                                                new Date(
                                                                  comment.creationTime
                                                                )
                                                              ) +
                                                                "  " +
                                                                formatTime(
                                                                  new Date(
                                                                    comment.creationTime
                                                                  )
                                                                )
                                                            )}

                                                            <span className="text-base text-[#2B2969]">
                                                              {
                                                                comment.user
                                                                  ?.name
                                                              }
                                                            </span>
                                                            <span className="text-[10px] text-[#7e7e7e] mr-5 ml-5">
                                                              {formatDate(
                                                                new Date(
                                                                  comment.creationTime
                                                                )
                                                              ) ==
                                                              formatDate(
                                                                new Date()
                                                              )
                                                                ? "Today" +
                                                                  " " +
                                                                  formatTime(
                                                                    new Date(
                                                                      comment.creationTime
                                                                    )
                                                                  )
                                                                : formatDate(
                                                                    new Date(
                                                                      comment.creationTime
                                                                    )
                                                                  ) +
                                                                  "  " +
                                                                  formatTime(
                                                                    new Date(
                                                                      comment.creationTime
                                                                    )
                                                                  )}
                                                            </span>

                                                            {/* </div>  */}
                                                          </div>
                                                          {/* </div> */}
                                                          {/* <div className="bg-[#e9e9e9] p-3"> 
                   {console.log(comment.attachment?.attachment?.uri)}
                       {comment.attachment != null ? (
                           <img id={"attachmentImageView"} className="w-[100px] p-2" src={`${process.env.REACT_APP_FRONT_URL}${comment.attachment.attachment.uri}`} 
                           />
                       ):null}
               
                        <div className="text-sm text-black mt-3">{comment.note}</div>
                       </div> */}
                                                        </div>
                                                        {/* </div> */}
                                                      </div>
                                                    </Accordion.Title>
                                                    <Accordion.Content>
                                                      {comment.note}
                                                    </Accordion.Content>
                                                  </Accordion.Panel>
                                                )
                                              )}
                                            </Accordion>
                                          )}
                                        </Accordion.Content>
                                      </Accordion.Panel>
                                    ))}
                                  </Accordion>
                                )}
                              </Accordion.Content>
                            </Accordion.Panel>
                          ))}
                        </Accordion>
                      )}
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
              </Accordion>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Inquiries;
