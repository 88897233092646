import React, { useRef, useEffect, useState } from "react";
import cloud from "../../../assets/icons/cloud.svg";
import pdf from "../../../assets/icons/pdf.svg";
import download from "../../../assets/icons/download.svg";
import openBook from "../../../assets/icons/openBook.svg";
import Select from "react-tailwindcss-select";
import Button from "../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../../components/UploadAdapter";
import environment from "../../../services/environment";
import { get, post } from "../../../services/Request";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CModal,
  DropdownMultiSelect,
  Ecard,
  FModal,
  InputLabel,
  SModal,
  Title,
  Input,
} from "../../../components";
import { Field, Formik } from "formik";
import Skeleton from "react-skeleton-loader";
import {
  avatar,
  plusLight,
  programsIcon,
  search,
} from "../../../assets/icons/Index";
import { useDebugValue } from "react";
import NewHeader from "../../../layouts/header/NewHeader";
import { Spinner } from "flowbite-react";
import { isAgency } from "../../../helpers/isPermission";

const CreativeForms = () => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [descriptionStyle, setDescriptionStyle] = useState({
    border: "2px solid #f4f7fe",
  });
  const [dateStyle, setDateStyle] = useState({ border: "2px solid #f4f7fe" });
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const [loadingImageDescription, setLoadingImageDescription] = useState(false);
  const [
    loadingImageFinancialImpact,
    setLoadingImageFinancialImpact,
  ] = useState(false);
  const [loadingImageImpactOnAgency, setLoadingImageImpactOnAgency] = useState(
    false
  );
  const [
    loadingImageImpactOnBeneficiaries,
    setLoadingImageImpactOnBeneficiaries,
  ] = useState(false);
  const [
    loadingImageReliabilityAndCompetitiveness,
    setLoadingImageReliabilityAndCompetitiveness,
  ] = useState(false);

  const [descriptionAttachmentUrl, setDescriptionAttachmentUrl] = useState(
    null
  );
  const [descriptionFile, setDescriptionFile] = useState("");
  const fileDescriptionInputRef = useRef(null);
  const [date, setDate] = useState("");
  const [message, setMessage] = useState({});
  const [innovativeFormIdeaTypes, setInnovativeFormIdeaTypes] = useState([]);
  const [innovativeFormIdeaTypes1, setInnovativeFormIdeaTypes1] = useState([]);
  const [
    innovativeFormIdeaMaturities,
    setInnovativeFormIdeaMaturities,
  ] = useState([]);
  const [standrds, setStandrds] = useState([]);
  const [selectedStandrds, setSelectedStandrds] = useState([]);
  const [innovativeFormIdeaType, setInnovativeFormIdeaType] = useState("");
  const [innovativeFormIdeaMaturity, setInnovativeFormIdeaMaturity] = useState(
    ""
  );
  const [ideaImpact, setIdeaImpact] = useState("");
  const [financialImpact, setFinancialImpact] = useState("");
  const [ideaImpactStyle, setIdeaImpactStyle] = useState({
    border: "2px solid #f4f7fe",
  });
  const [financialImpactStyle, setFinancialImpactStyle] = useState({
    border: "2px solid #f4f7fe",
  });

  const [impactOnBeneficiaries, setImpactOnBeneficiaries] = useState("");
  const [impactOnBeneficiariesStyle, setImpactOnBeneficiariesStyle] = useState({
    border: "2px solid #f4f7fe",
  });

  const [impactOnAgency, setImpactOnAgency] = useState("");
  const [impactOnAgencyStyle, setImpactOnAgencyStyle] = useState({
    border: "2px solid #f4f7fe",
  });

  const [
    reliabilityAndCompetitiveness,
    setReliabilityAndCompetitiveness,
  ] = useState("");
  const [
    reliabilityAndCompetitivenessStyle,
    setReliabilityAndCompetitivenessStyle,
  ] = useState({ border: "2px solid #f4f7fe" });

  const fileFinancialImpactInputRef = useRef(null);
  const fileImpactOnBeneficiariesInputRef = useRef(null);
  const fileImpactOnAgencyInputRef = useRef(null);
  const fileReliabilityAndCompetitivenessInputRef = useRef(null);

  const [
    impactOnAgencyAttachmentUrl,
    setImpactOnAgencyAttachmentUrl,
  ] = useState(null);
  const [impactOnAgencyFile, setImpactOnAgencyFile] = useState("");

  const [
    financialImpactAttachmentUrl,
    setFinancialImpactAttachmentUrl,
  ] = useState(null);
  const [financialImpactFile, setFinancialImpactFile] = useState("");

  const [
    impactOnBeneficiariesAttachmentUrl,
    setImpactOnBeneficiariesAttachmentUrl,
  ] = useState(null);
  const [impactOnBeneficiariesFile, setImpactOnBeneficiariesFile] = useState(
    ""
  );

  const [
    reliabilityAndCompetitivenessAttachmentUrl,
    setReliabilityAndCompetitivenessAttachmentUrl,
  ] = useState(null);
  const [
    reliabilityAndCompetitivenessFile,
    setreliabilityAndCompetitivenessFile,
  ] = useState("");

  const [impactOnAgencyFileId, setImpactOnAgencyFileId] = useState("");
  const [financialImpactFileId, setFinancialImpactFileId] = useState("");
  const [
    impactOnBeneficiariesFileId,
    setImpactOnBeneficiariesFileId,
  ] = useState("");
  const [
    reliabilityAndCompetitivenessFileId,
    setreliabilityAndCompetitivenessFileId,
  ] = useState("");
  const [descriptionFileId, setDescriptionFileId] = useState("");
  const history = useHistory();
  const [openModel, setOpenModel] = useState(false);

  const handleChangeStandrds = (value) => {
    console.log(value);

    console.log(value);
    setSelectedStandrds(value);
    const dd = selectedStandrds;
    // debugger;
  };

  const handleChangeDate = (e) => {
    console.log("date", e.target.value);
    // debugger;
    if (e.target.value) {
      setDateStyle({ border: "2px solid green" });
    } else {
      setDateStyle({ border: "2px solid red" });
    }
    setDate(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
    if (e.target.value) {
      setDescriptionStyle({ border: "2px solid green" });
    } else {
      setDescriptionStyle({ border: "2px solid red" });
    }
  };
  const handleIdeaImpact = (e) => {
    setIdeaImpact(e.target.value);
    if (e.target.value) {
      setIdeaImpactStyle({ border: "2px solid green" });
    } else {
      setIdeaImpactStyle({ border: "2px solid red" });
    }
  };
  const handleFinancialImpact = (e) => {
    setFinancialImpact(e.target.value);
    if (e.target.value) {
      setFinancialImpactStyle({ border: "2px solid green" });
    } else {
      setFinancialImpactStyle({ border: "2px solid red" });
    }
  };
  const handleImpactOnBeneficiaries = (e) => {
    setImpactOnBeneficiaries(e.target.value);
    if (e.target.value) {
      setImpactOnBeneficiariesStyle({ border: "2px solid green" });
    } else {
      setImpactOnBeneficiariesStyle({ border: "2px solid red" });
    }
  };
  const handleImpactOnAgency = (e) => {
    setImpactOnAgency(e.target.value);
    if (e.target.value) {
      setImpactOnAgencyStyle({ border: "2px solid green" });
    } else {
      setImpactOnAgencyStyle({ border: "2px solid red" });
    }
  };
  const handleReliabilityAndCompetitiveness = (e) => {
    setReliabilityAndCompetitiveness(e.target.value);
    if (e.target.value) {
      setReliabilityAndCompetitivenessStyle({ border: "2px solid green" });
    } else {
      setReliabilityAndCompetitivenessStyle({ border: "2px solid red" });
    }
  };

  const handleFileSelect = (e) => {
    // debugger;
    switch (e) {
      case 1:
        fileDescriptionInputRef.current.click();
        break;
      case 2:
        fileFinancialImpactInputRef.current.click();
        break;
      case 3:
        fileImpactOnAgencyInputRef.current.click();
        break;
      case 4:
        fileImpactOnBeneficiariesInputRef.current.click();
        break;
      case 5:
        fileReliabilityAndCompetitivenessInputRef.current.click();
        break;
    }
  };
  const handleDownload = () => {
    if (descriptionAttachmentUrl) {
      // Simulating file download by creating an anchor element
      const url = descriptionAttachmentUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", descriptionFile.name); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("No file chosen");
    }
  };
  const onChangeFile = (event, e) => {
    // debugger;
    console.log(event.target.files[0]);

    if (event.target.files.length > 0) {
      switch (e) {
        case 1:
          setDescriptionFile(
            event.target.files[0],
            setDescriptionAttachmentUrl(
              URL.createObjectURL(event.target.files[0]),
              generateUpload(event.target.files[0], 1)
            )
          );
          // debugger;
          break;
        case 2:
          setFinancialImpactFile(
            event.target.files[0],
            setFinancialImpactAttachmentUrl(
              URL.createObjectURL(event.target.files[0]),
              generateUpload(event.target.files[0], 2)
            )
          );
          break;
        case 3:
          setImpactOnAgencyFile(
            event.target.files[0],
            setImpactOnAgencyAttachmentUrl(
              URL.createObjectURL(event.target.files[0]),
              generateUpload(event.target.files[0], 3)
            )
          );
          break;
        case 4:
          setImpactOnBeneficiariesFile(
            event.target.files[0],
            setImpactOnBeneficiariesAttachmentUrl(
              URL.createObjectURL(event.target.files[0]),
              generateUpload(event.target.files[0], 4)
            )
          );
          break;
        case 5:
          setreliabilityAndCompetitivenessFile(
            event.target.files[0],
            setReliabilityAndCompetitivenessAttachmentUrl(
              URL.createObjectURL(event.target.files[0]),
              generateUpload(event.target.files[0], 5)
            )
          );
          break;
      }
    }
  };

  const generateUpload = (file, e, values) => {
    // setLoadingImage(true);
    switch (e) {
      case 1:
        setLoadingImageDescription(true);
        break;
      case 2:
        setLoadingImageFinancialImpact(true);
        break;
      case 3:
        setLoadingImageImpactOnAgency(true);
        break;
      case 4:
        setLoadingImageImpactOnBeneficiaries(true);
        break;
      case 5:
        setLoadingImageReliabilityAndCompetitiveness(true);
        break;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();

    formData.append("Name", file.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", file);
    post(environment.attachmentFile, formData, config, (res) => {
      console.log(res);
      console.log("stop 4");
      // addUser(values, res.data?.result?.id);
      switch (e) {
        case 1:
          setDescriptionFileId(
            res.data?.result?.id,
            setLoadingImageDescription(false)
          );
          break;
        case 2:
          setFinancialImpactFileId(
            res.data?.result?.id,
            setLoadingImageFinancialImpact(false)
          );
          break;
        case 3:
          setImpactOnAgencyFileId(
            res.data?.result?.id,
            setLoadingImageImpactOnAgency(false)
          );
          break;
        case 4:
          setImpactOnBeneficiariesFileId(
            res.data?.result?.id,
            setLoadingImageImpactOnBeneficiaries(false)
          );
          break;
        case 5:
          setreliabilityAndCompetitivenessFileId(
            res.data?.result?.id,
            setLoadingImageReliabilityAndCompetitiveness(false)
          );
          break;
      }

      //     console.log(res.data?.result?.id)
      //     let arr = []
      //     arr.push(res.data?.result?.id)
      //     console.log(arr)

      //     console.log(descriptionFileId,financialImpactFileId,impactOnAgencyFileId,impactOnBeneficiariesFileId,reliabilityAndCompetitivenessFileId)
      //     let ids = {
      //         descriptionFileId:res.data?.result?.id,
      //         financialImpactFileId:res.data?.result?.id,
      //         impactOnAgencyFileId:res.data?.result?.id,
      //         impactOnBeneficiariesFileId:res.data?.result?.id,
      //         reliabilityAndCompetitivenessFileId:res.data?.result?.id
      //     }
      //     console.log(ids)
      //    addIdea(ids,values)
    });
  };

  const addIdea = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    selectedStandrds.forEach((element) => {
      delete element.disabled;
      delete element.label;
      delete element.value;
    });
    console.log("stop 2");
    let data = {
      name: values.name,
      description: description,
      descriptionAttachmentId: descriptionFileId,
      launchDate: date,
      agencyId: parseInt(localStorage.getItem("agencyId")),
      innovativeFormIdeaTypeId: innovativeFormIdeaType,
      innovativeFormIdeaMaturityId: innovativeFormIdeaMaturity,
      ideaImpact: ideaImpact,
      financialImpact: financialImpact,
      financialImpactAttachmentId: financialImpactFileId,
      impactOnBeneficiaries: impactOnBeneficiaries,
      impactOnBeneficiariesAttachmentId: impactOnBeneficiariesFileId,
      impactOnAgency: impactOnAgency,
      impactOnAgencyAttachmentId: impactOnAgencyFileId,
      reliabilityAndCompetitiveness: reliabilityAndCompetitiveness,
      reliabilityAndCompetitivenessAttachmentId: reliabilityAndCompetitivenessFileId,
      standardInnovativeFormAgencyAnswer: selectedStandrds,
    };

    post(environment.createInnovativeFormAgencyAnswer, data, config, (res) => {
      console.log(res);
      // debugger;
      setLoading(false);
      if (res.status == 403) {
        // setMessage(
        //     { type: "error", message: res.data?.error?.message },
        //     setOpenModel(true)
        // );
        toast.error(res.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        // setMessage(
        //     { type: "error", message: res.data?.error?.message },
        //     setOpenModel(true)
        // );
        toast.error(res.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        // setMessage({ type: "success", message: t("تم الإرسال بنجاح") },
        //     setOpenModel(true)
        // );
        toast.success("تم إضافة نموذج بنجاح", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          backToMailBox();
        }, 1000);
      }
    });
  };

  const onFileUpload = (values) => {
    // debugger;
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    generateUpload(descriptionFile, 1, values);
    generateUpload(financialImpactFile, 2, values);
    generateUpload(impactOnAgencyFile, 3, values);
    generateUpload(impactOnBeneficiariesFile, 4, values);
    generateUpload(reliabilityAndCompetitivenessFile, 5, values);

    //let newstandardInnovativeFormAgencyAnswer = []
    //selectedStandrds.forEach(item => {
    //    newstandardInnovativeFormAgencyAnswer(item);
    //    });
    var cc = selectedStandrds;
    // debugger;

    // console.log("stop 2")
    // let data = {
    //     name: values.name,
    //     description: description,
    //     descriptionAttachmentId: descriptionFileId,
    //     launchDate: date,
    //     agencyId: localStorage.getItem("agencyId"),
    //     innovativeFormIdeaTypeId: innovativeFormIdeaType,
    //     innovativeFormIdeaMaturityId: innovativeFormIdeaMaturity,
    //     ideaImpact: ideaImpact,
    //     financialImpact:financialImpact,
    //     financialImpactAttachmentId: financialImpactFileId,
    //     impactOnBeneficiaries: impactOnBeneficiaries,
    //     impactOnBeneficiariesAttachmentId: impactOnBeneficiariesFileId,
    //     impactOnAgency: impactOnAgency,
    //     impactOnAgencyAttachmentId: impactOnAgencyFileId,
    //     reliabilityAndCompetitiveness: reliabilityAndCompetitiveness,
    //     reliabilityAndCompetitivenessAttachmentId: reliabilityAndCompetitivenessFileId,
    //     standardInnovativeFormAgencyAnswer: selectedStandrds ,
    // };

    // post(environment.createInnovativeFormAgencyAnswer, data, config, (res) => {
    //     console.log(res);
    //     setLoading(false);
    //     if (res.status == 403) {
    //         setMessage(
    //             { type: "error", message: t("general.authError") },
    //             setOpenModel(true)
    //         );
    //     } else if (res.status == 500) {
    //         setMessage(
    //             { type: "error", message: t("general.serverError") },
    //             setOpenModel(true)
    //         );

    //     }
    //     else if (res.status == 200) {
    //         setMessage({ type: "success", message: t("تم الإرسال بنجاح") },
    //             setOpenModel(true)
    //         );
    //         setTimeout(() => {
    //          backToMailBox();
    //         }, 1000);
    //     }
    // })
  };
  const backToMailBox = () => {
    isAgency() ? history.push("/creativeModelsList") : history.push("/creativeModels");
  };

  useEffect(() => {
    getInnovativeFormIdeaTypes();
    getInnovativeFormIdeaMaturities();
    getStandrds();
    // debugger;
  }, []);

  const getInnovativeFormIdeaTypes = () => {
    //  setLoading(true)
    // debugger;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getInnovativeFormIdeaType, config, (res) => {
      // debugger;
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((item) => {
          let obj = { label: item.title, value: item.id, id: item.id };
          arr.push(obj);
        });

        const halfLength = Math.ceil(arr.length / 2);
        const firstColumnItems = arr.slice(0, halfLength);
        const secondColumnItems = arr.slice(halfLength);
        // debugger;
        setInnovativeFormIdeaTypes(firstColumnItems);
        setInnovativeFormIdeaTypes1(secondColumnItems);
        setInnovativeFormIdeaType(firstColumnItems[0].id);
        // setInnovativeFormIdeaTypes1(arr);
        // arr.unshift({ label: "اختر تصنيف", value: "", id: "" })
        // debugger;
        //setClassifications(arr, getAllFaq(currentPage))
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const getInnovativeFormIdeaMaturities = () => {
    //  setLoading(true)
    // debugger;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getInnovativeFormIdeaMaturity, config, (res) => {
      // debugger;
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((item) => {
          let obj = { label: item.title, value: item.id, id: item.id };
          arr.push(obj);
        });
        setInnovativeFormIdeaMaturities(arr);
        setInnovativeFormIdeaMaturity(arr[0].id);
        // arr.unshift({ label: "اختر تصنيف", value: "", id: "" })
        // debugger;
        //setClassifications(arr, getAllFaq(currentPage))
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const getStandrds = () => {
    //  setLoading(true)
    // debugger;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllStandard, config, (res) => {
      // debugger;
      if (res.status == 403) {
        setMessage({ type: "error", message: t("general.authError") });
      } else if (res.status == 500) {
        setMessage({ type: "error", message: t("general.serverError") });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((item) => {
          let obj = {
            label: item.title,
            standardTitle: item.title,
            value: item.id,
            standardId: item.id,
          };
          arr.push(obj);
        });
        setStandrds(arr);
        // arr.unshift({ label: "اختر تصنيف", value: "", id: "" })
        // debugger;
        //setClassifications(arr, getAllFaq(currentPage))
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const handleInnovativeFormIdeaType = (id) => {
    // debugger;
    setInnovativeFormIdeaType(id);
  };
  const handleInnovativeFormIdeaMaturity = (id) => {
    // debugger;
    setInnovativeFormIdeaMaturity(id);
  };

  return (
    // <div className='bg-white m-5 p-5 rounded-[30px] '>
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        title={"النماذج الابداعية"}
        subTitle={"اضافة نموذج"}
        toPage={() => history.push("/creativeModels")}
        withoutButton={true}
        iconTitle={programsIcon}
        seconed={true}
      />
      <div className="bg-[#F9F9FF] h-full m-7 p-5 rounded-3xl flex flex-col justify-center">
        <SModal
          type={message.type}
          open={openModel}
          setOpen={() => setOpenModel(false)}
          subTitle={message.message}
        />
        <Formik
          initialValues={{
            name: "",
            description: "",
            descriptionAttachmentId: "",
            launchDate: "",
            agencyId: 0,
            innovativeFormIdeaTypeId: 0,
            innovativeFormIdeaMaturityId: "",
            ideaImpact: "",
            financialImpact: "",
            financialImpactAttachmentId: "",
            impactOnBeneficiaries: "",
            impactOnBeneficiariesAttachmentId: "",
            impactOnAgency: "",
            impactOnAgencyAttachmentId: "",
            reliabilityAndCompetitiveness: "",
            reliabilityAndCompetitivenessAttachmentId: "",
            standardInnovativeFormAgencyAnswer: [],
          }}
          validate={(values) => {
            const errors = {};
            values.launchDate = date;
            //console.log(role)

            if (!values.name) {
              errors.name = t("اسم الفكرة مطلوب");
            }
            if (!description) {
              // debugger;
              errors.description = t("وصف الفكرة مطلوب");
              setDescriptionStyle({ border: "2px solid red" });
            }
            if (!ideaImpact) {
              // debugger;
              errors.ideaImpact = t("أثر الفكرة مطلوب");
              setIdeaImpactStyle({ border: "2px solid red" });
            }

            if (!financialImpact) {
              // debugger;
              errors.financialImpact = t("أثر المالي مطلوب");
              setFinancialImpactStyle({ border: "2px solid red" });
            }

            if (!impactOnBeneficiaries) {
              // debugger;
              errors.impactOnBeneficiaries = t("أثر علي المتنافسين مطلوب");
              setImpactOnBeneficiariesStyle({ border: "2px solid red" });
            }

            if (!impactOnAgency) {
              // debugger;
              errors.impactOnAgency = t("أثر علي الجهة مطلوب");
              setImpactOnAgencyStyle({ border: "2px solid red" });
            }

            if (!reliabilityAndCompetitiveness) {
              // debugger;
              errors.reliabilityAndCompetitiveness = t(
                " الاعتماد والتنافسية مطلوب"
              );
              setReliabilityAndCompetitivenessStyle({
                border: "2px solid red",
              });
            }

            if (!values.launchDate) {
              errors.launchDate = t("تاريخ بداية اطلاق الفكرة مطلوب");
              setDateStyle({ border: "2px solid red" });
            }
            // debugger;
            if (!selectedStandrds) {
              errors.standardInnovativeFormAgencyAnswer = t(
                "العنصر المرتبط به الفكرة مطلوب"
              );
              /*setDateStyle({ border: '2px solid red' });*/
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            // onFileUpload(values);
            addIdea(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="bg-white rounded-3xl p-5 flex flex-col justify-between items-center"
            >
              <section className="w-full flex flex-col">
                <label className="text-[16px] text-[#292069] mb-3">
                  اسم الفكرة
                </label>
                <InputLabel
                  style={{
                    borderColor: errors.name
                      ? "red"
                      : values.name != ""
                      ? "#22bb33"
                      : "transparent",
                  }}
                  label={t("اسم نضج الفكرة")}
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                  placeholder="أكتب هنا"
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.name && touched.name && errors.name}
                </div>
              </section>
              <section className="flex flex-col items-start w-full mt-5">
                <label className="text-[16px] text-[#292069] mb-3">
                  وصف للفكرة
                </label>
                <div className="w-full flex lg:flex-row sm:flex-col">
                  <section className="w-[50%] ml-5 flex flex-col justify-between">
                    <textarea
                      onChange={handleDescription}
                      style={descriptionStyle}
                      className="text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF]  rounded-3xl py-3 px-5 resize-none"
                      placeholder="أكتب هنا"
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>

                    <div flex flex-row>
                      {/*<div className='flex flex-row items-center'>*/}
                      {/*  <img src={pdf} alt='pdf'/>*/}
                      {/*  <p className='text-sm text-[#161318] mr-3'>اسم الملف ا اسم الملف....</p>*/}
                      {/*</div>*/}
                      {/*<div className='flex flex-row items-center'>*/}
                      {/*  <p className='text-sm text-[#7D8BAB] ml-3'>50KB</p>*/}
                      {/*  <img src={download} alt='download'/>*/}
                      {/*</div>*/}
                    </div>
                  </section>
                  <section className="w-[50%] flex flex-col justify-between">
                    <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9">
                      <img src={cloud} alt="cloud" />
                      <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                        يمكنك ارفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
                      </p>

                      <input
                        style={{ display: "none" }}
                        isImage={true}
                        type={"file"}
                        ref={fileDescriptionInputRef}
                        id={"fileUpload"}
                        label={t("modules.main.users.users.form.image")}
                        onChange={(event) => onChangeFile(event, 1)}
                        image={descriptionFile.name}
                        // style={{borderColor:errors.profilePictureId ? 'red' : selectedFile != "" ? '#22bb33' : 'transparent'}}
                      />
                      <button
                        type="button"
                        onClick={() => handleFileSelect(1)}
                        className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                      >
                        إرفاق ملف
                      </button>
                    </div>
                    <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                      <div className="flex flex-row items-center">
                        <img src={pdf} alt="pdf" />
                        {loadingImageDescription ? (
                          <Spinner
                            aria-label="Info spinner example"
                            color="info"
                          />
                        ) : (
                          <p className="text-sm text-[#161318] mr-3">
                            {descriptionFile.name}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-row items-center">
                        <p className="text-sm text-[#7D8BAB] ml-3">
                          {descriptionFile.size}KB
                        </p>
                        <img
                          src={download}
                          alt="download"
                          onClick={handleDownload}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <section className="w-full flex flex-col mt-5">
                <label className="text-[16px] text-[#292069] mb-3">
                  بداية إطلاق الفكرة
                </label>
                <input
                  style={dateStyle}
                  onChange={handleChangeDate}
                  value={date}
                  type="date"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                  placeholder="أكتب هنا"
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.launchDate && touched.launchDate && errors.launchDate}
                </div>
              </section>
              <section className="flex flex-row bg-white justify-between w-full mt-5">
                <div className="bg-[#F9F9FF] rounded-3xl ml-2 p-5 w-[50%] flex flex-col items-start">
                  <label className="mb-3">نوعية الفكرة</label>
                  <div className="flex flex-row">
                    <section className="flex flex-col ml-14">
                      {innovativeFormIdeaTypes.map((checkbox) => (
                        <div className="flex flex-row items-center w-full relative">
                          <input
                            onChange={() =>
                              handleInnovativeFormIdeaType(checkbox.id)
                            }
                            checked={checkbox.id === innovativeFormIdeaType}
                            id={checkbox.id}
                            type="checkbox"
                            className="bg-[#DBE0EF] border-none"
                          />
                          <label className="ml-5 mr-2">{checkbox.label}</label>
                          <img
                            src={openBook}
                            alt="openBook"
                            className="-left-5 absolute"
                          />
                        </div>
                      ))}
                    </section>
                    <section className="flex flex-col">
                      {innovativeFormIdeaTypes1.map((checkbox) => (
                        <div className="flex flex-row items-center relative">
                          <input
                            onChange={() =>
                              handleInnovativeFormIdeaType(checkbox.id)
                            }
                            checked={checkbox.id === innovativeFormIdeaType}
                            id={checkbox.id}
                            type="checkbox"
                            className="bg-[#DBE0EF] border-none"
                          />
                          <label className="ml-5 mr-2">{checkbox.label}</label>
                          <img
                            src={openBook}
                            alt="openBook"
                            className="-left-5 absolute"
                          />
                        </div>
                      ))}
                    </section>
                  </div>
                </div>
                <div className="bg-[#F9F9FF] rounded-3xl mr-2 p-5 w-[50%] flex flex-row items-start">
                  <label className="ml-10">نضج الفكرة</label>
                  <section className="flex flex-col">
                    {innovativeFormIdeaMaturities.map((checkbox) => (
                      <div className="flex flex-row items-center w-full relative mb-2">
                        <input
                          onChange={() =>
                            handleInnovativeFormIdeaMaturity(checkbox.id)
                          }
                          checked={checkbox.id === innovativeFormIdeaMaturity}
                          id={checkbox.id}
                          type="checkbox"
                          className="bg-[#DBE0EF] border-none"
                        />
                        <label className="ml-5 mr-2">{checkbox.label}</label>
                      </div>
                    ))}
                  </section>
                </div>
              </section>
              <section className="w-full flex flex-col mt-5">
                {/* <label typeof='dropdown' className='text-[16px] text-[#292069] mb-3'>العنصر المرتبط به الفكرة</label>*/}
                {/* <input className='text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5' placeholder='العنصر المرتبط به الفكرة'/> */}
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedStandrds}
                  handleChange={handleChangeStandrds}
                  placeholder="العنصر المرتبط به الفكرة"
                  label="العنصر المرتبط به الفكرة"
                  options={standrds}
                  isMultiple={true}
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.standardInnovativeFormAgencyAnswer &&
                    touched.standardInnovativeFormAgencyAnswer &&
                    errors.standardInnovativeFormAgencyAnswer}
                </div>

                {/*    <Select */}
                {/*    value={''}*/}
                {/*    key={'key'}*/}
                {/*    onChange={'handleChange'}*/}
                {/*    isMultiple={false}*/}
                {/*    options={[{value: '1', label: "options"}]}*/}
                {/*    isSearchable={true}*/}
                {/*    isClearable={true}*/}
                {/*    classNames={{*/}
                {/*        list:"h-[150px] overflow-y-scroll",*/}
                {/*        // menuButton:({ isDisabled }) => styleButton,*/}
                {/*        searchBox:"bg-white w-[100%] border-0 rounded-[30px] outline-none",*/}
                {/*        tagItem: ({ item }) => (*/}
                {/*            `max-w-[200px] break-all flex flex-row items-center p-2 rounded-lg bg-white`*/}
                {/*        )*/}
                {/*    }}*/}
                {/*    searchInputPlaceholder={"بحث"}*/}
                {/*    placeholder={'العنصر المرتبط به الفكرة'}*/}
                {/*/>*/}
              </section>
              <section className="w-full flex flex-col my-5">
                <label className="text-[16px] text-[#292069] mb-3">
                  أثر الفكرة
                </label>
                <textarea
                  onChange={handleIdeaImpact}
                  style={ideaImpactStyle}
                  className="text-[14px] h-40 text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                  placeholder="أكتب هنا"
                />
                <div className="text-sm text-red-600 mt-3">
                  {errors.ideaImpact && touched.ideaImpact && errors.ideaImpact}
                </div>
                {/*<input className='text-[14px] h-40 text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5' placeholder='أكتب هنا'/>*/}
              </section>
              <div className="bg-white w-full mt-7 p-5 rounded-3xl flex flex-col justify-center">
                <section>
                  <label className="text-[16px] text-[#292069]">
                    الأثر المالي
                  </label>
                  <div className="w-full flex flex-col">
                    <div className="w-full flex mt-5 lg:flex-row sm:flex-col">
                      <section className="w-[50%] ml-5 flex flex-col justify-between">
                        {/*  <input className='text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5' placeholder='أكتب هنا' />*/}

                        <textarea
                          onChange={handleFinancialImpact}
                          style={financialImpactStyle}
                          className="text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF]  rounded-3xl py-3 px-5 resize-none"
                          placeholder="أكتب هنا"
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {errors.financialImpact &&
                            touched.financialImpact &&
                            errors.financialImpact}
                        </div>
                      </section>
                      <section className="w-[50%] flex flex-col justify-between">
                        <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9">
                          <img src={cloud} alt="cloud" />
                          <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                            يمكنك ارفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
                          </p>

                          <input
                            style={{ display: "none" }}
                            isImage={true}
                            type={"file"}
                            ref={fileFinancialImpactInputRef}
                            id={"fileUpload"}
                            label={t("modules.main.users.users.form.image")}
                            onChange={(event) => onChangeFile(event, 2)}
                            image={financialImpactFile.name}
                            // style={{borderColor:errors.profilePictureId ? 'red' : selectedFile != "" ? '#22bb33' : 'transparent'}}
                          />
                          <button
                            type="button"
                            onClick={() => handleFileSelect(2)}
                            className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                          >
                            إرفاق ملف
                          </button>
                        </div>
                        <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                          <div className="flex flex-row items-center">
                            <img src={pdf} alt="pdf" />
                            {loadingImageFinancialImpact ? (
                              <Spinner
                                aria-label="Info spinner example"
                                color="info"
                              />
                            ) : (
                              <p className="text-sm text-[#161318] mr-3">
                                {financialImpactFile.name}
                              </p>
                            )}
                            {/* <p className='text-sm text-[#161318] mr-3'>{financialImpactFile.name}</p> */}
                          </div>
                          <div className="flex flex-row items-center">
                            <p className="text-sm text-[#7D8BAB] ml-3">
                              {financialImpactFile.size}KB
                            </p>
                            <img
                              src={download}
                              alt="download"
                              onClick={handleDownload}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    {/*<div className='bg-[#F9F9FF] w-[50%] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>*/}
                    {/*    <div className='flex flex-row items-center'>*/}
                    {/*        <img src={pdf} alt='pdf' />*/}
                    {/*        <p className='text-sm text-[#161318] mr-3'>اسم الملف ا اسم الملف....</p>*/}
                    {/*    </div>*/}
                    {/*    <div className='flex flex-row items-center'>*/}
                    {/*        <p className='text-sm text-[#7D8BAB] ml-3'>50KB</p>*/}
                    {/*        <img src={download} alt='download' />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </section>
                <section className="mt-5">
                  <label className="text-[16px] text-[#292069]">
                    الأثر علي الجهة
                  </label>
                  <div className="w-full flex flex-col">
                    <div className="w-full flex mt-5 lg:flex-row sm:flex-col">
                      <section className="w-[50%] ml-5 flex flex-col justify-between">
                        {/* <input className='text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5' placeholder='أكتب هنا' />*/}
                        <textarea
                          onChange={handleImpactOnAgency}
                          style={impactOnAgencyStyle}
                          className="text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF]  rounded-3xl py-3 px-5 resize-none"
                          placeholder="أكتب هنا"
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {errors.impactOnAgency &&
                            touched.impactOnAgency &&
                            errors.impactOnAgency}
                        </div>
                      </section>
                      <section className="w-[50%] flex flex-col justify-between">
                        <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9">
                          <img src={cloud} alt="cloud" />
                          <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                            يمكنك ارفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
                          </p>

                          <input
                            style={{ display: "none" }}
                            isImage={true}
                            type={"file"}
                            ref={fileImpactOnAgencyInputRef}
                            id={"fileUpload"}
                            label={t("modules.main.users.users.form.image")}
                            onChange={(event) => onChangeFile(event, 3)}
                            image={impactOnAgencyFile.name}
                            // style={{borderColor:errors.profilePictureId ? 'red' : selectedFile != "" ? '#22bb33' : 'transparent'}}
                          />
                          <button
                            type="button"
                            onClick={() => handleFileSelect(3)}
                            className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                          >
                            إرفاق ملف
                          </button>
                        </div>
                        <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                          <div className="flex flex-row items-center">
                            <img src={pdf} alt="pdf" />
                            {loadingImageImpactOnAgency ? (
                              <Spinner
                                aria-label="Info spinner example"
                                color="info"
                              />
                            ) : (
                              <p className="text-sm text-[#161318] mr-3">
                                {impactOnAgencyFile.name}
                              </p>
                            )}
                            {/* <p className='text-sm text-[#161318] mr-3'>{impactOnAgencyFile.name}</p> */}
                          </div>
                          <div className="flex flex-row items-center">
                            <p className="text-sm text-[#7D8BAB] ml-3">
                              {impactOnAgencyFile.size}KB
                            </p>
                            <img
                              src={download}
                              alt="download"
                              onClick={handleDownload}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    {/*<div className='flex flex-row justify-between items-center'>*/}
                    {/*    <div className='bg-[#F9F9FF] w-[50%] ml-3 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <img src={pdf} alt='pdf' />*/}
                    {/*            <p className='text-sm text-[#161318] mr-3'>اسم الملف ا اسم الملف....</p>*/}
                    {/*        </div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <p className='text-sm text-[#7D8BAB] ml-3'>50KB</p>*/}
                    {/*            <img src={download} alt='download' />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                  </div>
                </section>
                <section className="mt-5">
                  <label className="text-[16px] text-[#292069]">
                    الأثر علي المتنافسين
                  </label>
                  <div className="w-full flex flex-col">
                    <div className="w-full flex mt-5 lg:flex-row sm:flex-col">
                      <section className="w-[50%] ml-5 flex flex-col justify-between">
                        <textarea
                          onChange={handleImpactOnBeneficiaries}
                          style={impactOnBeneficiariesStyle}
                          className="text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF]  rounded-3xl py-3 px-5 resize-none"
                          placeholder="أكتب هنا"
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {errors.impactOnBeneficiaries &&
                            touched.impactOnBeneficiaries &&
                            errors.impactOnBeneficiaries}
                        </div>
                        {/* <input className='text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5' placeholder='أكتب هنا' />*/}
                      </section>
                      <section className="w-[50%] flex flex-col justify-between">
                        <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9">
                          <img src={cloud} alt="cloud" />
                          <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                            يمكنك ارفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
                          </p>

                          <input
                            style={{ display: "none" }}
                            isImage={true}
                            type={"file"}
                            ref={fileImpactOnBeneficiariesInputRef}
                            id={"fileUpload"}
                            label={t("modules.main.users.users.form.image")}
                            onChange={(event) => onChangeFile(event, 4)}
                            image={impactOnBeneficiariesFile.name}
                            // style={{borderColor:errors.profilePictureId ? 'red' : selectedFile != "" ? '#22bb33' : 'transparent'}}
                          />
                          <button
                            type="button"
                            onClick={() => handleFileSelect(4)}
                            className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                          >
                            إرفاق ملف
                          </button>
                        </div>
                        <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                          <div className="flex flex-row items-center">
                            <img src={pdf} alt="pdf" />
                            {loadingImageImpactOnBeneficiaries ? (
                              <Spinner
                                aria-label="Info spinner example"
                                color="info"
                              />
                            ) : (
                              <p className="text-sm text-[#161318] mr-3">
                                {impactOnBeneficiariesFile.name}
                              </p>
                            )}
                            {/* <p className='text-sm text-[#161318] mr-3'>{impactOnBeneficiariesFile.name}</p> */}
                          </div>
                          <div className="flex flex-row items-center">
                            <p className="text-sm text-[#7D8BAB] ml-3">
                              {impactOnBeneficiariesFile.size}KB
                            </p>
                            <img
                              src={download}
                              alt="download"
                              onClick={handleDownload}
                            />
                          </div>
                        </div>
                      </section>
                    </div>

                    {/*<div className='flex flex-row w-full pl-5'>*/}
                    {/*    <div className='bg-[#F9F9FF] w-[50%] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <img src={pdf} alt='pdf' />*/}
                    {/*            <p className='text-sm text-[#161318] mr-3'>اسم الملف ا اسم الملف....</p>*/}
                    {/*        </div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <p className='text-sm text-[#7D8BAB] ml-3'>50KB</p>*/}
                    {/*            <img src={download} alt='download' />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </section>
                <section className="mt-5">
                  <label className="text-[16px] text-[#292069]">
                    الاعتماد والتنافسية
                  </label>
                  <div className="w-full flex flex-col">
                    <div className="w-full flex mt-5 lg:flex-row sm:flex-col">
                      <section className="w-[50%] ml-5 flex flex-col justify-between">
                        <textarea
                          onChange={handleReliabilityAndCompetitiveness}
                          style={reliabilityAndCompetitivenessStyle}
                          className="text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF]  rounded-3xl py-3 px-5 resize-none"
                          placeholder="أكتب هنا"
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {errors.reliabilityAndCompetitiveness &&
                            touched.reliabilityAndCompetitiveness &&
                            errors.reliabilityAndCompetitiveness}
                        </div>

                        {/* <input className='text-[14px] w-full h-full  text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5' placeholder='أكتب هنا' />*/}
                      </section>
                      <section className="w-[50%] flex flex-col justify-between">
                        <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9">
                          <img src={cloud} alt="cloud" />
                          <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                            يمكنك ارفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
                          </p>

                          <input
                            style={{ display: "none" }}
                            isImage={true}
                            type={"file"}
                            ref={fileReliabilityAndCompetitivenessInputRef}
                            id={"fileUpload"}
                            label={t("modules.main.users.users.form.image")}
                            onChange={(event) => onChangeFile(event, 5)}
                            image={reliabilityAndCompetitivenessFile.name}
                            // style={{borderColor:errors.profilePictureId ? 'red' : selectedFile != "" ? '#22bb33' : 'transparent'}}
                          />
                          <button
                            type="button"
                            onClick={() => handleFileSelect(5)}
                            className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                          >
                            إرفاق ملف
                          </button>
                        </div>
                        <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                          <div className="flex flex-row items-center">
                            <img src={pdf} alt="pdf" />
                            {loadingImageReliabilityAndCompetitiveness ? (
                              <Spinner
                                aria-label="Info spinner example"
                                color="info"
                              />
                            ) : (
                              <p className="text-sm text-[#161318] mr-3">
                                {reliabilityAndCompetitivenessFile.name}
                              </p>
                            )}
                            {/* <p className='text-sm text-[#161318] mr-3'>{reliabilityAndCompetitivenessFile.name}</p> */}
                          </div>
                          <div className="flex flex-row items-center">
                            <p className="text-sm text-[#7D8BAB] ml-3">
                              {reliabilityAndCompetitivenessFile.size}KB
                            </p>
                            <img
                              src={download}
                              alt="download"
                              onClick={handleDownload}
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                    {/*<div className='flex flex-row justify-between items-center'>*/}
                    {/*    <div className='bg-[#F9F9FF] w-[50%] ml-3 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <img src={pdf} alt='pdf' />*/}
                    {/*            <p className='text-sm text-[#161318] mr-3'>اسم الملف ا اسم الملف....</p>*/}
                    {/*        </div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <p className='text-sm text-[#7D8BAB] ml-3'>50KB</p>*/}
                    {/*            <img src={download} alt='download' />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className='bg-[#F9F9FF] w-[50%] mr-3 flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl'>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <img src={pdf} alt='pdf' />*/}
                    {/*            <p className='text-sm text-[#161318] mr-3'>اسم الملف ا اسم الملف....</p>*/}
                    {/*        </div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <p className='text-sm text-[#7D8BAB] ml-3'>50KB</p>*/}
                    {/*            <img src={download} alt='download' />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </section>
              </div>
              <div className="w-full flex flex-row justify-end">
                <Button
                  loading={loading}
                  type={"submit"}
                  title="ارسال"
                  style={{
                    marginBottom: "50px",
                    marginTop: "50px",
                    width: "12rem",
                  }}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreativeForms;
