import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBinoculars,
  faClose,
  faPen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  CModal,
  DropdownMultiSelect,
  FModal,
  InputLabel,
  SModal,
} from "../../../components";
import environment from "../../../services/environment";
import {
  axiosGetRequest,
  axiosPostRequest,
  get,
  put,
  remove,
} from "../../../services/Request";
import { useHistory } from "react-router-dom";
import NewTable from "../../../components/NewTable";
import CustomTable from "../../../components/CustomTable";
import ticketsIcon from "../../../assets/icons/ticketsIcon.svg";
import DrawerFilter from "../../../components/DrawerFilter";
import FilePreview from "../../../components/upload/filePreview";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import useExcelExport from "../../../helpers/customHooks/useExcelExport";
import {
  all,
  done,
  overflow,
  progress,
  removeCircle,
  removeCircleDark,
} from "../../../assets/icons/dashboard";
import Skeleton from "react-skeleton-loader";
import SCard from "../../../components/dashboard/SCard";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";
import { toast } from "react-toastify";
import DeleteIcon from "../../../components/DeleteIcon";
import NewTooltip from "../../../components/NewTooltip";
import ReactSwitch from "react-switch";
import {
  checkRoute,
  formatDate,
  permissionsCheck,
} from "../../../helpers/utils";

import axios from "axios";
import { close } from "../../../assets/icons/Index";
import StatusAction from "./StatusAction";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import AppModal from "../../../components/Models/appModal";
import { Accordion } from "flowbite-react";
import { parseDateTime } from "../../../helpers/dateFormat";
import { editableStringRendererName } from "survey-creator-core";
import { isAgency } from "../../../helpers/isPermission";

const EXCEED_SERVICES_LIMIT_VALUE = 3

const TicketsList = ({ permission }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tickets, setTickets] = useState([]);
  let [ticketsForExcel, setTicketsForExcel] = useState([]);
  const [agencyId, setAgencyId] = useState(0);
  const [editIsSupportedStandard, setEditIsSupportedStandard] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [categories, setCategories] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [sortTypes, setSortTypes] = useState([]);
  const [sortType, setSortType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [openStatistics, setOpenStatistics] = useState(false);

  const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();
  const [dateStyle, setDateStyle] = useState({ border: "2px solid #f4f7fe" });
  const [filteredTickets, setFilteredTickets] = useState([]);

  const [statusList, setStatusList] = useState([
    { value: "1", label: "تحت الاٍجراء" },
    { value: "2", label: "مغلقة" },
    { value: "3", label: "تجاوز مستوى الخدمة" },
    { value: "4", label: "جديد" },
  ]);

  const [escalations, setEscalations] = useState([
    { value: "1", label: "تذكير ١" },
    { value: "2", label: "تذكير ٢" },
    { value: "3", label: "تصعيد ١" },
    { value: "4", label: "تذكير لتصعيد ١" },
    { value: "5", label: "تصعيد ٢" },
  ]);
  const [escalation, setEscalation] = useState(null);

  const [ticketStatistics, setTicketStatistics] = useState(null);
  const [category, setCategory] = useState(null);
  const [priority, setPriority] = useState(null);
  const [status, setStatus] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [editId, setEditId] = useState(0);
  const [editCategory, setEditCategory] = useState({});
  const [editPriority, setEditPriority] = useState({});
  const [editStatus, setEditStatus] = useState({});
  // const [agencies,setAgencies] = useState([])

  const [standard, setStandard] = useState({});
  const [agency, setAgency] = useState("");
  const [date, setDate] = useState("");

  const [standards, setStandards] = useState([]);
  const [perPage, setPerPage] = useState({ label: "10", value: 10 });

  const [getCategoriesForFilter, categoriesForFilter] = useAxiosGetRequest();

  useEffect(() => {
    getCategoriesForFilter(environment.getCategoriesForFilter);
  }, []);

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  const categoriesOptions =
    categoriesForFilter?.result?.items &&
    categoriesForFilter?.result?.items?.map((item) => {
      return { label: item.name.toString(), value: item.id };
    });

  function getCreatorUserNameStyle(creatorUserName) {
    if (creatorUserName === "admin") {
      return "bg-green/30 text-emerald-600";
    } else if (creatorUserName === "agency") {
      return "bg-[#0288d1]/30 text-[#005f92]";
    } else {
      return "";
    }
  }

  function getStatusStyle(status) {
    if (status === "جديد") {
      return "bg-[#5bc0de] text-white";
    } else if (status === "تحت الاٍجراء") {
      return "bg-[#22bb33] text-white";
    } else if (status === "مغلقة") {
      return "bg-[#bb2124] text-white";
    } else if (status === "تجاوز مستوى الخدمة") {
      return "bg-[#f0ad4e] text-white";
    } else {
      return "bg-[#5bc0de] text-white";
    }
  }

  function getEscalationStyle(escalation) {
    if (escalation === 1) {
      return "bg-[#2dff00] text-white";
    } else if (escalation === 2) {
      return "bg-[#4a86e8] text-white";
    } else if (escalation === 3) {
      return "bg-[#ffff00] text-white";
    } else if (escalation === 4) {
      return "bg-[#fd9901] text-white";
    } else if (escalation === 5) {
      return "bg-[#fc0001] text-white";
    } else {
      return "bg-[#2dff00] text-white";
    }
  }

  const getFile = (id, name, uri) => {
    axios
      .get(process.env.REACT_APP_FRONT_URL + uri, {
        responseType: "blob",
      })
      .then((obj) => {
        const url = window.URL.createObjectURL(obj.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);
        toast.success("تم تحميل الملف بنجاح", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  // const downloadFile=(id,name,uri)=>{
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //     },
  //   };

  //   post(environment.downloadFile + "?id=" + id, {}, config, (res) => {
  //     const url = window.URL.createObjectURL(res.data)
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "/images/"+name);
  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     toast.success("تم تحميل الملف بنجاح", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //     setMessage(
  //       { type: "success", message: t("تم تحميل الملف بنجاح") },
  //     );
  //   });
  // }

  function getStatusCategoryStyle(status) {
    if (status === "استفسار عن معيار") {
      return "bg-[#5bc0de] font-bold text-white rounded-lg";
    } else if (status === "استفسار عام") {
      return "bg-[#22bb33] font-bold text-white rounded-lg";
    } else if (status === "فئة جهة الصحة") {
      return "bg-[#bb2124] font-bold text-white rounded-lg";
    } else if (status === "استفسار تقنى") {
      return "bg-[#f0ad4e] font-bold text-white rounded-lg";
    } else {
      return "bg-[#5bc0de] font-bold text-white rounded-lg";
    }
  }

  const columns = [
    {
      name: t("modules.main.tickets.number"),
      selector: (row) => row.ticketNumber,
      header: "ticketNumber",
      accessorKey: "ticketNumber",
      footer: (props) => props.column.ticketNumber,
      width: "200px",
      sortable: true,
      cell: (row) => (
        <div
          className="cursor-pointer text-[#108AE0] font-extrabold border-[1px] border-[#108AE0] p-1"
          // onClick={(e) => history.push("/ticket/" + row.id)}
          onClick={() =>
            window.open("/ticket/" + row.id, "_blank", "noreferrer")
          }
        >
          {row.ticketNumber}
        </div>
      ),
    },
    {
      name: t("modules.main.tickets.subject"),
      selector: (row) => row.subject,
      header: "subject",
      accessorKey: "subject",
      center: true,
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden truncate max-w-full">
          {row.subject}
        </div>
      ),
    },
    {
      name: t("modules.main.tickets.creatorUser"),
      selector: (row) => row.creatorUserName,
      header: "creatorUserName",
      accessorKey: "creatorUserName",
      center: true,
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="py-3">
          <span
            className={`text-center px-4 py-2 mr-auto items-center text-sm leading-none rounded-lg`}
          >
            {row.creatorUserName !== "" ? row.creatorUserName : "—"}
          </span>
        </div>
      ),
    },
    {
      name: t("modules.main.tickets.agency"),
      selector: (row) => row.agencyTitle,
      header: "agencyTitle",
      accessorKey: "agencyTitle",
      center: true,
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-center flex justify-center w-full">
          <span className="w-full text-center flex justify-center">
            {row.agencyTitle !== "" ? row.agencyTitle : "—"}
          </span>
        </div>
      ),
    },
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.Delegate")
      ? {}
      : {
          name: t("التصعيد"),
          selector: (row) => row.ticketEscalationType,
          header: "ticketEscalationType",
          accessorKey: "ticketEscalationType",
          center: true,
          sortable: true,
          footer: (props) => props.column.id,
          cell: (row) => (
            // <div className={`text-center`}>
            //   {row.categoryName !== "" ? row.categoryName : "—"}
            // </div>
            <div
              style={{ padding: "7px", paddingBottom: "10px" }}
              className={`text-center rounded-xl ${getEscalationStyle(
                row.ticketEscalationType
              )}`}
            >
              {getEscalationText(row.ticketEscalationType)}
            </div>
          ),
        },
    {
      name: t("modules.main.tickets.category"),
      selector: (row) => row.category,
      header: "category",
      accessorKey: "category",
      center: true,
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        // <div className={`text-center`}>
        //   {row.categoryName !== "" ? row.categoryName : "—"}
        // </div>
        <div
          style={{ padding: "7px", paddingBottom: "10px" }}
          className={`text-center ${getStatusCategoryStyle(row.categoryName)}`}
        >
          {row.categoryName !== "" ? row.categoryName : "—"}
        </div>
      ),
    },
    {
      name: t("modules.main.tickets.priority"),
      selector: (row) => row.priority,
      header: "priority",
      accessorKey: "priority",
      center: true,
      sortable: true,
      footer: (props) => props.column.priority,
      cell: (row) => (
        <div
          className="text-white w-28 rounded-xl text-center text-sm"
          style={{
            padding: "7px",
            paddingBottom: "10px",
            backgroundColor: row.priorityColor,
          }}
        >
          {row.priorityName}
        </div>
      ),
    },
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.Delegate")
      ? {}
      : {
          name: t("modules.main.tickets.status.title"),
          selector: (row) => row.ticketStatus,
          header: "ticketStatus",
          accessorKey: "ticketStatus",
          center: true,
          sortable: true,
          footer: (props) => props.column.id,
          cell: (row) => (
            <div
              style={{ padding: "7px", paddingBottom: "10px" }}
              className={`text-white w-28 rounded-xl text-center text-sm ${getStatusStyle(
                getStatusText(row.ticketStatus)
              )}`}
            >
              {getStatusText(row.ticketStatus)}
            </div>
          ),
        },
    {
      name: t("modules.main.tickets.standard"),
      selector: (row) => row.standardTitle,
      header: "standardTitle",
      accessorKey: "standardTitle",
      center: true,
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-center">
          {row.standardTitle == "" ? "لا يوجد" : row.standardTitle}
        </div>
      ),
    },
    {
      name: t(" رقم المعيار"),
      selector: (row) => row.standardCode,
      header: "standardCode",
      accessorKey: "standardCode",
      center: true,
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-center">
          {row.standardCode == "" ? "لا يوجد" : row.standardCode}
        </div>
      ),
    },
    {
      name: t("modules.main.tickets.time.title"),
      selector: (row) => row.timing,
      header: "timing",
      accessorKey: "timing",
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => <div>{row.timing}</div>,
    },
    {
      name: t("التاريخ"),
      selector: (row) => row.creationTime,
      header: "التاريخ",
      accessorKey: "creationTime",
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => <div>{row.creationTime}</div>,
    },
    {
      name: t("الوقت"),
      selector: (row) => row.ticketTime,
      header: "الوقت",
      accessorKey: "ticketTime",
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => <div>{row.ticketTime}</div>,
    },
    {
      name: t("الملف"),
      selector: (row) => row.attachmentUri,
      header: "الملف",
      accessorKey: "attachmentUri",
      sortable: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div>
          {row.attachmentUri && (
            <div className="flex flex-row items-center">
              <div
                className={
                  "relative rounded-2xl px-5 py-2 flex flex-row items-center "
                }
              >
                <span
                  className={"w-1.5 h-1.5 absolute rounded-full cursor-pointer"}
                >
                  <FilePreview
                    id={row.attachmentId}
                    fileName={row.attachmentName}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      ),
    },
    // {
    //   name: t("modules.main.mailbox.actions"),
    //   selector: (row) => row.id,
    //   header: "id",
    //   accessorKey: "id",
    //   // center: true,
    //   footer: (props) => props.column.id,
    //   width: "180px",
    //   cell: (row) => (
    //     <div className="flex">
    //       {permissionsCheck("Pages.Tickets.Get") ? (
    //         <NewTooltip content={"تفاصيل"}>
    //           <FontAwesomeIcon
    //             icon={faBinoculars}
    //             className="bg-blue-50 rounded-full text-sky-500 p-2 cursor-pointer"
    //             onClick={(e) => history.push("/ticket/" + row.id)}
    //           />
    //         </NewTooltip>
    //       ) : null}
    //       {/* {row.ticketStatus == 1 && !row.standardTitle && ( */}
    //       {permissionsCheck("Pages.Tickets.Edit") ? (
    //         <div>
    //           {row.ticketStatus == 1 && (
    //             <NewTooltip content={"تعديل"}>
    //               <FontAwesomeIcon
    //                 icon={faPen}
    //                 className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
    //                 onClick={(e) => edit(row)}
    //               />
    //             </NewTooltip>
    //           )}
    //         </div>
    //       ) : null}
    //       {permissionsCheck("Pages.Tickets.Delete") ? (
    //         <div>
    //           {row.ticketStatus == 1 &&
    //             permissionsCheck("Pages.UserType.Admin") && (
    //               <NewTooltip content={"حذف"}>
    //                 <DeleteIcon
    //                   loading={deleteLoading}
    //                   deletedName={"هذا الاستفسار"}
    //                   deleteFunction={() => deleteTicket(row.id)}
    //                 />
    //               </NewTooltip>
    //             )}
    //         </div>
    //       ) : null}
    //     </div>
    //   ),
    // },
    permissionsCheck("Pages.Tickets.Close")
      ? {
          name: "اغلاق الاستفسار",
          header: "اغلاق الاستفسار",
          accessorFn: (row) => row.status,
          sortable: true,
          footer: (props) => props.column.id,
          cell: (info) =>
            info.isHasApprovedReply ? (
              <StatusAction ticket={info} onSuccess={() => getAllList(0, 10)} />
            ) : (
              <p>لا يمكنك غلق الاستفسار</p>
            ),
        }
      : "",
    // permissionsCheck("Pages.UserType.ExpertManager") || permissionsCheck("Pages.UserType.Admin")
    // ? {
    //     name: "خبراء",
    //     header: "خبراء",
    //     accessorFn: (row) => row.experts,
    //     sortable: true,
    //     footer: (props) => props.column.id,
    //     cell: (info) => (
    //       info.categoryId == 3 ? <AssignExperts ticket={info} onSuccess={() => getAllList(0)} /> : "لا يوجد"
    //     ),
    //   }
    // : "",
    // permissionsCheck("Pages.UserType.ExpertManager") || permissionsCheck("Pages.UserType.Admin")
    // ? {
    //     name: "POC",
    //     header: "POC",
    //     accessorFn: (row) => row.poc,
    //     sortable: true,
    //     footer: (props) => props.column.id,
    //     cell: (info) => (
    //       info.categoryId == 3 ? <AssignPOC ticket={info} onSuccess={() => getAllList(0)} /> : "لا يوجد"
    //     ),
    //   }
    // : "",
  ];

  function AssignExperts({ ticket }) {
    const { t, i18n } = useTranslation();
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedExperts, setSelectedExperts] = useState([]);
    const [experts, setExperts] = useState([]);
    useEffect(() => {
      getExperts();
    }, []);
    const getExperts = async () => {
      const res = await axiosGetRequest(environment.getAllExpertLookUp);
      let arr = [];
      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });
      setExperts(arr);
      if (res?.success) {
      }
    };
    const assignTicketToExpert = async () => {
      //   let assignedExperts = []
      //   selectedExperts.forEach((expert,index)=>{
      //      assignedExperts.push({expertId : expert.id})
      //   })
      //  let data = {
      //   standardId:id,
      //   assignedExperts:assignedExperts
      //  }
      //   const res = await axiosPostRequest(
      //     environment.assignStandardToExpert,data
      //   );
      //   if (res?.success) {
      //     let arr = [];
      //     res?.result?.items?.forEach((element) => {
      //       let obj = {
      //         id: element.id,
      //         value: element.id,
      //         label: element.fullName,
      //       };
      //       arr.push(obj);
      //     });
      //     setExperts(arr);
      //   }
    };
    const [loading, setLoading] = useState(false);
    const handleAssignExpert = () => {
      // setLoading(true);
      if (selectedExperts) {
        assignTicketToExpert();
      }
      // setLoading(false);
      // if (!loading) setShowAssignModal(false);
    };

    const handleChangeExperts = (value) => {
      setSelectedExperts(value || []);
    };
    return (
      <>
        <div className="flex justify-center items-center space-x-2">
          <div></div>
          <AppModal
            handleSubmit={handleAssignExpert}
            open={showAssignModal}
            setOpen={setShowAssignModal}
            loading={loading}
            submitLabel="إضافة"
          >
            <NewDropdownMultiSelect
              label={"الخبراء"}
              placeholder={"اختر خبراء"}
              options={experts}
              handleChange={handleChangeExperts}
              value={selectedExperts}
              isMultiple={true}
            />

            {/* <div className="text-sm text-red-600 mt-3">
            {errors.experts && touched.experts && errors.experts}
          </div> */}
          </AppModal>
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => setShowAssignModal(true)}
          >
            <FontAwesomeIcon
              fontSize={20}
              icon={faUser}
              style={{ color: "#3333FF" }}
            />
          </button>
        </div>
      </>
    );
  }

  function AssignPOC({ ticket }) {
    const { t, i18n } = useTranslation();
    const [showAssignPOCModal, setShowAssignPOCModal] = useState(false);
    const [selectedPOCs, setSelectedPOCs] = useState([]);
    const [agency, setAgency] = useState("");

    const [pocs, setPOCs] = useState([]);

    useEffect(() => {
      getEntitiesForFilter(environment.getAllAgencyWithoutPagination);
    }, []);

    const agencies =
      entitiesForFilter?.result &&
      entitiesForFilter?.result?.map((item) => {
        return { label: item?.nameInArabic?.toString() || "", value: item.id };
      });

    useEffect(() => {
      getPOCs();
    }, []);
    const getPOCs = async () => {
      const res = await axiosGetRequest(environment.getAllExpertLookUp);
      let arr = [];
      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });
      setPOCs(arr);
      if (res?.success) {
      }
    };
    const assignTicketToPOC = async () => {
      //   let assignedExperts = []
      //   selectedExperts.forEach((expert,index)=>{
      //      assignedExperts.push({expertId : expert.id})
      //   })
      //  let data = {
      //   standardId:id,
      //   assignedExperts:assignedExperts
      //  }
      //   const res = await axiosPostRequest(
      //     environment.assignStandardToExpert,data
      //   );
      //   if (res?.success) {
      //     let arr = [];
      //     res?.result?.items?.forEach((element) => {
      //       let obj = {
      //         id: element.id,
      //         value: element.id,
      //         label: element.fullName,
      //       };
      //       arr.push(obj);
      //     });
      //     setExperts(arr);
      //   }
    };
    const [loading, setLoading] = useState(false);
    const handleAssignTicket = () => {
      setLoading(true);
      if (selectedPOCs) {
        assignTicketToPOC();
      }
      setLoading(false);
      if (!loading) setShowAssignPOCModal(false);
    };

    const handleChangePOCs = (value) => {
      setSelectedPOCs(value || []);
    };
    const handleChangeAgency = (value) => {
      setAgency(value);
    };
    return (
      <>
        <div className="flex justify-center items-center space-x-2">
          <div></div>
          <FModal
            handleSubmit={handleAssignTicket}
            open={showAssignPOCModal}
            setOpen={setShowAssignPOCModal}
            loading={loading}
            submitLabel="إضافة"
          >
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={agency}
              options={agencies}
              isMultiple={false}
              name="agency"
              handleChange={handleChangeAgency}
              placeholder={"اختر الجهة"}
              label={t("الجهة")}
            />
            <NewDropdownMultiSelect
              label={"poc"}
              placeholder={"اختر poc"}
              options={pocs}
              handleChange={handleChangePOCs}
              value={selectedPOCs}
              isMultiple={true}
            />

            {/* <div className="text-sm text-red-600 mt-3">
            {errors.experts && touched.experts && errors.experts}
          </div> */}
          </FModal>
          <button
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => setShowAssignPOCModal(true)}
          >
            <FontAwesomeIcon
              fontSize={20}
              icon={faUser}
              style={{ color: "#00CC66" }}
            />
          </button>
        </div>
      </>
    );
  }

  // ------------------------------------------------- //
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [getTicketsStats, ticketsStats, getStatsLoading] = useAxiosGetRequest();

  useEffect(() => {
    getTicketsStats(environment.getTicketsStats);
  }, []);

  const stats = ticketsStats?.result;

  // list of stats card
  const ticketsList = [
    {
      value: null,
      title: "الكل",
      number: stats?.allTicketsCount,
      icon: all,
      color: "rgba(195, 43, 255, 0.15)",
    },
    {
      value: 1,
      title: "تحت الاٍجراء",
      number: stats?.inProgressTicketsCount,
      icon: progress,
      color: "rgba(255, 162, 23, 0.15)",
    },
    {
      value: 2,
      title: "مغلقة",
      number: stats?.closedTicketsCount,
      icon: removeCircleDark,
      color: "rgba(248, 30, 30, 0.15)",
    },
    {
      value: 5,
      title: "المتبقى",
      number: stats?.restTicketsCount,
      icon: done,
      color: "rgba(55, 255, 155, 0.192)",
    },
  ];

  if (
    permissionsCheck("Pages.UserType.Admin") ||
    permissionsCheck("Pages.UserType.TechnicalInquiryManager") ||
    permissionsCheck("Pages.UserType.InquiryManager") ||
    permissionsCheck("Pages.UserType.ExpertManager") ||
    permissionsCheck("Pages.UserType.Viewer") ||
    permissionsCheck("Pages.UserType.POCOfficer")
  ) {
    ticketsList.push({
      value: 3,
      title: "تجاوز مستوى الخدمة",
      number: stats?.passedSLATicketsCount,
      icon: overflow,
      color: "rgba(0, 70, 222, 0.15)",
    });
  }

  const [selectedCard, setSelectedCard] = useState(null);
  // ------------------------------------------------- //

  const handleChangeSearchCategory = (value) => {
    setCategory(value);
  };

  const handleChangeSearchEscalation = (value) => {
    setEscalation(value);
  };

  const handleChangeSearchPriority = (value) => {
    setPriority(value);
  };

  const handleChangeSearchStatus = (value) => {
    setStatus(value);
  };

  useEffect(() => {
    setAgencyId(localStorage.getItem("agencyId"));
    getAllCategories();
    getAllPriorities();
  }, []);

  useEffect(() => {
    getAllList(0, 10);
  }, [selectedCard, searchText]);

  const getAllList = (page, perPage) => {
    setTickets([]);
    setCurrentPage(page);

    const searchParams = new URLSearchParams()
    searchParams.append("skipCount", page * perPage)
    searchParams.append("maxResultCount", perPage)
    
    if(category && category.value){
      searchParams.append("categoryId", category.value)
    }

    if (priority && priority.value) {
      searchParams.append("priorityId", category.value)
    }

    if(selectedCard && selectedCard.value){
      if(selectedCard.value === EXCEED_SERVICES_LIMIT_VALUE){
        searchParams.append("&hasPreviousPassedSLA", true);
      }else{
        searchParams.append("ticketStatus", selectedCard.value);
      }
    }

    if (agency && agency.value) {
      searchParams.append("AgencyId", agency.value);
    }

    if (sortType && sortType.value) {
      searchParams.append("SortingType", sortType.value);
    }
    if (standard && standard.value) {
      searchParams.append("StandardId", standard.value);
    }

    if (status && status.value) {
      if(status.value === EXCEED_SERVICES_LIMIT_VALUE){
        searchParams.append("hasPreviousPassedSLA", true);
        // 
      }else{
        searchParams.append("ticketStatus", status.value);
      }
    }

    if (searchText) {
      searchParams.append("KeyWord", searchText);
    }

    if (date) {
      searchParams.append("Date", date);
    }

    if (escalation && escalation.value) {
      searchParams.append("TicketEscalationType", escalation.value);
    }

    var query = "?skipCount=" + page * perPage + "&maxResultCount=" + perPage;
    if (category && category.value) {
      query = query + "&categoryId=" + category.value;
    }
    if (priority && priority.value) {
      query = query + "&priorityId=" + priority.value;
    }

    if (selectedCard && selectedCard.value) {
      if(selectedCard.value === EXCEED_SERVICES_LIMIT_VALUE){
        query = query + "&hasPreviousPassedSLA=" + true;
      }else{
        query = query + "&ticketStatus=" + selectedCard.value;
      }
    }

    if (agency && agency.value) {
      query = query + "&AgencyId=" + agency.value;
    }
  
    if (sortType && sortType.value) {
      query = query + "&SortingType=" + sortType.value;
    }
    if (standard && standard.value) {
      query = query + "&StandardId=" + standard.value;
    }

    if (status && status.value) {
      if(status.value === EXCEED_SERVICES_LIMIT_VALUE){
        query = query + "&hasPreviousPassedSLA=" + true;
        // 
      }else{
        query = query + "&ticketStatus=" + status.value;
      }
    }
  
    if (searchText) {
      query = query + "&KeyWord=" + searchText;
    }
    if (date) {
      query = query + "&Date=" + date;
    }
    if (escalation && escalation.value) {
      query = query + "&TicketEscalationType=" + escalation.value;
    }
    getAllTickets(query, perPage);
    getAllTicketsForExcel(query);
  };

  useEffect(() => {
    getEntitiesForFilter(environment.getAllAgencyWithoutPagination);
  }, []);

  const agencies =
    entitiesForFilter?.result &&
    entitiesForFilter?.result?.map((item) => {
      return { label: item?.nameInArabic?.toString() || "", value: item.id };
    });

  const getAllTickets = (query, perPage) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let agencyId = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      agencyId = "&AgencyId=" + localStorage.getItem("agencyId");
    }

    let technicalInquiry = "";
    if (
      permissionsCheck("Pages.Tickets.TechnicalOfficer") &&
      permissionsCheck("Pages.UserType.TechnicalInquiryManager")
    ) {
      technicalInquiry = "&categoryId=" + 2;
    }

    get(environment.getAllTickets + query + technicalInquiry, config, (res) => {
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
      } else if (res.status == 200) {
        setTickets(res.data.result.items);
        setTotalPages(res.data.result.totalCount / perPage);
        setLoading(
          false
          // clearSearch()
        );
      }
    });
  };

  const getAllTicketsForExcel = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let agencyId = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      agencyId = "&AgencyId=" + localStorage.getItem("agencyId");
    }

    let technicalInquiry = "";
    if (
      permissionsCheck("Pages.Tickets.TechnicalOfficer") &&
      permissionsCheck("Pages.UserType.TechnicalInquiryManager")
    ) {
      technicalInquiry = "&categoryId=" + 2;
    }

    var query = "?skipCount=" + 0 + "&MaxResultCount=" + 1000000;
    if (category && category.value) {
      query = query + "&categoryId=" + category.value;
    }
    if (priority && priority.value) {
      query = query + "&priorityId=" + priority.value;
    }
    if (selectedCard && selectedCard.value) {
      // query = query + "&ticketStatus=" + status.value;
      query = query + "&ticketStatus=" + selectedCard.value;
    }
    if (agency && agency.value) {
      query = query + "&AgencyId=" + agency.value;
    }
    if (standard && standard.value) {
      query = query + "&StandardId=" + standard.value;
    }
    if (status && status.value) {
      query = query + "&TicketStatus=" + status.value;
    }
    if (date) {
      query = query + "&Date=" + date;
    }
    if (escalation && escalation.value) {
      query = query + "&TicketEscalationType=" + escalation.value;
    }

    get(environment.getAllTickets + query + technicalInquiry, config, (res) => {
      if (res.status == 403) {
      } else if (res.status == 500) {
      } else if (res.status == 200) {
        // res?.data?.result?.items.forEach((item,index)=>{
        //   cleanHTML(item.description)
        // })
        setTicketsForExcel(res?.data?.result?.items);
        // setFilteredTickets(res?.data?.result?.items)
      }
    });
  };

  const deleteTicket = (id) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteTicket + "?Id=" + id, config, (res) => {
      setDeleteLoading(false);
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
      } else if (res.status == 200) {
        // toast.success("تم حذف الاستفسار بنجاح");
        toast.success(t("تم حذف الاستفسار بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getAllList(currentPage, perPage.value);
      }
    });
  };

  const getAllCategories = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get("services/app/Category/GetAll", config, (res) => {
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name.toString(),
              isSupported: res.data.result.items[i].isSupported,
              isSelected: false,
            });
          }
          setCategories(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const getAllAgencies = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res?.status == 200) {
        if (res?.data.result && res?.data?.result?.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.length; i++) {
            array.push({
              value: res?.data?.result[i].id,
              label: res?.data?.result[i].nameInArabic?.toString() || "",
              // isSupported: res?.data?.result[i]?.isSupported,
              // isSelected: false,
            });
          }
          // setAgencies(array);
        }
        // setTimeout(() => {
        // setLoading(false);
        // }, );
      }
    });
  };

  const getAllPriorities = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllPriorities, config, (res) => {
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name,
              isSelected: false,
            });
          }
          setPriorities(array, getAllStandards());
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  function getStatusText(status) {
    switch (status) {
      // case null:
      //   return t("modules.main.tickets.status.newTicket");
      case 1:
        return "تحت الاٍجراء";
      case 2:
        return "مغلقة";
      case 3:
        return "تجاوز مستوى الخدمة";
      case 4:
        return "جديد";
      default:
        return "تحت الاٍجراء";
    }
  }

  function getEscalationText(escalation) {
    switch (escalation) {
      // case null:
      //   return t("modules.main.tickets.status.newTicket");
      case 1:
        return "تذكير ١";
      case 2:
        return "تذكير ٢";
      case 3:
        return "تصعيد ١";
      case 4:
        return "تذكير لتصعيد ١";
      case 5:
        return "تصعيد ٢";
      default:
        return "تحت الاٍجراء";
    }
  }

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllList(newOffset, perPage.value);
  };

  const search = () => {
    getAllList(currentPage, perPage.value);
  };

  const clearSearch = () => {
    setCategory(null);
    setPriority(null);
    setStatus(null);
    setAgency(null);
    setStandard(null);
  };

  function handleMenu() {
    setShowMenu(!showMenu);
  }

  const handleChangeEditCategory = (value) => {
    setEditCategory(value);
  };

  const handleChangeEditPriority = (value) => {
    setEditPriority(value);
  };

  const handleChangeEditStatus = (value) => {
    setEditStatus(value);
  };

  const edit = (data) => {
    setEditStatus(
      statusList.find((x) => {
        return x.value == data.ticketStatus;
      }) || { label: "تحت الاٍجراء", value: "1" }
    );
    setEditCategory(
      categories.find((x) => {
        return x.value == data.categoryId;
      })
    );
    setEditPriority(
      priorities.find((x) => {
        return x.value == data.priorityId;
      })
    );

    setStandard({
      value: data.standardId,
      label: data.standardCode,
    
    });

    setEditId(data.id);
    setEditIsSupportedStandard(data.categoryIsSupportStandard);
    setOpen(true);
  };

  function saveTicket() {
    setEditLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    let data = {
      id: editId,
      categoryId: editCategory?.value,
      priorityId: editPriority?.value,
      ticketStatus: editStatus?.value,
    };

    if (standard != null) {
      data.standardId = standard.value;
    }

    if(data.standardId === 0){
      data.standardId = null
    }

    // return true
    put(environment.updateTicket, data, config, (res) => {
      setEditLoading(false);
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
      } else if (res.status == 200) {
        // toast.info("تم تعديل الاستفسار بنجاح");
        toast.success(t("تم تعديل الاستفسار بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getAllList(currentPage, perPage.value);
        getTicketsStats(environment.getTicketsStats);
        setOpen(false);
      }
    });
  }

  const ticketBreadcumb = [
    {
      icon: ticketsIcon,
      title: "الاستفسارات",
    },
  ];

  const handleChangeStandard = (value) => {
    setStandard(value);
  };

  const handleChangeSortTypes = (value) => {
    setSortType(value);
  };

  const getAllStandards = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getStandards + "?MaxResultCount=" + 1000, config, (res) => {
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setMessage({ type: "error", message: res.data.error.message });
        // setOpenModal(true);
        // setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].code,
              isSelected: false,
            });
          }
          setStandards(array);
        }
        // setTimeout(() => {
        //   setLoading(false);
        // }, 500);
      }
    });
  };

  useEffect(() => {
    getTicketStatistics();
  }, []);

  const getTicketStatistics = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getTicketStatistics, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        setTicketStatistics(res.data.result);
      }
    });
  };

  const handleChangeAgency = (value) => {
    setAgency(value);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  // useEffect(() => {
  //   getAllTicketsForExcel();
  // }, []);

  const downloadExcel = useExcelExport();

  const handleFilterTickets = (value) => {
    setSearchText(value, getAllList(currentPage, perPage.value));
    // if (!value || value === " ") {
    //   setFilteredTickets(tickets);
    // }

    // var searchData = tickets.filter((item) => {
    //   if (
    //     item.ticketNumber
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()) ||
    //     item.description
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()) ||
    //     item.agencyTitle
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()) ||
    //     item.categoryName
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()) ||
    //     item.subject.toString().toLowerCase().includes(value.toLowerCase()) ||
    //     item.priorityName
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()) ||
    //     item.creatorUserName
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase()) ||
    //     item.creationTime.toString().toLowerCase().includes(value.toLowerCase())
    //   ) {
    //     return item;
    //   }
    // });
    // setFilteredTickets(searchData);
    // setTicketsForExcel(searchData);
  };

  const handlePerRowsChange = (value) => {
    setPerPage(value, getAllList(currentPage, value.value));
  };

  function convertHtmlToString(htmlString) {
    if (!htmlString) { return ""; }
    let doc = new DOMParser().parseFromString(htmlString, "text/html");
    let textContent = "";

    function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        textContent += node.textContent.trim() + " ";
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        for (let childNode of node.childNodes) {
          traverse(childNode);
        }
      }
    }

    traverse(doc.body);
    return textContent.trim() ?? " ";
  }


  ticketsForExcel = ticketsForExcel.map((ele) => {
    return {
      ...ele,
      description: convertHtmlToString(ele.description),
      inquiryAnswer: ele.ticketHistories.map(el => convertHtmlToString(el?.comment)).join(","),
      inquiryAnswerTime: ele.ticketHistories.length
        ? `اليوم : ${
            parseDateTime(ele.ticketHistories[0]?.creationTime)?.date
          }  -  الوقت : ${
            parseDateTime(ele.ticketHistories[0]?.creationTime)?.time
          }`
        : "",
    };
  });

  const handleExportExcel = async () => {
    if(isAgency()){
      ticketsForExcel = ticketsForExcel.map((ele) => {
        return {
          "الاستفسار": ele.description,
          "نوع الاستفسار": ele.categoryName,
          "رقم الاستفسار": ele.ticketNumber,
          "الموضوع": ele.subject,
          "إجابة الإستفسار": ele.inquiryAnswer ?? " " ,
        }
      });
        
    }

    await downloadExcel("تقرير الاستفسارات", ticketsForExcel);
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={ticketBreadcumb}
      btnLabel={
        permissionsCheck("Pages.UserType.Admin") ||
        permissionsCheck("Pages.UserType.InquiryManager")
          ? false
          : "اٍنشاء طلب استفسار"
      }
      handleBtnClick={() => history.push("/new-ticket")}
      hideBtn={permissionsCheck("Pages.Tickets.Create") ? false : true}
      handleBtnSeconedClick={() => setOpenStatistics(true)}
      hideBtnSeconed={
        permissionsCheck("Pages.UserType.Admin") ||
        permissionsCheck("Pages.UserType.ExpertManager") ||
        permissionsCheck("Pages.UserType.InquiryManager")
          ? false
          : true
      }
      btnLabelSeconed={"الاحصائيات"}
    >
      {/* <NewHeader />
      <Title
        iconTitle={ticketsIcon}
        onClick={() => history.push("/new-ticket")}
        style={{ backgroundColor: "#2B2969", marginBottom: "10px" }}
        single={true}
        title={"الإستفسارات"}
        titleButton={t("modules.main.tickets.newTicket")}
        withIcon={true}
        icon={plusWhite}
        withoutButton={
          permissionsCheck("Pages.UserType.Admin") ? true : false
        }
      /> */}
      <SModal
        type={message.type}
        open={openModal}
        setOpen={() => setOpenModal(false)}
        subTitle={message.message}
      />
      <FModal
        loading={editLoading}
        headerTitle={t("احصائيات")}
        content={
          <div>
            <div>
              <div className="text-[#202969] text-lg">
                اكثر معيار تم الاستفسار عنه :
              </div>
              <ul className="mt-3">
                <li> اسم المعيار : {ticketStatistics?.standardTitle}</li>
                <li> كود المعيار : {ticketStatistics?.standardCode}</li>
              </ul>
            </div>
            <div className="mt-7">
              <div className="text-[#202969] text-lg">
                اكثر ٥ جهات قامت بعمل استفسار :
              </div>
              {ticketStatistics?.agencies.length == 0 ? (
                <div className="mt-3">لا يوجد جهات</div>
              ) : (
                <ul className="mt-3">
                  {ticketStatistics?.agencies?.map((subItem, index) => (
                    <li>{subItem.agencyNameInArabic}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        }
        submitLabel={"احصائيات"}
        open={openStatistics}
        setOpen={() => setOpenStatistics(false)}
        action={saveTicket}
        actions={false}
      />
      <FModal
        loading={editLoading}
        headerTitle={t("modules.main.tickets.editTicket")}
        content={
          <div>
            {permissionsCheck("Pages.TicketsCategory.GetAll") ? (
              <DropdownMultiSelect
                withoutLabel={false}
                isSearchable={true}
                value={editCategory}
                handleChange={handleChangeEditCategory}
                placeholder={t("modules.main.tickets.category")}
                label={t("modules.main.tickets.category")}
                options={categories}
                isMultiple={false}
                name="editCategory"
                style={{ borderColor: editCategory != "" ? "#22bb33" : "red" }}
              />
            ) : null}
            {editIsSupportedStandard && (
              <div className="">
                {/* <label className="label_input text-lg text-[#0D062D] mb-3 w-11/12">
                        {t("modules.main.tickets.standard")}
                      </label> */}
                {permissionsCheck("Pages.Standards.GetAll") ? (
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={true}
                    value={standard}
                    handleChange={handleChangeStandard}
                    placeholder={t("modules.main.tickets.standard")}
                    label={t("modules.main.tickets.standard")}
                    options={standards}
                    isMultiple={false}
                    name="standard"
                    // style={{
                    //   borderColor: errors.standard
                    //     ? "red"
                    //     : values.standard != ""
                    //     ? "#22bb33"
                    //     : "",
                    // }}
                  />
                ) : null}
                {/* <div className="text-sm text-red-600 mt-3">
                        {errors.standard && touched.standard && errors.standard}
                      </div> */}
              </div>
            )}
            {permissionsCheck("Pages.Priority.GetAll") ? (
              <DropdownMultiSelect
                withoutLabel={false}
                isSearchable={true}
                value={editPriority}
                handleChange={handleChangeEditPriority}
                placeholder={t("modules.main.tickets.priority")}
                label={t("modules.main.tickets.priority")}
                options={priorities}
                isMultiple={false}
                name="editPriority"
                style={{ borderColor: editPriority != "" ? "#22bb33" : "red" }}
              />
            ) : null}
            <DropdownMultiSelect
              withoutLabel={false}
              isSearchable={true}
              value={editStatus}
              handleChange={handleChangeEditStatus}
              placeholder={t("modules.main.tickets.status.title")}
              label={t("modules.main.tickets.status.title")}
              options={statusList}
              isMultiple={false}
              name="editStatus"
              style={{ borderColor: editStatus != "" ? "#22bb33" : "red" }}
            />
          </div>
        }
        submitLabel={"تعديل"}
        open={open}
        setOpen={() => setOpen(false)}
        action={saveTicket}
      />

      <div className="m-5">
        <div
          className="my-5 py-5"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
            gap: "20px",
          }}
        >
          {ticketsList.map((item, index) => (
            <SCard
              tickets={true}
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedCard(item)}
              key={index}
              icon={item.icon}
              title={
                getStatsLoading ? (
                  <div className="flex flex-col items-start">
                    <Skeleton
                      width={"50px"}
                      height={"10px"}
                      color={item.color}
                    />
                    <Skeleton
                      width={"100px"}
                      height={"10px"}
                      color={item.color}
                    />
                  </div>
                ) : (
                  item.title
                )
              }
              color={item.color}
              number={item.number}
            />
          ))}
        </div>
      </div>

      <div className="m-5 flex justify-end">
        <DrawerFilter handleSearch={() => search()} handleClear={clearSearch}>
          {permissionsCheck("Pages.TicketsCategory.GetAll") ? (
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={category}
              options={categoriesOptions}
              isMultiple={false}
              name="category"
              handleChange={handleChangeSearchCategory}
              placeholder="اختر الفئة"
              label={t("modules.main.tickets.category")}
            />
          ) : null}
          <DropdownMultiSelect
            filter={true}
            withoutLabel={false}
            isSearchable={true}
            value={escalation}
            options={escalations}
            isMultiple={false}
            name="ticketEscalationType"
            handleChange={handleChangeSearchEscalation}
            placeholder="اختر التصعيد"
            label={t("التصعيد")}
          />
          {permissionsCheck("Pages.Priority.GetAll") ? (
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={priority}
              options={priorities}
              isMultiple={false}
              name="priority"
              handleChange={handleChangeSearchPriority}
              placeholder={"اختر الأولوية"}
              label={t("modules.main.tickets.priority")}
            />
          ) : null}

          <DropdownMultiSelect
            filter={true}
            withoutLabel={false}
            isSearchable={true}
            value={status}
            options={statusList}
            isMultiple={false}
            name="status"
            handleChange={handleChangeStatus}
            placeholder={"اختر الحالة"}
            label={t("الحالة")}
          />
          {permissionsCheck("Pages.UserType.POC") ||
          permissionsCheck("Pages.UserType.CIO") ||
          permissionsCheck("Pages.UserType.Delegate") ? null : (
            <div>
              {permissionsCheck("Pages.Agencies.GetAll") ? (
                <DropdownMultiSelect
                  filter={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={agency}
                  options={agencies}
                  isMultiple={false}
                  name="agency"
                  handleChange={handleChangeAgency}
                  placeholder={"اختر الجهة"}
                  label={t("الجهة")}
                />
              ) : null}
            </div>
          )}

          {permissionsCheck("Pages.Standards.GetAll") ? (
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={standard}
              options={standards}
              isMultiple={false}
              name="standard"
              handleChange={handleChangeStandard}
              placeholder={"اختر المعيار"}
              label={t("المعيار")}
            />
          ) : null}

          <InputLabel
            placeholder={"22-10-2023"}
            label={"تاريخ"}
            isRequired={false}
            onChange={handleChangeDate}
            value={date}
            type="date"
            style={dateStyle}
          />

          {/* <DropdownMultiSelect
            filter={true}
            withoutLabel={false}
            isSearchable={true}
            value={status}
            options={statusList}
            isMultiple={false}
            name="status"
            handleChange={handleChangeSearchStatus}
            placeholder={"اختر الحالة"}
            label={t("modules.main.tickets.status.title")}
          /> */}
          <DropdownMultiSelect
            filter={true}
            withoutLabel={false}
            isSearchable={false}
            value={sortType}
            handleChange={handleChangeSortTypes}
            placeholder={t("الترتيب")}
            label={t("الترتيب")}
            options={[
              { label: "تصاعدي", value: 1 },
              { label: "تنازلي", value: 2 },
            ]}
            isMultiple={false}
          />
        </DrawerFilter>
      </div>
      {permissionsCheck("Pages.Tickets") ? (
        <div className="w-full bg-white p-5">
          {/* <NewTable
            loading={loading}
            columns={columns}
            data={filteredTickets.length ? filteredTickets : tickets}
            pagination={true}
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={currentPage}
            withExcel
            withSearch
            onSearch={handleFilterTickets}
          /> */}
          <CustomTable
            loading={loading}
            columns={columns}
            data={filteredTickets.length ? filteredTickets : tickets}
            pagination
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={currentPage}
            withExcel
            onExportExcel={handleExportExcel}
            onEditClick={edit}
            onDeleteClick={deleteTicket}
            onSearch={handleFilterTickets}
            permissionPage={"Tickets"}
            handlePerPageChange={handlePerRowsChange}
            perPage={perPage}
            showPerPage={true}
          />
        </div>
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {"ليس لديك صلاحية لهذا"}
        </div>
      )}
    </HeaderWithBreadcrumbLayout>
  );
};

export default TicketsList;
