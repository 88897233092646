import { Chart as ChartJS, LineController, LineElement, PointElement } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(PointElement, LineController, LineElement);


const LineChart = () => {
  const data = {
    labels: [
      "2022",
      "2023",
      "2024",
    ],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
    ]
  };
  
  return <Line data={data} />;
};

export default LineChart;



