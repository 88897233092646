import { useHistory } from "react-router-dom";
import { attachment, programsIcon, viewNew } from "../../../assets/icons/Index";
import { DropdownMultiSelect } from "../../../components";
import GTabs from "../../../components/GTabs";
import { Visit } from "../../../routes/Index";
import SCard from "../../../components/dashboard/SCard";
import Skeleton from "react-skeleton-loader";
import {
  expertUser,
  standard,
  standards,
} from "../../../assets/icons/dashboard";
import { useEffect, useRef, useState } from "react";
import {
  axiosDeleteRequest,
  axiosGetRequest,
  axiosPostRequest,
  get,
  post,
  put,
} from "../../../services/Request";
import environment from "../../../services/environment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NewTable from "../../../components/NewTable";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";
import { formatDate, permissionsCheck } from "../../../helpers/utils";
import { Badge, Spinner } from "flowbite-react";
import DrawerFilter from "../../../components/DrawerFilter";
import {
  faBook,
  faCalendar,
  faClock,
  faEye,
  faLock,
  faPen,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewTooltip from "../../../components/NewTooltip";
import AppModal from "../../../components/Models/appModal";
import {
  convertToArabicDayName,
  removeSeconds,
} from "../../../helpers/dateFormat";
import DeleteIcon from "../../../components/DeleteIcon";
import AppTextarea from "../../../components/form/appTextarea";
import { NewForm } from "../../../components/form/NewForm";
import { ErrorMessage } from "formik";
import useExcelExport from "../../../helpers/customHooks/useExcelExport";
import cloud from "../../../assets/icons/cloud.svg";
import pdf from "../../../assets/icons/pdf.svg";
import download from "../../../assets/icons/download.svg";
import { isAdmin, isAgency, isInquiryManager } from "../../../helpers/isPermission";
import { useQuery } from "@tanstack/react-query";
import FilePreview from "../../../components/upload/filePreview";

const VisitsAndMeetings = () => {
  const [getAllVisitSlots, allVisitSlots, allVisitSlotsLoading] =
    useAxiosGetRequest();

  const [activeTab, setActiveTab] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredData, setFilteredData] = useState(
    allVisitSlots?.result?.items || []
  );

  const history = useHistory();
  const { t } = useTranslation();
  const fileDescriptionInputRef = useRef(null);
  const [descriptionFile, setDescriptionFile] = useState("");
  const [descriptionFileId, setDescriptionFileId] = useState("");
  const [countSlots, setCountSlots] = useState();
  const [
    reliabilityAndCompetitivenessFile,
    setReliabilityAndCompetitivenessFile,
  ] = useState("");
  const [loading, setLoading] = useState(false);
  const agencyId =
    localStorage.getItem("agencyId") != "null"
      ? localStorage.getItem("agencyId")
      : null;
  const [descriptionAttachmentUrl, setDescriptionAttachmentUrl] =
    useState(null);
  const [
    reliabilityAndCompetitivenessAttachmentUrl,
    setReliabilityAndCompetitivenessAttachmentUrl,
  ] = useState(null);

  // list of stats card
  const visitsList = [
    {
      value: null,
      title: "عدد الزيارات",
      number: `${"80"}%`,
      icon: expertUser,
      color: "rgba(103, 212, 255, 0.15)",
    },

    {
      value: 1,
      title: " عدد المواعيد المحجوزة",
      number: `${"85"}%`,
      icon: standards,
      color: "rgba(37, 248, 30, 0.15)",
    },
    {
      value: 2,
      title: "عدد المواعيد المتاحة",
      number: `${"67"}%`,
      icon: standard,
      color: "#0046de26",
    },
    {
      value: 3,
      title: " عدد المواعيد المغلقة",
      number: `${"85"}%`,
      icon: standards,
      color: "rgba(248, 37, 30, 0.15)",
    },
  ];

  useEffect(() => {
    getCountVisitSlots();
  }, []);

  const getCountVisitSlots = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let agencyId = "";
    if (
      permissionsCheck("Pages.UserType.POC") ||
      permissionsCheck("Pages.UserType.CIO") ||
      permissionsCheck("Pages.UserType.Delegate")
    ) {
      agencyId = "?agenyId=" + localStorage.getItem("agencyId");
    }
    get(environment.getCountsVisitSlots + agencyId, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        setCountSlots(res.data.result);
      }
    });
  };

  // ----------------------------------------- //

  // ----------------------- Agencies -----------------------
  const [selectedAgency, setSelectedAgency] = useState("");

  const handleAllAgenciesChange = (value) => {
    setSelectedAgency(value || "");
  };

  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination);
  }, []);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item?.nameInArabic?.toString() || "",
        value: item?.id,
      };
    });
  // ----------------------- Agencies -----------------------

  // ----------------------- Teams -----------------------
  const [selectedTeam, setSelectedTeam] = useState("");

  const handleAllTeamsChange = (value) => {
    setSelectedTeam(value || "");
  };

  const [getAllTeams, allTeams] = useAxiosGetRequest();

  useEffect(() => {
    getAllTeams(environment.getVisitTeam);
  }, []);

  const allTeamsOptions =
    allTeams?.result?.items &&
    allTeams?.result?.items?.map((item) => {
      return {
        label: item?.teamName || "",
        value: item?.id,
      };
    });
  // ----------------------- Teams -----------------------

  // ----------------------- Reserved -----------------------
  const [selectedStatusForSearch, setselectedStatusForSearch] = useState("");
  const [loadingImageDescription, setLoadingImageDescription] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const handleSetStatus = (value) => {
    setselectedStatusForSearch(value || "");
  };

  const allReservedOptions = [
    {
      label: "محجوز",
      value: 1,
    },
    {
      label: "متاح",
      value: 2,
    },
    {
      label: "مغلق",
      value: 3,
    },
  ];
  // ----------------------- Reserved -----------------------
  const [openModal, setOpenModal] = useState(false);
  const [openCloseMeetingModal, setCloseMeetingOpenModal] = useState(false);
  const [openMeetingOutputModal, setMeetingOutputOpenModal] = useState(false);
  const [selectedOutputRow, setSelectedOutputRow] = useState("");
  const [openAgendaModal, setOpenAgendaModal] = useState(false);
  const [selectedAgencyRow, setSelectedAgencyRow] = useState("");

  const [teamMembers, setTeamMembers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [closeMeetingLoading, setCloseMeetingLoading] = useState(false);
  const [currentCloseId, setCurrentCloseId] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: closeMeetingAttachmentDetails, isLoadingAttachmentLoading } =
    useQuery({
      queryKey: ["close-meeting-attachment", selectedOutputRow.attachmentId],
      queryFn: () => {
        console.log(
          "selectedOutputRow.attachmentId",
          selectedOutputRow.attachmentId
        );
        return axiosGetRequest("services/app/Attachment/Get", {
          id: selectedOutputRow.attachmentId,
        });
      },
      enabled: !!selectedOutputRow.attachmentId,
    });

  console.log({ closeMeetingAttachmentDetails, selectedOutputRow });

  // handleDeleteVisit
  const handleDeleteVisit = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.deleteVisitSlot, {
      id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success("تم الحذف بنجاح");
      handleGetAllVisitSlots(currentPage);
      getCountVisitSlots();
    }
  };

  const handlecloseMeeting = async (id) => {
    setCloseMeetingLoading(true);
    if (!descriptionFileId) {
      toast.error("يجب اٍضافة ملف");
      setCloseMeetingLoading(false);
      return;
    }
    const res = await axiosPostRequest(environment.closeMeeting, {
      id: id,
      attachmentId: descriptionFileId,
      meetingOutPut: meetingOutPut,
    });
    setCloseMeetingLoading(false);
    if (res?.success) {
      toast.success("تم اٍغلاق الزيارة");
      handleGetAllVisitSlots(currentPage);
      setCloseMeetingOpenModal(false);
      getCountVisitSlots();
    }
  };

  //LATER
  const columns = [
    {
      name: "تاريخ الزيارة",
      selector: (row) => row.visitDate,
      header: "visitDate",
      accessorKey: "visitDate",
      sortable: true,
      cell: (row) => (
        <div className="flex gap-3 items-center whitespace-nowrap">
          <span>
            <FontAwesomeIcon
              icon={faCalendar}
              className="text-gray-400 text-lg "
            />
          </span>
          <span>
            {convertToArabicDayName(row.visitDate)}
            {" / "}
            {formatDate(new Date(row.visitDate))}
          </span>
        </div>
      ),
    },
    {
      name: "موعد بداية الزيارة",
      selector: (row) => row.startTime,
      header: "startTime",
      accessorKey: "startTime",
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex gap-3 items-center">
          <span>
            <FontAwesomeIcon
              icon={faClock}
              className="text-emerald-400 text-lg "
            />
          </span>
          <span>{removeSeconds(row.startTime) || "-"}</span>
        </div>
      ),
    },
    {
      name: "موعد انتهاء الزيارة",
      selector: (row) => row.endTime,
      header: "endTime",
      accessorKey: "endTime",
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex gap-3 items-center">
          <span>
            <FontAwesomeIcon icon={faClock} className="text-red-400 text-lg " />
          </span>
          <span>{removeSeconds(row.endTime) || "-"}</span>
        </div>
      ),
    },
    permissionsCheck("Pages.UserType.CIO") ||
    permissionsCheck("Pages.UserType.POC") ||
    permissionsCheck("Pages.UserType.Delegate")
      ? {
          name: "نوع الزيارة",
          selector: (row) => row?.meetingAndVisitGoal?.nameInArabic,
          header: "meetingAndVisitGoal",
          accessorKey: "meetingAndVisitGoal",
          sortable: true,
          center: true,
          cell: (row) => (
            <div className="text-center">
              {row?.meetingAndVisitGoal?.nameInArabic || "-"}
            </div>
          ),
        }
      : {
          name: "اسم الفريق",
          selector: (row) => row.visitTeam.teamName,
          header: "teamName",
          accessorKey: "teamName",
          sortable: true,
          cell: (row) => (
            <div>
              <div
                className="cursor-pointer flex gap-3 items-center"
                onClick={() => {
                  setTeamMembers(row.visitTeam.visitTeamMembers);
                  setTeamName(row.visitTeam.teamName);
                  setOpenModal(true);
                }}
              >
                <span className="w-5 h-5 mt-[0.60rem] pb-2 text-white bg-green flex justify-center items-center rounded-full">
                  {row.visitTeam.visitTeamMembers.length}
                </span>
                <span>{row.visitTeam.teamName}</span>
              </div>
            </div>
          ),
        },

    {
      name: "تم الحجز بواسطة",
      selector: (row) => row?.reservedAgency?.nameInArabic,
      header: "agency",
      accessorKey: "agency",
      sortable: true,
      center: true,
      cell: (row) => {
        if (agencyId == row?.reservedAgencyId) {
          return (
            <div className="text-center">
              {row?.reservedAgency?.nameInArabic || "-"}
            </div>
          );
        } else {
          return (
            <div className="text-center">
              {permissionsCheck("Pages.UserType.CIO") ||
              permissionsCheck("Pages.UserType.POC") ||
              permissionsCheck("Pages.UserType.Delegate")
                ? "-"
                : row?.reservedAgency?.nameInArabic || "-"}
            </div>
          );
        }
      },
    },
    {
      name: "نوع الموعد",
      selector: (row) => row.meetingAndVisitTypeId,
      header: "meetingAndVisitTypeId",
      accessorKey: "meetingAndVisitTypeId",

      center: true,
      cell: (row) => (
        <div>
          {row.meetingAndVisitTypeId == 1 && (
            <Badge
              className="text-white font-medium bg-red-800  pb-3 px-5 rounded-xl"
              size="sm"
            >
              حضوري
            </Badge>
          )}

          {row.meetingAndVisitTypeId == 2 && (
            <Badge
              className="text-white font-medium bg-violet-800 pb-3 px-5 rounded-xl"
              size="sm"
            >
              افتراضي
            </Badge>
          )}
        </div>
      ),
    },
    {
      name: "الحالة",
      selector: (row) => row.reserved,
      header: "reserved",
      accessorKey: "reserved",

      center: true,

      cell: (row) => (
        <div>
          {row.visitSlotStatus == 1 ? (
            <Badge
              className="text-white font-medium bg-emerald-600  pb-3 px-5 rounded-xl"
              size="sm"
            >
              محجوز
            </Badge>
          ) : row.visitSlotStatus == 2 ? (
            <Badge
              className="text-white font-medium bg-cyan-600 pb-3 px-5 rounded-xl"
              size="sm"
            >
              متاح
            </Badge>
          ) : row.visitSlotStatus == 3 ? (
            <Badge
              className="text-white font-medium bg-red-600 pb-3 px-5 rounded-xl"
              size="sm"
            >
              مغلق
            </Badge>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "الاجراءات",
      selector: (row) => row.actions,
      header: "actions",
      accessorKey: "actions",
      center: true,
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          <div className="flex gap-2">
            <>
              {row.visitSlotStatus != 3 && (
                <>
                  {permissionsCheck("Pages.VisitSlots.Edit") && (
                    <NewTooltip content={"تعديل"}>
                      <FontAwesomeIcon
                        icon={faPen}
                        className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
                        onClick={() =>
                          history.push({
                            pathname: "/visit/editVisit",
                            state: row,
                          })
                        }
                      />
                    </NewTooltip>
                  )}

                  {permissionsCheck("Pages.VisitSlots.Delete") && (
                    <DeleteIcon
                      loading={deleteLoading}
                      id={row.id}
                      deleteFunction={() => handleDeleteVisit(row.id)}
                      deletedName={"هذه الزيارة"}
                    />
                  )}
                </>
              )}

              {permissionsCheck("Pages.VisitSlots.Close") &&
                row.visitSlotStatus == 1 && (
                  <NewTooltip content={"اغلاق الزيارة"}>
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-slate-500 bg-slate-200 mr-1 rounded-full p-2 cursor-pointer"
                      onClick={() => {
                        setCloseMeetingOpenModal(true);
                        setCurrentCloseId(row.id);
                      }}
                    />
                  </NewTooltip>
                )}
            </>

            {/* (permissionsCheck("Pages.UserType.POC") ||
                permissionsCheck("Pages.UserType.CIO") ||
                permissionsCheck("Pages.UserType.Delegate")) */}
            {row.visitSlotStatus == 3 && (
              <NewTooltip content={"مخرجات الاجتماع"}>
                <FontAwesomeIcon
                  icon={faEye}
                  className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
                  onClick={() => {
                    setMeetingOutputOpenModal(true);

                    setSelectedOutputRow(row);
                  }}
                />
              </NewTooltip>
            )}

            <NewTooltip content={"أجندة الاجتماع"}>
              <FontAwesomeIcon
                icon={faBook}
                className="text-orange-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={() => {
                  setOpenAgendaModal(true);
                  setSelectedAgencyRow(row);
                }}
              />
            </NewTooltip>
          </div>
        );
      },
    },
  ].filter(Boolean);

  useEffect(() => {
    getAllVisitSlots(environment.getVisitSlot, {
      SkipCount: 0,
      MaxResultCount: 6,
      VisitTeamId: selectedTeam.value,
      AgencyId: agencyId ? agencyId : selectedAgency.value,
      VisitSlotStatus: selectedStatus,
    });
  }, [selectedStatus]);

  useEffect(() => {
    setFilteredData(allVisitSlots?.result?.items);
  }, [allVisitSlots]);

  const handleGetAllVisitSlots = async (page) => {
    await getAllVisitSlots(environment.getVisitSlot, {
      SkipCount: page * 6,
      MaxResultCount: 6,
      VisitTeamId: selectedTeam.value,
      AgencyId: agencyId ? agencyId : selectedAgency.value,
      VisitSlotStatus:
        selectedStatusForSearch != ""
          ? selectedStatusForSearch.value
          : selectedStatus,
    });
  };

  const search = () => {
    setCurrentPage(0);
    handleGetAllVisitSlots(currentPage);
    setSelectedAgency("");
    setSelectedTeam("");
  };

  const clearSearch = () => {
    setSelectedAgency("");
    setSelectedTeam("");
    setselectedStatusForSearch("");
    setCurrentPage(0);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    handleGetAllVisitSlots(newOffset);
  };
  const totalCounts = Math.ceil(allVisitSlots?.result?.totalCount / 6) || 1;

  // ----------------------------------------- //
  const [standardsVisitAgency, setStandardsVisitAgency] = useState([]);

  const getAllStandardsVisitAgency = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllVisitsAnswerAgency + "?AgencyId=" + agencyId,
      config,
      (res) => {
        if (res?.status == 403) {
          toast.error(t("general.authError"));
        } else if (res?.status == 500) {
          toast.error(t("general.serverError"));
        } else if (res?.status == 200) {
          setStandardsVisitAgency(res?.data?.result?.items);
        }
      }
    );
  };
  useEffect(() => {
    getAllStandardsVisitAgency();
  }, []);

  const [getStandardsVisitForExpert, allStandardsVisitForExpert] =
    useAxiosGetRequest();

  useEffect(() => {
    getStandardsVisitForExpert(environment.getAllStandardVisitSlot);
  }, []);

  // ----------------------------------------- //

  const tabs = [
    {
      icon: programsIcon,
      title: "حجر موعد",
      table: "visits",
      content: (
        <Visit
          standardsVisitAgency={standardsVisitAgency}
          setActiveTab={setActiveTab}
          getAllStandardsVisitAgency={getAllStandardsVisitAgency}
        />
      ),
    },
    {
      icon: programsIcon,
      title: "مواعيدي",
      table: "myVisits",
      content: (
        <>
          <div className="m-5 flex justify-end">
            <DrawerFilter
              handleSearch={() => search()}
              handleClear={clearSearch}
            >
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedTeam}
                options={allTeamsOptions}
                isMultiple={false}
                name="selectedTeam"
                handleChange={handleAllTeamsChange}
                label={"الزيارات الخاصة بفريق:"}
              />

              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedStatusForSearch}
                options={allReservedOptions}
                isMultiple={false}
                name="isReserved"
                handleChange={handleSetStatus}
                label={"الحالة (متاح / محجوز / مغلق)"}
              />
            </DrawerFilter>
          </div>
          <div className="w-full bg-[#F9F9FF] p-5 ">
            <NewTable
              withExcel
              onExportExcel={() =>
                downloadExcel("الزيارات", allVisitSlots?.result?.items)
              }
              loading={allVisitSlotsLoading}
              columns={columns}
              data={allVisitSlots?.result?.items || []}
              pagination={true}
              totalPages={totalCounts}
              onPageChange={handlePageClick}
              currentPage={currentPage}
            />
          </div>
        </>
      ),
    },

    // {
    //   icon: programsIcon,
    //   title: "نتائج تدقيق الزيارة",
    //   table: "myResults",
    //   content: (
    //     <>
    //       {permissionsCheck("Pages.UserType.POC") ||
    //       permissionsCheck("Pages.UserType.CIO") ||
    //       permissionsCheck("Pages.UserType.Delegate") ? (
    //         <NewTable
    //           data={standardsVisitAgency}
    //           columns={[
    //             {
    //               name: "كود المعيار",
    //               accessorKey: "standardCode",
    //               selector: (row) => row.standardCode,
    //               sortable: true,
    //               cell: (info) => (
    //                 <div className="text-sm text-[#108AE0]">
    //                   {info.standardCode}
    //                 </div>
    //               ),
    //             },
    //             {
    //               name: "أسم المعيار",
    //               accessorKey: "standardTitle",
    //               selector: (row) => row.standardTitle,
    //               sortable: true,
    //               cell: (info) => (
    //                 <div className="text-sm text-[#292069]">
    //                   {info.standardTitle}
    //                 </div>
    //               ),
    //             },
    //             {
    //               name: "مستوي الالتزام",
    //               accessorKey: "commitmentLevelTitle",
    //               selector: (row) => row.commitmentLevelTitle,
    //               cell: (info) => (
    //                 <div className="text-sm text-[#1CC081]">
    //                   {info.commitmentLevelTitle}
    //                 </div>
    //               ),
    //               sortable: true,
    //             },
    //             {
    //               name: "الملاحظة",
    //               accessorKey: "notes",
    //               selector: (row) => row.notes,
    //               cell: (info) => (
    //                 <div
    //                   className="text-sm text-[#1CC081]"
    //                   dangerouslySetInnerHTML={{ __html: info.notes }}
    //                 ></div>
    //               ),
    //               sortable: true,
    //             },
    //           ]}
    //           pagination={false}
    //         />
    //       ) : null}

    //       <div>
    //         <NewSwitch
    //           label={"أوافق على ما تم الأتفاق عليه خلال الاٍجتماع"}
    //           value={isAgree}
    //           setValue={setIsAgree}
    //         />
    //       </div>
    //     </>
    //   ),
    // },
  ];

  // ---------------------- validation -------------------
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const [meetingOutPut, setMeetingOutPut] = useState("");
  const initialValues = {
    meetingOutPut: "",
  };

  const validate = () => {
    const errors = {};

    if (!meetingOutPut.trim()) errors.meetingOutPut = "هذا الحقل مطلوب";

    return errors;
  };

  const downloadExcel = useExcelExport();

  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(allVisitSlots?.result?.items);
    } else {
      const filtered = allVisitSlots?.result?.items?.filter((ele) => {
        const {
          visitDate,
          startTime,
          endTime,
          meetingAndVisitGoal,
          reservedAgency,
          visitTeam,
          meetingAndVisitTypeId,
          visitSlotStatus,
        } = ele;
        return (
          visitDate.includes(value) ||
          startTime.includes(value) ||
          endTime.includes(value) ||
          (meetingAndVisitGoal &&
            meetingAndVisitGoal.nameInArabic.includes(value)) ||
          (reservedAgency && reservedAgency.nameInArabic.includes(value)) ||
          (visitTeam && visitTeam.teamName.includes(value)) ||
          ("حضوري".includes(value) && meetingAndVisitTypeId === 1) ||
          ("افتراضي".includes(value) && meetingAndVisitTypeId === 2) ||
          ("محجوز".includes(value) && visitSlotStatus === 1) ||
          ("متاح".includes(value) && visitSlotStatus === 2) ||
          ("مغلق".includes(value) && visitSlotStatus === 3)
        );
      });
      setFilteredData(filtered);
    }
  };

  // -----------------------------------------------------
  const [excelLoading, setExcelLoading] = useState(false);

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getVisitSlot, {
      SkipCount: 0,
      MaxResultCount: 10000,
      VisitTeamId: selectedTeam.value,
      AgencyId: agencyId ? agencyId : selectedAgency.value,
      VisitSlotStatus: selectedStatus,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        "تاريخ الزيارة": `${convertToArabicDayName(
          ele.visitDate
        )} / ${formatDate(new Date(ele.visitDate))}`,
        "موعد بداية الزيارة": removeSeconds(ele.startTime),
        "موعد انتهاء الزيارة": removeSeconds(ele.endTime),
        "نوع الزيارة": ele?.meetingAndVisitGoal?.nameInArabic,
        "حالة الزيارة":
          ele.visitSlotStatus == 1
            ? "محجوز"
            : ele.visitSlotStatus == 2
            ? "متاح"
            : "مغلق",
        "مكان الزيارة": ele?.meetingAndVisitType?.nameInArabic,
        "تم الحجز بواسطة":
          agencyId == ele?.reservedAgencyId ||
          !(
            permissionsCheck("Pages.UserType.CIO") ||
            permissionsCheck("Pages.UserType.POC") ||
            permissionsCheck("Pages.UserType.Delegate")
          )
            ? ele?.reservedAgency?.nameInArabic
            : "-",
      };
    });

    downloadExcel("الزيارات", dataForExcel);
  };

  const handleFileSelect = (e) => {
    fileDescriptionInputRef.current.click();
  };

  const generateUpload = (file, e, values) => {
    setLoadingImageDescription(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();

    formData.append("Name", file.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", file);

    post(environment.attachmentFile, formData, config, (res) => {
      setDescriptionFileId(
        res.data?.result?.id,
        setLoadingImageDescription(false)
      );
    });
  };

  const onChangeFile = (event, e) => {
    console.log("files", event.target.files[0]);
    if (event.target.files.length > 0) {
      setDescriptionFile(
        event.target.files[0],
        setDescriptionAttachmentUrl(
          URL.createObjectURL(event.target.files[0]),
          generateUpload(event.target.files[0], 1)
        )
      );

      // setReliabilityAndCompetitivenessFile(
      //   event.target.files[0],
      //   setReliabilityAndCompetitivenessAttachmentUrl(
      //     URL.createObjectURL(event.target.files[0]),
      //     generateUpload(event.target.files[0], 5)
      //   )
      // );
    }
  };

  const handleDownload = () => {
    if (descriptionAttachmentUrl) {
      // Simulating file download by creating an anchor element
      const url = descriptionAttachmentUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", descriptionFile.name); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("No file chosen");
    }
  };

  const handleDownloadCloseMeetingAttachment = () => {
    if (closeMeetingAttachmentDetails.result.uri) {
      // Simulating file download by creating an anchor element
      const url = closeMeetingAttachmentDetails.result.uri;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", closeMeetingAttachmentDetails.result.name); // Set the desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("No file chosen");
    }
  };

  function handleUpdateVisitSlotAttachment() {
    editVisit();
  }

  const editVisit = (values) => {
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: selectedAgencyRow.id,
      visitDate: selectedAgencyRow.visitDate,
      startTime: selectedAgencyRow.startTime,
      endTime: selectedAgencyRow.endTime,
      visitTeamId: selectedAgencyRow.visitTeamId,
      visitRequirements: selectedAgencyRow.visitRequirements,
      agencySectorId: selectedAgencyRow.agencySectorId,
      agencyClassificationId: selectedAgencyRow.agencyClassificationId,
      meetingAndVisitTypeId: selectedAgencyRow.meetingAndVisitTypeId,
      locationOrLink: selectedAgencyRow.locationOrLink,
      meetingAndVisitGoalId: selectedAgencyRow.targetOfVisit,
      agencyVisitSlots: selectedAgencyRow.agencyVisitSlots,
      standardVisitSlots: selectedAgencyRow.standardVisitSlots,
      attachmentIds: [descriptionFileId],
    };

    put("services/app/VisitSlots/Update", data, config, (res) => {
      setAddLoading(false);
      if (res.status == 200) {
        toast.info("تم تحديث موعد بنجاح", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(res?.response?.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={[
        {
          icon: programsIcon,
          title: "الاجتماعات و الزيارات",
        },
      ]}
      btnLabel={
        permissionsCheck("Pages.VisitSlots.Create") ? "اٍتاحة موعد" : false
      }
      hideBtn={permissionsCheck("Pages.VisitSlots.Create") ? false : true}
      handleBtnClick={() => history.push("/visit/AddVisit")}
    >
      <div className="m-5">
        {permissionsCheck("Pages.VisitSlots.GetAll") && (
          <div
            className="my-5 py-5"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
              gap: "20px",
            }}
          >
            {visitsList.map((item, index) => (
              <SCard
                tickets={true}
                onClick={() => setSelectedStatus(item.value)}
                key={index}
                icon={item.icon}
                title={
                  loading ? (
                    <div className="flex flex-col items-start">
                      <Skeleton
                        width={"50px"}
                        height={"10px"}
                        color={item.color}
                      />
                      <Skeleton
                        width={"100px"}
                        height={"10px"}
                        color={item.color}
                      />
                    </div>
                  ) : (
                    item.title
                  )
                }
                color={item.color}
                number={
                  item.value == null
                    ? countSlots?.allVisitsCount
                    : item.value == 1
                    ? countSlots?.reservedVisitCount
                    : item.value == 2
                    ? countSlots?.nonReservedVisitCount
                    : item.value == 3
                    ? countSlots?.closedVisitCount
                    : ""
                  // : countSlots?.
                }
              />
            ))}
          </div>
        )}
        {permissionsCheck("Pages.VisitSlots.GetAll") && (
          <div className="m-5 flex justify-end">
            <DrawerFilter
              handleSearch={() => search()}
              handleClear={clearSearch}
            >
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedAgency}
                options={allAgenciesOptions}
                isMultiple={false}
                name="selectedAgency"
                handleChange={handleAllAgenciesChange}
                label={"الزيارات الخاصة بجهة:"}
              />

              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedTeam}
                options={allTeamsOptions}
                isMultiple={false}
                name="selectedTeam"
                handleChange={handleAllTeamsChange}
                label={"الزيارات الخاصة بفريق:"}
              />

              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedStatusForSearch}
                options={allReservedOptions}
                isMultiple={false}
                name="isReserved"
                handleChange={setselectedStatusForSearch}
                label={"الحالة (متاح / محجوز / مغلق)"}
              />
            </DrawerFilter>
          </div>
        )}
        {permissionsCheck("Pages.VisitSlots.GetAll") && (
          <div className="w-full p-5 ">
            <NewTable
              withExcel
              withSearch
              excelLoading={excelLoading}
              onExportExcel={handleExcelExport}
              onSearch={handleFilterData}
              loading={allVisitSlotsLoading}
              columns={columns}
              data={filteredData || allVisitSlots?.result?.items}
              pagination={true}
              totalPages={totalCounts}
              onPageChange={handlePageClick}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>

      {permissionsCheck("Pages.UserType.Expert") ||
      permissionsCheck("Pages.UserType.ExpertManager") ? (
        <div className="bg-white m-5">
          <p className="text-[16px] text-[#292069] mb-5">
            نموذج تدقيق الزيارات
          </p>
          <NewTable
            // data={allStandardsVisitForExpert?.result?.items || []}
            data={
              allStandardsVisitForExpert?.result?.items?.filter(
                (ele) => ele.standardCode
              ) || []
            }
            columns={[
              {
                name: "كود المعيار",
                accessorKey: "standardCode",
                selector: (row) => row.standardCode,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#108AE0]">
                    {info.standardCode}
                  </div>
                ),
              },
              {
                name: "أسم المعيار",
                accessorKey: "standardTitle",
                selector: (row) => row.standardTitle,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">
                    {info.standardTitle}
                  </div>
                ),
              },
              {
                name: "تاريخ الزيارة",
                accessorKey: "visitDate",
                selector: (row) => row.visitDate,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">
                    {formatDate(new Date(info.visitDate))}
                  </div>
                ),
              },
              {
                name: "من",
                accessorKey: "startTime",
                selector: (row) => row.startTime,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">{info.startTime}</div>
                ),
              },
              {
                name: "الي",
                accessorKey: "endTime",
                selector: (row) => row.endTime,
                sortable: true,
                cell: (info) => (
                  <div className="text-sm text-[#292069]">{info.endTime}</div>
                ),
              },
              {
                name: "إجراءات",
                selector: (row) => row.status,
                sortable: true,
                cell: (info) => (
                  <NewTooltip content={"تدقيق"}>
                    <div className="flex flex-row w-[100%]">
                      <div
                        onClick={() =>
                          history.push(
                            `/visit/${info.visitId}/standard/${info.standardId}/rate`
                          )
                        }
                      >
                        <img
                          src={viewNew}
                          className="w-[40px] cursor-pointer"
                          alt="viewNew"
                        />
                      </div>
                    </div>
                  </NewTooltip>
                ),
              },
            ]}
            pagination={false}
          />
        </div>
      ) : null}

      {(permissionsCheck("Pages.UserType.POC") ||
        permissionsCheck("Pages.UserType.CIO") ||
        permissionsCheck("Pages.UserType.Delegate")) && (
        <div className="mt-5">
          <GTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      )}

      {/* Modals */}
      <AppModal
        isFooter={false}
        headerTitle={`أعضاء ${teamName}`}
        open={openModal}
        setOpen={setOpenModal}
      >
        <div className="flex text-xl h-full flex-col gap-3">
          {teamMembers.map((item, i) => (
            <p key={item.id}>
              {i + 1} {" - "} {item.teamMember.userName}
            </p>
          ))}
        </div>
      </AppModal>

      <AppModal
        headerTitle={"اٍغلاق الزيارة"}
        open={openCloseMeetingModal}
        setOpen={(value) => {
          // reset attached file
          setDescriptionFileId("");
          setDescriptionFile("");
          setDescriptionAttachmentUrl(null);

          setCloseMeetingOpenModal(value);
        }}
        loading={closeMeetingLoading}
        handleSubmit={handleFormik}
      >
        <NewForm
          validate={validate}
          initialValues={initialValues}
          onSubmit={() => handlecloseMeeting(currentCloseId)}
          innerRef={formRef}
        >
          <div className="flex flex-col gap-3">
            <div className="space-y-1">
              <AppTextarea
                label={"مخرجات الزيارة"}
                name={"meetingOutPut"}
                type={"text"}
                value={meetingOutPut}
                onChange={(e) => setMeetingOutPut(e.target.value)}
              />

              <ErrorMessage
                className="text-red-700"
                name="meetingOutPut"
                component="div"
              />
            </div>
            <section className="w-[100%] flex flex-col justify-between mt-4">
              {(isAdmin || isInquiryManager) && (
                <div className="bg-[#F9F9FF] flex flex-row items-center justify-between rounded-3xl px-5 py-9">
                  <img src={cloud} alt="cloud" />
                  <p className="text-[14px] text-[#E42829] w-[40%] text-center">
                    يمكنك ارفاق بصيغة pdf ولا يزيد حجم الملف عن 100 ميجا
                  </p>

                  <input
                    style={{ display: "none" }}
                    isImage={true}
                    ref={fileDescriptionInputRef}
                    type={"file"}
                    id={"fileUpload"}
                    label={t("modules.main.users.users.form.image")}
                    onChange={(event) => onChangeFile(event, 1)}
                    image={descriptionFile.name}
                  />

                  <button
                    type="button"
                    onClick={() => handleFileSelect(1)}
                    className="bg-[#1CC081] w-[35%] rounded-3xl p-3 text-white text-[16px]"
                  >
                    إرفاق ملف
                  </button>
                </div>
              )}
              <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
                <div className="flex flex-row items-center">
                  <img src={pdf} alt="pdf" />
                  {loadingImageDescription ? (
                    <Spinner aria-label="Info spinner example" color="info" />
                  ) : (
                    <p className="text-sm text-[#161318] mr-3">
                      {descriptionFile.name}
                    </p>
                  )}
                </div>
                <div className="flex flex-row items-center">
                  <p className="text-sm text-[#7D8BAB] ml-3">
                    {descriptionFile.size}KB
                  </p>
                  <img src={download} alt="download" onClick={handleDownload} />
                </div>
              </div>
            </section>
          </div>
        </NewForm>
      </AppModal>

      <AppModal
        isFooter={false}
        headerTitle={"مخرجات الاٍجتماع"}
        open={openMeetingOutputModal}
        setOpen={setMeetingOutputOpenModal}
      >
        <div className="text-xl h-full">{selectedOutputRow.meetingOutPut}</div>

        {closeMeetingAttachmentDetails &&
          closeMeetingAttachmentDetails.result && (
            <div className="bg-[#F9F9FF] flex flex-row justify-between items-center mt-3 px-5 py-3 rounded-3xl">
              <div className="flex flex-row items-center">
                <img src={pdf} alt="pdf" />
                <p className="text-sm text-[#161318] mr-3">
                  {closeMeetingAttachmentDetails.result.name}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <FilePreview
                  id={closeMeetingAttachmentDetails.result.id}
                  fileName={closeMeetingAttachmentDetails.result.name}
                />
              </div>
            </div>
          )}
      </AppModal>

      <AppModal
        isFooter={!isAgency()}
        headerTitle={"أجندة الاٍجتماع"}
        open={openAgendaModal}
        setOpen={setOpenAgendaModal}
        handleSubmit={handleUpdateVisitSlotAttachment}
        type={"submit"}
        loading={addLoading}
        submitLabel="تحديث"
      >
        <div
          className="text-xl h-full"
          dangerouslySetInnerHTML={{
            __html: selectedAgencyRow?.visitRequirements ?? "",
          }}
        ></div>
      </AppModal>
    </HeaderWithBreadcrumbLayout>
  );
};

export default VisitsAndMeetings;
