import { useContext } from "react";
import { AddStandardContext } from "../addStandardContext";
import StandardDimensionItem from "./standardDimensionItem";

const StandardRequirementList = ({ disabled, isSpecial }) => {
  const { addFinalValue, selectedRequirementAgenciesForEdit } =
    useContext(AddStandardContext);

  console.log("test", addFinalValue.standardRequirements)
  let standardRequirementsFiltered = addFinalValue.standardRequirements ?? [];
  // if not special
  if(!isSpecial){
    standardRequirementsFiltered = (addFinalValue.standardRequirements ?? []).filter(item => item.type !== "special")
  }

  console.log({ standardRequirementsFiltered })
  const renderedRequirements =
    standardRequirementsFiltered?.length > 0 &&
    standardRequirementsFiltered
    .map((item, index) => (
      <StandardDimensionItem
        key={item.title}
        {...item}
        index={index}
        disabled={disabled}
        isSpecial={isSpecial}
        selectedRequirementAgenciesForEdit={selectedRequirementAgenciesForEdit}
        number={addFinalValue.standardRequirements?.length - (index + 1) + 1}
      />
    ));

  return <div> {renderedRequirements}</div>;
};

export default StandardRequirementList;
