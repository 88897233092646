import {useHistory, useParams} from "react-router";
import {Title} from "../../../../../components";
import SecondaryTapsLayout from "../../../../../layouts/secondaryTapsLayout";
import {serveySettingTabs} from "../data";
import {t} from "i18next";
import {programsIcon} from "../../../../../assets/icons/Index";
import AppRadio from "../../../../../components/form/appRadio";
import AppButton from "../../../../../components/buttons/appButton";
import {useState} from "react";
import {axiosPutRequest} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import {toast} from "react-toastify";
import AssignAgencies from "../../standerds/agency/AssignAgencies";

const QuestionsLevel = () => {
  const {surveyId} = useParams();
  // there is a bug here default value should come from the api
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (value) => {
    setLoading(true);
    const searchParams = new URLSearchParams();
    searchParams.append("surveyId", surveyId);
    searchParams.append("structureSubStandardRequirementDirectly", value === "1");

    const res = await axiosPutRequest(
      environment.updateSurveyStructureSubStandardRequirement + `?${searchParams.toString()}`,
    );
    setLoading(false);

    if (res.success) {
      toast.success("تم تعديل مستوى إنشاء الأسئلة بنجاح");
    }
  };

  function handleLevelChange(e) {
    setValue(e.target.value);
    handleSubmit(e.target.value);
  }

  return (
    <SecondaryTapsLayout
      linksArr={serveySettingTabs(`/surveys/${surveyId}`)}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={"مستوى إنشاء الأسئلة"}
            seconed
            // subTitleSeconed={"مستوى إنشاء الأسئلة"}
            title={t("المؤشرات")}
            toPage={() => history.push("/indicators")}
            withoutButton={true}
          />
        </div>
      }
    >
      <h2 className="mb-8 text-lg text-blue-text ">مستوى إنشاء الأسئلة</h2>
      <AppRadio
        label="علي مستوي المعيار "
        name={"level"}
        id={1}
        value="1"
        checked={value === "1"}
        onChange={handleLevelChange}
      />
      <AppRadio
        label="علي مستوي البعد"
        name={"level"}
        id={2}
        value="2"
        checked={value === "2"}
        onChange={handleLevelChange}
      />
      {/* 
      <AppButton onClick={handleSubmit} loading={loading} className="mt-4">
        حفظ
      </AppButton> */}
    </SecondaryTapsLayout>
  );
};

export default QuestionsLevel;
