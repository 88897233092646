import React, { useContext, useEffect, useState } from "react";
import { AppInput, DropdownMultiSelect } from "../../../../../../components";
import AppButton from "../../../../../../components/buttons/appButton";
import StandardSubRequirements from "./standardSubRequirements";
import AppAccordion from "../../../../../../components/appAccordion";
import { AddStandardContext } from "../addStandardContext";
import { produce } from "immer";
import NewDropdownMultiSelect from "../../../../../../components/NewDropDownMultiSelect";
import { addStandardRequirementValidations } from "../addStandardValidation";
import {
  handleFormValidation,
  handleInputValidation,
} from "../../../../../../helpers/utils";
import StandardQuestions from "./standardQuestions";

const StandardDimensionItem = ({
  index,
  title,
  recommendation,
  subStandardRequirements,
  number,
  disabled,
  isSpecial,
}) => {
  const {
    requirmentList,
    setRequirmentList,
    subRequirementCount,
    setSubRequirementCount,
    classifications,
    addFinalValue,
    setAddFinalValue,
    selectedRequirementAgenciesForEdit,
    selectedAgencies,
  } = useContext(AddStandardContext);

  const [selectedClassifications, setSelectedClassifications] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [errors, setErrors] = useState({
    title: "",
    recommendation: "",
  });

  const [selectedRequirementAgencies, setSelectedRequirementAgencies] =
    useState(selectedRequirementAgenciesForEdit);
  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardRequirementValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: "" });
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const [requirement, setRequirement] = useState({
    title,
    recommendation,
    subStandardRequirements,
  });

  const body = {
    ...requirement,
    agencyStandardRequirements: selectedRequirementAgencies.map((ele) => ({
      agencyId: ele.value,
    })),
    subStandardRequirements,
  };

  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
          label: item.title,
          value: item.id,
          id: item.id,
        }))
      : [];

  const handleChange = (e) => {
    const { name, value } = e.target;

    setRequirement({ ...requirement, [e.target.name]: e.target.value });
    validateInput(name, value);
  };

  const handleSubmit = async () => {
    const { validForm, formErrors } = await handleFormValidation(
      addStandardRequirementValidations,
      {
        title: requirement.title,
        recommendation: requirement.recommendation,
      }
    );

    if (formErrors) {
      console.log(errors);
      setErrors({ ...errors, ...formErrors });
      return;
    }

    const editedRequirement = addFinalValue?.standardRequirements?.map(
      (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            ...body,
          };
        }
        return item;
      }
    );

    setIsOpen(false);

    setRequirmentList(editedRequirement);

    setAddFinalValue(
      produce((draft) => {
        draft.standardRequirements[index] = body;
      })
    );
  };

  const handleDeleteClick = () => {
    setSubRequirementCount(
      subRequirementCount -
        requirmentList?.[index]?.subStandardRequirements?.length
    );
    const newReq = requirmentList.filter((item, itemIndx) => itemIndx != index);
    setRequirmentList(newReq);

    setAddFinalValue(
      produce((draft) => {
        draft.standardRequirements =
          draft.standardRequirements.filter(
            (item, itemIndx) => itemIndx != index
          ) || [];
      })
    );
  };

  console.log({ isSpecial });
  return (
    <div className="mt-3">
      <AppAccordion
        number={number}
        title={!isSpecial ? title : ""}
        handleDeleteClick={handleDeleteClick}
        setIsOpen={() => setIsOpen(true)}
        isOpen={isOpen}
      >
        <div className={"p-5 pb-7 rounded-lg "}>
          { !isSpecial && <h2 className="font-semibold mb-4">تعديل البعد</h2>}
          <div className={"row gap-y-3" + (isSpecial ? " hidden " : "")}>
            <div className="col-md-5 ">
              <AppInput
                label="عنوان البعد"
                placeholder="اكتب هنا"
                name="title"
                value={requirement.title}
                onChange={handleChange}
                errorMessage={errors?.title}
                disabled={disabled}
              />
            </div>
            <div className="col-xl-5">
              <AppInput
                label="وصف البعد"
                placeholder="اكتب هنا"
                name="recommendation"
                value={requirement.recommendation}
                onChange={handleChange}
                errorMessage={errors?.recommendation}
                disabled={disabled}
              />
            </div>
          </div>
          <div className={"row gap-y-4 mt-8" + (isSpecial ? " hidden " : "")}>
            <div className="col-xl-2 align-self-end">
              <div className="text-end">
                <AppButton onClick={handleSubmit}>تعديل البعد</AppButton>
              </div>
            </div>
          </div>

          <StandardQuestions
            subReq={
              addFinalValue.standardRequirements[index]
                .subStandardRequirements || []
            }
            isSpecial={isSpecial}
            disabled={disabled}
            requirementIndx={index}
          />
        </div>
      </AppAccordion>
    </div>
  );
};

export default StandardDimensionItem;
