import { useContext } from "react";
import { useParams } from "react-router-dom";
import { StageContext } from "../../../../../../../../contexts/stageContext";
import { useAnswerContext } from "../../../answerContext";
import MainRequirement from "../../../standardAnswerRequirements/ui/mainRequirement";
import SubRequirement from "../../../standardAnswerRequirements/ui/subRequirement";

const AgencyRequirementAnswerStage3 = ({
  standardRequirements,
  isAgency = false,
}) => {
  const { standardId, entityId } = useParams();
  const { newAgencyAnswer, setNewAgencyAnswer, getSelfEvaluation } =
    useAnswerContext();
  const { stageRoles, currentStage } = useContext(StageContext);

  const isThirdStage = currentStage?.displayOrder === 3;

  console.log({ isThirdStage });

  const handleRequirementChange = (requirement, property = "decision") => {
    if (!isAgency) return;
    const updatedRequirements = newAgencyAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === "decision"
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                  };
            });

          return property === "decision"
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId, "agency");

    setNewAgencyAnswer({
      ...newAgencyAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = "decision"
  ) => {
    if (!isAgency) return;

    const updatedSubRequirements = newAgencyAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === "decision"
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedSubRequirements, entityId, "agency");

    setNewAgencyAnswer({
      ...newAgencyAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const roOpenRequirementIds = standardRequirements.reduce((acc, item) => {
    const subReqIds = item?.subStandardRequirements?.map((subReq) => subReq?.id) || [];
    return [...acc, item?.id, ...subReqIds];
  }, []);



  const renderedAgencyRequirements =
    newAgencyAnswer?.standardRequirements?.length > 0 &&
    newAgencyAnswer?.standardRequirements
      ?.filter((i) => roOpenRequirementIds.includes(i.id))
      .map((requirement) => {
        const hasSubRequirements =
          requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            onChange={() => handleRequirementChange(requirement)}
            requirementDonotApplyChecked={requirement?.donotApply}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, "donotApply")
            }
            readOnly={
              !isAgency ||
              !stageRoles.includes(localStorage.getItem("userType"))
            }
            disableDonotApply={
              !isAgency ||
              !stageRoles.includes(localStorage.getItem("userType"))
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements
                ?.filter((i) => roOpenRequirementIds.includes(i.id))
                .map((subRequirement) => {
                  return (
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          "donotApply"
                        )
                      }
                      readOnly={
                        !isAgency ||
                        !stageRoles.includes(localStorage.getItem("userType"))
                      }
                      disableDonotApply={
                        !isAgency ||
                        !stageRoles.includes(localStorage.getItem("userType"))
                      }
                    />
                  );
                })}
          </MainRequirement>
        </>
      );
    });

  return renderedAgencyRequirements;
};

export default AgencyRequirementAnswerStage3;
