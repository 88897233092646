function NewSwitch({
  value,
  setValue,
  label,
  onsubmit,
  trueText = "نعم",
  falseText = "لا",
  reverse = false,
  targetValue,
  name,
  onChange,
  widthFalse,
  widthFalseValue,
  widthTrue,
  widthTrueValue
}) {
  return (
    <div
      className={`flex gap-5 items-center ${reverse ? "flex-row-reverse" : ""}`}
    >
      <label
        htmlFor={"label"}
        className="inline-flex items-center rounded-full cursor-pointer dark:text-gray-800"
      >
        <input
          id={"label"}
          type="checkbox"
          className="hidden"
          checked={value}
          value={targetValue}
          name={name}
          onChange={onChange}
        />
        <span
          onClick={() => {
            setValue(true);
            onsubmit?.();
          }}
          className={`whitespace-nowrap ${widthTrue ? widthTrueValue : 'w-16'} text-center select-none  border-[3px]  border-l-0 pb-1.5 rounded-r-xl ${
            value ? "bg-green text-white border-green" : "border-red-600"
          }`}
        >
          {trueText}
        </span>
        <span
          onClick={() => setValue(false)}
          className={`${widthFalse ? widthFalseValue : 'w-16'} text-center select-none border-[3px]  pb-1.5 border-r-0 rounded-l-xl ${
            !value ? "bg-red-600 text-white border-red-600" : "border-green"
          }`}
        >
          {falseText}
        </span>
      </label>

      {label && (
        <label htmlFor={value} className="text-gray-700 text-lg -mt-3">
          {label}
        </label>
      )}
    </div>
  );
}

export default NewSwitch;
