import React, {useContext, useRef, useState} from "react";
import {AppInput} from "../../../../../components";
import AppTextarea from "../../../../../components/form/appTextarea";
import AppModal from "../../../../../components/Models/appModal";
import {axiosPostRequest} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import {IndicatorsContext} from "../../../../../contexts/indicatorsContext";
import {toast} from "react-toastify";
import {ErrorMessage, Formik} from "formik";

const AddIndicatorModal = ({openAddModal, setOpenAddModal}) => {
  const {addnewIndicator} = useContext(IndicatorsContext);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
  });

  const handleInputChange = (e) => {
    setInputs({...inputs, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPostRequest(environment.createIndicator, inputs);
    setLoading(false);
    if (res?.success) {
      addnewIndicator(res?.result);
      toast.success("تم إضافة مؤشر بنجاح");
      setOpenAddModal(false);
      setInputs({
        title: "",
        description: "",
      });
    }
  };

  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: "",
    description: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title.trim()) {
      errors.title = "هذا الحقل مطلوب";
    }

    if (!inputs.description.trim()) {
      errors.description = "هذا الحقل مطلوب";
    }

    return errors;
  };

  return (
    <AppModal
      headerTitle="إضافة مؤشر"
      open={openAddModal}
      setOpen={(value) => {
        setInputs({
          title: "",
          description: "",
        });
        
        setOpenAddModal(value)
      }}
      handleSubmit={handleSubmitAdd}
      type={"submit"}
      loading={loading}
      submitLabel="إضافة"
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({handleChange, values}) => (
          <div>
            <div>
              <AppInput
                type={"text"}
                label={"اسم المؤشر"}
                name="title"
                variant="gray"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="mt-3">
              <AppTextarea
                label={"الوصف"}
                name="description"
                isRequired={true}
                value={inputs.description}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="description"
                component="div"
              />
            </div>
          </div>
        )}
      </Formik>
    </AppModal>
  );
};

export default AddIndicatorModal;
