import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import useText from "../../../../helpers/customHooks/useText";
import environment from "../../../../services/environment";
import { get, post } from "../../../../services/Request";
import List from "./List";

import { toast } from "react-toastify";
import {
  DropdownMultiSelect,
  FModal,
  InputLabel,
  SModal,
} from "../../../../components";
// import { identitiesContext } from "../../../../contexts/identitiesContext";
import { useTranslation } from "react-i18next";
import { permissionsCheck } from "../../../../helpers/utils";
import { programsIcon } from "../../../../assets/icons/Index";
import NewTable from "../../../../components/NewTable";
import HeaderWithBreadcrumbLayout from "../../../../layouts/headerWithBreadcrumbLayout";
import useAxiosGetRequest from "../../../../helpers/customHooks/useAxiosGetRequest";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import { IdentitiesContext } from "../../../../contexts/IdentitiesContext";

const IdentitiesList = ({ permission }) => {
  const { t } = useTranslation();
  const identitiesContext = useContext(IdentitiesContext);
  const [values, handleChange] = useText();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showAddNumber, setShowAddNumber] = useState(false);
  console.log(permission);
  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [statuses, setStatuses] = useState([
    { id: 1, label: "الحالة", labelEn: "Status", value: null },
    { id: 2, label: "تشط", labelEn: "Active", value: true },
    { id: 3, label: "غير نشط", labelEn: "Un active", value: false },
  ]);
  const [totalRows, setTotalRows] = useState(0);

  const [openPermissions, setOpenPermissions] = useState(false);
  const [openEntities, setOpenEntities] = useState(false);
  const [identities, setIdentities] = useState([]);
  const [message, setMessage] = useState({});

  const [role, setRole] = useState([]);
  const [roleStatic, setRoleStatic] = useState(null);
  const [entity, setEntity] = useState(null);

  const [roles, setRoles] = useState([]);
  const [rolesStatic, setRolesStatic] = useState([]);
  const [entities, setEntities] = useState([]);
  const [perPage, setPerPage] = useState({ label: "5", value: 5 });
  const [experts, setExperts] = useState([]);

  const [selectedExperts, setSelectedExperts] = useState([]);

  const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();
  const formIdentityRef = useRef(null);

  const handleSubmitAddUser = () => {
    if (formIdentityRef.current) {
      formIdentityRef.current?.handleSubmit();
    }
  };
  useEffect(() => {
    getEntitiesForFilter(environment.getAllAgencyWithoutPagination);
  }, []);

  const entitiesOptions =
    entitiesForFilter?.result &&
    entitiesForFilter?.result?.map((item) => {
      return { label: item.name, value: item.id };
    });

  const handleChangeExperts = (value) => {
    setSelectedExperts(value);
  };
  const handleChangeRoles = (value) => {
    console.log(value);
    setRole(value);
  };

  const handleChangeRoleStatic = (value) => {
    setRoleStatic(value);
  };

  const handleChangeRole = (value) => {
    identitiesContext.setSelectedRole(value);
  };

  const handleChangeStatus = (value) => {
    identitiesContext.setSelectedStatus(value);
  };

  const handleChangeEntities = (value) => {
    setEntity(value);
  };

  const handleChangeEntitiesFilter = (value) => {
    identitiesContext.setSelectedEntity(value);
  };

  useEffect(() => {
    getAllIdentities(0, 10);
  }, []);
  const getAllIdentities = (page, perPage) => {
    setLoading(true);
    let arr = [];
    if (identitiesContext.selectedRole != null) {
      identitiesContext.selectedRole.forEach((item, index) => {
        arr.push(item.id);
      });
    } else {
      arr = [];
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let status = "";
    let entity = "";
    let roles = [];
    if (identitiesContext.selectedStatus.value != null) {
      status = "&IsActive=" + identitiesContext.selectedStatus.value;
    }
    if (identitiesContext.selectedEntity != null) {
      entity = "&AgencyId=" + identitiesContext.selectedEntity.value;
    }

    if (arr.length != 0) {
      arr.forEach((item, index) => {
        roles = "&Roles=" + item;
      });
    }

    get(
      environment.getAllIdentity +
        "?SkipCount=" +
        page * 4 +
        "&MaxResultCount=" +
        4,
      // status +
      // entity +
      // roles,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          setTotalPages(res.data.result.totalCount / 4);
          setTotalRows(res.data.result.totalCount);
          setIdentities(res.data.result.items, getAllRoles());
        }
      }
    );
  };
  const getAllRoles = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllRoles + "?isStatic=" + false, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((role) => {
          arr.push({ id: role.id, label: role.name, value: role.id });
        });
        setRoles(arr, getAllRolesStatic());
      }
    });
  };

  const getAllRolesStatic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(
      environment.getAllRoles + "?isStatic=" + true + "&MaxResultCount=" + 100,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.status == 200) {
          console.log(res.data.result.items);
          let arr = [];
          let newArr = [];
          res?.data?.result?.items.forEach((role, index) => {
            arr.push({ id: role.id, label: role.name, value: role.id });
          });
          console.log(arr);
          newArr = arr.filter(
            (item, index) => item.id != 40 && item.id != 39
          );
          console.log(newArr);
          setRolesStatic(newArr, getAllAgency());
        }
      }
    );
  };

  function validateSAID(id) {
    let type = id.substr(0, 1);
    let sum = 0;
    const _idLength = 10;
    const _type1 = "1";
    const _type2 = "2";
    id = id.trim();
    if (
      isNaN(parseInt(id)) ||
      id.length !== _idLength ||
      (type !== _type2 && type !== _type1)
    ) {
      return -1;
    }
    for (let num = 0; num < 10; num++) {
      const digit = Number(id[num]);
      if (num % 2 === 0) {
        const doubled = digit * 2;
        const ZFOdd = `00${doubled}`.slice(-2);
        sum += Number(ZFOdd[0]) + Number(ZFOdd[1]);
      } else {
        sum += digit;
      }
    }
    return sum % 10 !== 0 ? -1 : type;
  }

  const passwordValidation = (password) => {
    var re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[ -\/:-@\[-\`{-~]/,
      digit: /(?=.*[0-9])/,
    };
    return (
      re.capital.test(password) &&
      re.length.test(password) &&
      re.specialChar.test(password) &&
      re.digit.test(password)
    );
  };

  const getAllAgency = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.forEach((agency) => {
          arr.push({ label: agency.name, value: agency.id, id: agency.id });
        });
        arr.unshift({ label: "جهة حكومية", value: "", id: "" });
        setEntities(arr, getAllExperts());
      }
    });
  };

  const getAllExperts = () => {
    // setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    get(environment.getAllExpertLookUp, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((user) => {
          arr.push({ id: user.id, label: user.fullName, value: user.name });
        });
        setExperts(arr, setLoading(false));
      }
    });
  };

  const addIdentity = (values, profilePictureId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let roleArr = [];
    role.forEach((role, index) => {
      roleArr.push({ roleId: role.value });
    });
    let data = {
      identityNumber: values.identityNumber,
      staticRoleId: roleStatic.value,
      identityRoles: roleArr,
    };

    post(environment.addIdentity, data, config, (res) => {
      setRole([]);
      setRoleStatic("");
      if (res.status == 200) {
        toast.success(t("تم اضافة الهوية بنجاح"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false, getAllIdentities(currentPage, perPage.value));
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false, getAllIdentities(currentPage, perPage.value));
      } else {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false, getAllIdentities(currentPage, perPage.value));
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllIdentities(newOffset, perPage.value);
  };

  const handlePerRowsChange = (value) => {
    setPerPage(value);
  };

  const handlePageChange = (page) => {
    getAllIdentities(page);
  };

  const clearSearch = () => {
    identitiesContext.setSelectedRole([]);
    identitiesContext.setSelectedEntity(null);
    identitiesContext.setSelectedStatus({
      id: 1,
      label: "الحالة",
      labelEn: "Status",
      value: null,
    });
    setPerPage({ label: "5", value: 5 });
  };

  const usersDetailsBreadcumbs = [
    {
      icon: programsIcon,
      title: "مستخدمو النظام",
      url: "/users",
    },
    {
      title: "الهويات",
      url: "/users",
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={usersDetailsBreadcumbs}
      btnLabel={"اٍضافة هوية"}
      handleBtnClick={() => setOpen(true)}
      hideBtn={permissionsCheck("Pages.Identities.Create") ? false : true}
    >
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <FModal
        content={
          <div>
            <Formik
              innerRef={formIdentityRef}
              initialValues={{
                identityNumber: "",
                identityRoles: [],
                roleStatic: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.identityNumber) {
                  errors.identityNumber = t("رقم الهوية مطلوب");
                }
                // console.log(validateSAID(values.identityNumber))
                // return true
                if (values.identityNumber.length != 10) {
                  errors.identityNumber = t(
                    "يجب ان يكون رقم الهوية مكون من ١٠ ارقام"
                  );
                }
                let valid = /^\d+$/;
                if (!valid.test(values.identityNumber)) {
                  errors.identityNumber = t(
                    "يجب ان يكون رقم الهوية ارقام فقط "
                  );
                }
                // if (!role) {
                //   errors.identityRoles = t(
                //     "modules.main.users.users.messages.listEntites"
                //   );
                // }
                if (roleStatic == null) {
                  errors.roleStatic = t(
                    "modules.main.users.users.messages.permissionStatic"
                  );
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                addIdentity(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <InputLabel
                    value={values.identityNumber}
                    onChange={handleChange}
                    name="identityNumber"
                    type={"text"}
                    label={t("رقم الهوية")}
                    max={10}
                    placeholder={t("ادخل رقم الهوية")}
                    style={{
                      borderColor: errors.identityNumber
                        ? "red"
                        : values.identityNumber != ""
                        ? "#22bb33"
                        : "transparent",
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.identityNumber &&
                      touched.identityNumber &&
                      errors.identityNumber}
                  </div>

                  {/* <NewDropdownMultiSelect
                    isSearchable={true}
                    value={role}
                    handleChange={handleChangeRoles}
                    placeholder={t(
                      "modules.main.users.users.placeholder.permissions"
                    )}
                    label={t("modules.main.users.users.form.permissions")}
                    options={roles}
                    isMultiple={true}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.identityRoles && touched.identityRoles && errors.identityRoles}
                  </div> */}

                  <DropdownMultiSelect
                    isSearchable={true}
                    value={roleStatic}
                    handleChange={handleChangeRoleStatic}
                    placeholder={t(
                      "modules.main.users.users.placeholder.permissionStatic"
                    )}
                    label={t("modules.main.users.users.form.permissionsStatic")}
                    options={rolesStatic}
                    isMultiple={false}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.roleStatic &&
                      touched.roleStatic &&
                      errors.roleStatic}
                  </div>
                  {roleStatic?.value == "POC" ||
                  roleStatic?.value == "CIO" ||
                  roleStatic?.value == "Delegate" ? (
                    <div>
                      <DropdownMultiSelect
                        isSearchable={true}
                        value={entity}
                        handleChange={handleChangeEntities}
                        placeholder={t(
                          "modules.main.users.users.placeholder.entity"
                        )}
                        label={t("modules.main.users.users.form.entity")}
                        options={entities}
                        isMultiple={false}
                      />
                      <div className="text-sm text-red-600 mt-3">
                        {errors.agencyId && touched.agencyId && errors.agencyId}
                      </div>
                    </div>
                  ) : null}

                  {roleStatic?.value == "Reviewer" ? (
                    <div>
                      <NewDropdownMultiSelect
                        label={"الخبراء"}
                        placeholder={"اختر خبير"}
                        options={experts}
                        handleChange={handleChangeExperts}
                        value={selectedExperts}
                        isMultiple={true}
                      />
                      <div className="text-sm text-red-600 mt-3">
                        {errors.users && touched.users && errors.users}
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </Formik>
          </div>
        }
        open={open}
        titleButton={t("modules.main.users.users.titleAddButton")}
        setOpen={() => setOpen(false)}
        action={handleSubmitAddUser}
        type={"submit"}
        headerTitle={t("اضافة هوية جديدة")}
      />
      <div className="my-5">
        {/* <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => getAllIdentities(currentPage, perPage.value)}
            handleClear={clearSearch}
          >
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={identitiesContext.selectedEntity}
              handleChange={handleChangeEntitiesFilter}
              placeholder={t("modules.main.users.users.filter.entity")}
              label={t("modules.main.users.users.filter.entity")}
              options={entitiesOptions}
              isMultiple={false}
            />
            <NewDropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={identitiesContext.selectedRole}
              handleChange={handleChangeRole}
              placeholder={t("modules.main.users.users.filter.permission")}
              label={t("modules.main.users.users.filter.permission")}
              options={roles}
              isMultiple={true}
            />
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={identitiesContext.selectedStatus}
              handleChange={handleChangeStatus}
              placeholder={t("modules.main.users.users.filter.action")}
              label={t("modules.main.users.users.filter.action")}
              options={statuses}
              isMultiple={false}
            />

            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={perPage}
              handleChange={handlePerRowsChange}
              placeholder={t("عدد العناصر في الجدول")}
              label={t("عدد العناصر في الجدول")}
              options={[
                { label: "10", value: 10 },
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
              isMultiple={false}
            />
          </DrawerFilter>
        </div> */}

        {permissionsCheck("Pages.Identities.GetAll") ? (
          <div className="mt-3">
            <NewTable
              loading={loading}
              columns={List}
              data={identities}
              pagination
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              totalRows={totalRows}
            />
          </div>
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {"ليس لديك صلاحية لهذا"}
          </div>
        )}
      </div>
    </HeaderWithBreadcrumbLayout>
  );
};

export default IdentitiesList;
