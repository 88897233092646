import environment from "../../../../../services/environment";
import InterlocutorItemSkeleton from "./interlocutorItemSkeleton";
import {useState} from "react";
import {useParams} from "react-router";
import DrawerFilter from "../../../../../components/DrawerFilter";
import {DropdownMultiSelect, InputLabel} from "../../../../../components";
import {permissionsCheck} from "../../../../../helpers/utils";

const InterlocutorList = ({
                            getInterlocutor,
                            loading,
                            renderedInterlocutor,
                            handleChangeSearchPerspectiveName,
                            perspectivesOptions,
                            perspectiveName,
                            setPerspectiveName,
                          }) => {
  const {surveyId} = useParams();

  const [interlocutorName, setInterlocutorName] = useState("");

  const clearSearch = () => {
    setPerspectiveName(null);
    setInterlocutorName("");
  };

  return (
    <div>
      <div className="my-5 mb-10 flex justify-end">
        <DrawerFilter
          handleSearch={() =>
            getInterlocutor(environment.getInterviewers, {
              PerspectiveId: perspectiveName?.value,
              SurveyId: surveyId,
              Keyword: interlocutorName,
            })
          }
          handleClear={clearSearch}
        >
          <div className="w-full">
            <InputLabel
              label={"العنصر"}
              name={"title"}
              type={"text"}
              value={interlocutorName}
              onChange={(e) => setInterlocutorName(e.target.value)}
              isRequired={false}
            />
          </div>

          <div className="w-full">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={perspectiveName}
              options={perspectivesOptions}
              isMultiple={false}
              name="PerspectiveName"
              handleChange={handleChangeSearchPerspectiveName}
              label={"القدرة"}
              isRequired={false}
            />
          </div>
        </DrawerFilter>
      </div>
      {permissionsCheck("Pages.Interlocutors.GetAll") ?
        <div
          className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ">
          {loading ? (
            <>
              <InterlocutorItemSkeleton/>
              <InterlocutorItemSkeleton/>
              <InterlocutorItemSkeleton/>
              <InterlocutorItemSkeleton/>
              <InterlocutorItemSkeleton/>
              <InterlocutorItemSkeleton/>
            </>
          ) : (
            renderedInterlocutor
          )}
        </div>
        : <div
          className="text-[#bb2124] text-xl text-center flex justify-center items-center">{"ليس لديك صلاحية لهذا"}</div>}
    </div>
  );
};

export default InterlocutorList;
