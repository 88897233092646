import { Table } from "flowbite-react";
import tableTheme from "../../theme/TableTheme";


const DashboardTable = ({
  children,
  data,
  headers,
  tableBody,
  tablePagination,
}) => {
  const rows = Object.keys(data[0]);

  return (
    <section className="shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A] bg-white border border-[#EAECF0] rounded-xl h-full overflow-hidden flex flex-col">
      {children}
      <div className="overflow-x-auto">
        <Table hoverable theme={tableTheme}>
          <Table.Head>
            {headers.map((header, index) => (
              <Table.HeadCell key={index}>{header}</Table.HeadCell>
            ))}
          </Table.Head>
          <Table.Body className="divide-y">
            {tableBody ||
              data.map((result, index) => (
                <Table.Row key={index}>
                  {rows.map((row, index) => {
                    if (row === "id") return null;
                    return (
                      <Table.Cell
                        key={index}
                        className={`${!index ? "" : "text-center"}`}
                      >
                        {result[row]}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
      {tablePagination}
    </section>
  );
};

export default DashboardTable;
