import React, { useRef, useEffect, useState } from 'react'
import cloud from '../../../assets/icons/cloud.svg'
import pdf from '../../../assets/icons/pdf.svg'
import download from '../../../assets/icons/download.svg'
import openBook from '../../../assets/icons/openBook.svg'
import Select from "react-tailwindcss-select";
import Button from '../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../../components/UploadAdapter";
import environment from "../../../services/environment";
import { get, post } from "../../../services/Request";
import {toast} from 'react-toastify'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CModal, DropdownMultiSelect, Ecard, FModal, InputLabel, SModal, Title, Input, NewECard } from "../../../components";
import { Field, Formik } from "formik";
import Skeleton from 'react-skeleton-loader';
import { avatar, plusLight, programsIcon, search } from "../../../assets/icons/Index";
import { useDebugValue } from 'react'
import ReactPaginate from "react-paginate";
import NewTitle from "../../../components/NewTitle"
import models from '../../../assets/icons/models.svg'

import Filter from '../../../components/Filter'
import Card from './Card'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import table from "../../../assets/icons/table.svg";
import NewTable from '../../../components/NewTable.jsx';
import NewHeader from '../../../layouts/header/NewHeader'
import blocks from './../../../assets/icons/blocks.svg'
import { formatDate } from '../../../helpers/utils'
import activeBlocks from './../../../assets/icons/activeBlocks.svg'

const CreativeCards = ({ number }) => {
    const [message, setMessage] = useState({});
    const { t } = useTranslation();
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [openModel, setOpenModel] = useState(false);
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedEvaluator, setSelectedEvaluator] = useState("");
    const [agencyAnswerId, setAgencyAnswerId] = useState("");

    const [innovativeAnswers, setInnovativeAnswers] = useState([]);
    const [totalCounts, setTotalCounts] = useState(0);

    const [selectedEndDate, setSelectedEndDate] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    function handleMenu() {
        setShowMenu(!showMenu)
    }

    const handleChangeEntitiesFilter = (value) => {
        console.log(value);
        setSelectedEntities(value);
    };

    const arr = [
        {
            filter: true,
            withoutLabel: false,
            isSearchable: true,
            isMultiple: true,
            isClearable: true,
            label: "الجهة الحكومية",
            value: 'الحالة',
            placeholder: "وزارة الحج والعمرة",
            options: [
                { value: 'active', label: 'وزارة الحج والعمرة' },
                { value: 'unactive', label: 'وزارة الحج والعمرة' }
            ]
        },
        {
            filter: true,
            withoutLabel: false,
            isSearchable: true,
            isMultiple: true,
            isClearable: true,
            label: 'الحالة',
            value: 'الحالة',
            placeholder: "الكل",
            options: [
                { value: 1, label: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزةالشخصية التقنية' },
                { value: 2, label: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على ال...' },
                { value: 3, label: 'أرشفة الوثائق والبيانات الإلكترونية' }
            ]
        }
    ]
    const cardArr1 = [
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 90,
            reviewed: "completed",
            id: 0
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 15,
            reviewed: "not started",
            id: 1
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 50,
            reviewed: "in progress",
            id: 2
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 70,
            reviewed: "completed",
            id: 3
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 10,
            reviewed: "not started",
            id: 4
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 20,
            reviewed: "not started",
            id: 5
        }
        , {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 90,
            reviewed: "completed",
            id: 6
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 15,
            reviewed: "not started",
            id: 7
        },
        {
            name: "وزارة التعمير و الإسكان",
            date: "20-10-2024",
            ratio: 50,
            reviewed: "in progress",
            id: 8
        },
    ]

    useEffect(() => {
        getAll(0);
        // debugger;
    }, []);
    const handlePageClick = (event) => {
        console.log(event.selected);
        const newOffset = event.selected;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setCurrentPage(newOffset);
        getAll(newOffset);
    };
    const getAll = (page) => {
        setInnovativeAnswers([]);
        // debugger;
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        };
        let startDate = ""
        let endDate = ""

        if (selectedStartDate != null) {
            startDate = "&FromDate=" + selectedStartDate
        }

        if (selectedEndDate != null) {
            endDate = "&ToDate=" + selectedEndDate
        }
        get(
            environment.getAllInnovativeFormAgencyAnswer +
            "?SkipCount=" +
            page * 6 +
            "&MaxResultCount=" +
            6 ,
            config,
            (res) => {
                // debugger;
                if (res.status == 403) {
                    setMessage(
                        { type: "error", message: res.data.error.message },
                        setOpenConfirmModal(false, setLoading(false))
                    );
                } else if (res.status == 500) {
                    setMessage(
                        { type: "error", message: res.data.error.message },
                        setOpenConfirmModal(false, setLoading(false))
                    );
                } else if (res.status == 200) {
                    console.log(res);
                    setTotalPages(res.data.result.items.length / 6);
                    setTotalCounts(res.data.result.items.length)
                    setInnovativeAnswers(res.data.result.items);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            }
        );
    };

    const openModalAssignReviewer=(cardId)=>{
       setAgencyAnswerId(cardId,setOpen(true))
    }

    useEffect(()=>{
        // getExpertDetail()
        getAllUsers()
      },[])
      const getAllUsers=()=>{
        // setLoading(true)
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        };
        // let arr = []
        // let roles = []
        // arr.push(28)
        // arr.forEach(item => {
        //   roles = "?Roles="+item
        // });
          get(environment.getAllUsersExceptAgencyUsers,config,(res)=>{
            if(res.status == 403){
              setMessage({type:'error',message:res.data.error.message},setOpenConfirmModal(true))
            }else if(res.status == 500){
              setMessage({type:'error',message:res.data.error.message},setOpenConfirmModal(true))
            }else if(res.status == 200){
              console.log(res)
              let arr = []
              res.data.result.items.forEach(user => {
                  arr.push({label:user.fullName,value:user.id})
              });
              arr.unshift({label:"المسؤولين",value:""})
              // setTotalPages(res.data.result.totalCount / 6)
              setUsers(arr)
            }
          })
      }


      const handleChangeEvaluator = value => {
        console.log(value)
        setSelectedEvaluator(value)
      };

         const addEvaluator=(values)=>{
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` }
        };
          let data = {
            // expertId:values.expertId,
            // programExpertId:expertProgram,
            // reviewers:selectedReviewers.value
            innovativeFormAgencyAnswerId:agencyAnswerId,
            correctorId:selectedEvaluator.value
          }
          console.log(data)
          post(environment.innovativeFormAgencyAnswerCorrectorCreate,data,config,(res)=>{
            setSelectedEvaluator("")
             if(res.status == 500){
                toast.error(res.data.error.message,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setOpen(false)
            //   setMessage({type:"error",message:res.data.error.message},setOpenConfirmModal(true,setOpen(false),setLoading(false,getAllUsers())))
             }else{
                toast.success("تم اضافة مقيم بنجاح",{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setOpen(false)
            //   setMessage({type:"success",message:t("تم اضافة مدقق بنجاح")},setOpenConfirmModal(true,setOpen(false),setLoading(false,getAllUsers())))

             }
          })
      }

    return (
        <div className="bg-white rounded-[30px] m-5">
        <NewHeader/>
        {/* <Title title={"المؤشرات"} withoutButton={true} iconTitle={programsIcon} single={true} /> */}
               <Title title={"النماذج الابداعية"} withoutButton={true} iconTitle={programsIcon} subTitle={"النموذج"} subTitleSeconed={"تقييم النموذج"} single={true} />

        {/* <LoadingBar
          color='rgb(43, 41, 105)'
          progress={loading}
          onLoaderFinished={() => setLoading(0)}
          height={2}
          containerStyle={{marginTop:'8vh'}}
        /> */}
        {/* <Title title={"المؤشرات"} withoutButton={true} single={true} /> */}
        <SModal
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle="تم حذف المستخدم بنجاح"
        />
        <CModal
          confirmModal={() => {
            // setOpenDelete(false);
            setOpenConfirmModal(true);
          }}
          open={openDelete}
        //   setOpen={() => setOpenDelete(false)}
          subTitle={"هل أنت متأكد من حذف المستخدم 3001 محمد القحطاني!"}
        />
        <FModal
          content={
            <div>
              {/* <InputLabel
                label={"إسم الجهة عربي "}
                placeholder={"أمانة منطقة الرياض"}
              />
              <InputLabel
                label={"اسم الجهة انجليزي"}
                placeholder={"Al Riyadh Municipality"}
              />
              <InputLabel type={"file"} id={"fileUpload"} label={"الصورة"} /> */}
        <DropdownMultiSelect label={"المقيمين"} placeholder={"اختر مقيمين"} options={users} handleChange={handleChangeEvaluator} value={selectedEvaluator} isMultiple={false}/>
      {/* <div className='text-sm text-red-600 mt-3'>{errors.expertId && touched.expertId && errors.expertId}</div> */}
             {/* <div className="text-left w-full pt-5 flex justify-end">
                 <div>
           <Button onClick={()=>addEvaluator()} loading={false} title={t("اضافة ")} typeColor={"dark"} withIcon={false} icon={plusLight} style={{backgroundColor:'#00114E',width:160}} />
           </div>
           <div>
           <Button onClick={()=>setOpen(false)} loading={false} title={t(" الغاء")} typeColor={"dark"} withIcon={false} icon={plusLight} style={{backgroundColor:'#B40001',borderColor:'#B40001',width:100,marginRight:20}} />
           </div>
           </div> */}
            </div>
          }
          open={open}
          titleButton={"إضافة"}
          widthButton={false}
          width={160}
          confirm={addEvaluator}
          setOpen={() => setOpen(false)}
          headerTitle={"إضافة مقييم"}
        />
        <div className="px-5 bg-white rounded-2xl flex flex-col hidden">
          {/* <NewTitle icon={icon} titlesArr={["المؤشرات"]}/> */}
          {/* <h1 className="text-lg">المؤشرات</h1> */}
          <div className="bg-[#F9F9FF] rounded-xl">
            <div className="flex flex-row cursor-pointer justify-between p-8 items-center text-[#292069] text-[18px] transition ease-in-out duration-200" onClick={handleMenu}>
              تصفية حسب
              <FontAwesomeIcon className="text-[#7D8BAB]" icon={showMenu ? faAngleDown : faAngleUp} />
            </div>
            <div className="bg-white w-[100%] h-[2px] p-0"></div>
            {showMenu && <div className="flex justify-between p-4 items-center">
                <div className="flex items-center gap-4">
                  {arr.map(element => 
                    <div className="w-34">

                      <Filter
                        label={element.label}
                        withoutLabel={element.withoutLabel}
                        filter={element.filter}
                        isSearchable={element.isSearchable}
                        isMultiple={element.isMultiple}
                        isClearable={element.isClearable}
                        key={element.label}
                        value={selectedEntities[arr.indexOf(element)]} 
                        options={element.options}
                        handleChange={handleChangeEntitiesFilter}
                        placeholder={'-- اختر ' + element.label + " --"}
                      />
                  </div>
                  )}
                </div>
                <div className="w-18">
                  <Button withIcon={false} icon={search} style={{padding:10,borderColor:'#2B2969',marginLeft:'auto', marginTop: '5px',backgroundColor:'#2B2969' ,alignSelf: 'left'}} styleText={{color:'white'}} title={t("modules.main.users.users.filter.search")} onClick={() => console.log("test")} typeColor="light"/>
                </div>
            </div>}
          </div>
        </div>
        <div className="px-5 bg-white rounded-2xl">
          {/* <div className="grid bg-[#F9F9FF] rounded-2xl sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6 w-[100%] p-5"> */}
            {/* <Input
              onFocus={(e)=>e.target.type = "date"}
              onBlur={(e) => (e.target.type = "text")}
              containerStyle={{marginLeft:5,marginRight:5}}
              withLabel={true}
              style={{
                backgroundColor: "white",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              // type={"date"}
              value={selectedStartDate}
              onChange={handleChangeStartDate}
              label={t("تاريخ البدء")}
              placeholder={t("تاريخ البدء")}
            /> */}
            {/* <NewInput
              onBlur={(e) => (e.target.type = "text")}
              containerStyle={{marginLeft:5,marginRight:5}}
              withLabel={true}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              type="date"
              value={selectedStartDate}
              onChange={handleChangeStartDate}
              label={t("تاريخ البدء")}
              placeholder={t("تاريخ البدء")}
            /> */}
            {/* <Input
              onFocus={(e)=>e.target.type = "date"}
              onBlur={(e) => (e.target.type = "text")}
              withLabel={true}
              style={{
                backgroundColor: "white",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              // type={"date"}
              value={selectedEndDate}
              onChange={handleChangeEndDate}
              label={t("تاريخ الانتهاء")}
              placeholder={t("تاريخ الانتهاء")}
            /> */}
            {/* <NewInput
              onBlur={(e) => (e.target.type = "text")}
              withLabel={true}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              type="date"
              value={selectedEndDate}
              onChange={handleChangeEndDate}
              label={t("تاريخ الانتهاء")}
              placeholder={t("تاريخ الانتهاء")}
            /> */}
            {/* <DropdownMultiSelect withoutLabel={true} isSearchable={false} value={selectedEntities} handleChange={handleChangeEntitiesFilter} placeholder={t("تاريخ البدء")} label={t("تاريخ البدء")} options={entities} isMultiple={false}/>
        <DropdownMultiSelect withoutLabel={true} isSearchable={false} value={selectedEntities} handleChange={handleChangeEntitiesFilter} placeholder={t("تاريخ الانتهاء")} label={t("تاريخ الانتهاء")} options={entities} isMultiple={false}/> */}
            {/* <DropdownMultiSelect
              withoutLabel={true}
              isSearchable={false}
              value={selectedEntities}
              handleChange={handleChangeEntitiesFilter}
              placeholder={t("الحالة")}
              label={t("الحالة")}
              options={entities}
              isMultiple={false}
            /> */}
            {/* <div className="pt-5"></div>
            <div className="pt-5"></div>
            <div className="pt-5"></div> */}
            <div className="pt-3">
              {/* <Button
                withIcon={true}
                icon={search}
                style={{
                  padding: 10,
                  borderColor: "rgb(43, 41, 105)",
                  marginLeft: "auto",
                }}
                styleText={{ color: "rgb(43, 41, 105)" }}
                title={t("modules.main.users.users.filter.search")}
                onClick={() => getAllPrograms(currentPage)}
                typeColor="light"
              /> */}
             {/* <Button withIcon={false} icon={search} style={{padding:10,borderColor:'#2B2969',marginLeft:'auto',backgroundColor:'#2B2969', 'grid-column-start': '6'}} styleText={{color:'white'}} title={t("modules.main.users.users.filter.search")} onClick={() => getAllPrograms(currentPage)} typeColor="light"/>
  
            </div> */}
          </div>
          {/* </div> */}
  
          {/* <div className="m-5 p-5 bg-white rounded-xl"> */}
          {/* <AnswerStandards/> */}
          {loading ? (
            <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
              {innovativeAnswers.map((program, index) => {
                console.log(program)
               return (<Skeleton
                  key={index}
                  animated={true}
                  width={"100%"}
                  height={"200px"}
                  style={{ marginTop: 10 }}
                  color={"#2B296969"}
                  count={1}
                />
                )
                }
                )
              }
            </div>
          ) : (
            <div>
            {innovativeAnswers.length == 0 ? (
              <div className="flex justify-center items-center">
                <span className="text-lg text-[#2B2969]">
                  {t("لا يوجد نماذج")}
                </span>
              </div>
            ) : (
              <>
              <div className="flex flex-row justify-between items-center">
                <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3>
                <div className="flex flex-row justify-between items-center">
                  {/* <div onClick={() => setShowTable(true)} className={"flex flex-col justify-evenly items-center ml-2 p-2 cursor-pointer rounded-lg " + (showTable ? "bg-gray-500" : "bg-[#F9F9FF]")}> */}
                  <div onClick={() => setShowTable(true)} className="bg-[#F9F9FF] rounded p-5 cursor-pointer">
                    <img src={table} alt="table view" className={`${showTable ? "color-[#292069]" : "color-[#F9F9FF]"} w-[25px]`}/>
                  </div>
                  {/* </div>
                  <div onClick={() => setShowTable(false)} className={"p-[6px] cursor-pointer rounded-lg " + (!showTable ? "bg-gray-500" : "bg-[#F9F9FF]")}> */}
                  <div onClick={() => setShowTable(false)} className="bg-[#F9F9FF] rounded p-5 mr-2 ml-2 cursor-pointer">
                    <img src={activeBlocks} alt="blocks view" className={`${showTable ? "color-[#F9F9FF]" : "color-[#292069]"} w-[25px]`}/>
                    </div>
                  {/* </div> */}
                </div>
              </div>
            {!showTable ? <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
              {innovativeAnswers.map((card, index) => (
                // <Ecard
                //   withIconsAction={false}
                //   goPage={() => history.push(`/programs/${program.id}/settings`)}
                //   onClick={() =>
                //     localStorage.getItem("userType") == "Agency"
                //       ? history.push(
                //           `/programs/${program.id}/program/${localStorage.getItem(
                //             "agencyId"
                //           )}/entity`
                //         )
                //       : permissionsCheck("Pages.UserType.Expert") ||
                //         permissionsCheck("Pages.UserType.Reviewer")
                //       ? history.push(
                //           `/programs/${program.id}/program/${localStorage.getItem(
                //             "userId"
                //           )}/entity`
                //         )
                //       : history.push(`/programs/${program.id}/program`)
                //   }
                //   withLabel={false}
                //   withSetting={true}
                //   withTitle={true}
                //   showButtons={true}
                //   type={program.isPublished == true ? "نشط" : "غير نشط"}
                //   status={true}
                //   withImage={false}
                //   year={"2024"}
                //   items={[
                //     {
                //       title: "تاريخ البدء",
                //       detail: formatDate(new Date(program.startDate)),
                //     },
                //     {
                //       title: "تاريخ الانتهاء",
                //       detail: formatDate(new Date(program.endDate)),
                //     },
                //     {
                //       title: "المرحلة",
                //       detail: program.isPublished == true ? "نشط" : "غير نشط",
                //     },
                //     localStorage.getItem("userType") == "n"
                //       ? {
                //           title: "المعايير",
                //           detail: (
                //             <span
                //               className="underline"
                //               onClick={() =>
                //                 history.push(
                //                   `/programs/${
                //                     program.id
                //                   }/program/${localStorage.getItem(
                //                     "userId"
                //                   )}/entity`
                //                 )
                //               }
                //             >
                //               عرض الكل
                //             </span>
                //           ),
                //         }
                //       : "",
                //   ]}
                //   openDelete={() => setOpenDelete(true)}
                //   openEdit={() => setOpen(true)}
                //   titleAr={program.title}
                //   titleEn={program.title}
                //   image={LogoHeader}
                // />
                // <Card 
                //   from="programs"
                //   status={program?.currentPhase?.titleAr}
                //   settings={() => history.push(`/programs/${program.id}/settings`)}
                //   startDate={formatDate(new Date(program.startDate))}
                //   endDate={formatDate(new Date(program.endDate))}
                //   showMore={() =>
                //     permissionsCheck("Pages.UserType.POC") || permissionsCheck("Pages.UserType.CIO") || permissionsCheck("Pages.UserType.Delegate")
                //       ? history.push(
                //           `/programs/${program.id}/program/${localStorage.getItem(
                //             "agencyId"
                //           )}/entity`
                //         )
                //       : permissionsCheck("Pages.UserType.Expert") ||
                //         permissionsCheck("Pages.UserType.Reviewer")
                //       ? history.push(
                //           `/programs/${program.id}/standards`
                //         )
                //       : history.push(`/programs/${program.id}/program`)
                //   }
                //   moreDetails={() =>
                //         history.push(`/programs/${program.id}/standards`)}
                //   statement={program.titleArabic}
                // />
                   <Card key={card.id} openModal={()=>openModalAssignReviewer(card.id)} id={card.id} onClick={() => console.log("btn clicked")} name={card.agencyTitle} ratio={card.formFinalDegree} date={card.creationTime} reviewed="completed" />

              ))}
              {/* <Ecard withIconsAction={false} goPage={()=>history.push("/programs/1/settings")} onClick={()=>history.push("/programs/1/program")} withLabel={false} withSetting={true} withTitle={true} showButtons={true} type={"نشط"} status={true} withImage={false} year={"2024"} items={[{title:"تاريخ البدء",detail:"15/04/2023"},{title:"تاريخ الانتهاء",detail:"15/04/2023"},{title:"الحالة",detail:"نشط"},{title:"الخبراء",detail:"خبير 1"}]} openDelete={()=>setOpenDelete(true)} openEdit={()=>setOpen(true)} titleAr={"برنامج الأمان الإلكتروني"} titleEn={"Al Riyadh Municipality"} image={LogoHeader}/>
        <Ecard withIconsAction={false} goPage={()=>history.push("/programs/1/settings")} onClick={()=>history.push("/programs/1/program")} withLabel={false} withSetting={true} withTitle={true} showButtons={true} type={"نشط"} status={true} withImage={false} year={"2024"} items={[{title:"تاريخ البدء",detail:"15/04/2023"},{title:"تاريخ الانتهاء",detail:"15/04/2023"},{title:"الحالة",detail:"نشط"},{title:"الخبراء",detail:"خبير 1"}]} openDelete={()=>setOpenDelete(true)} openEdit={()=>setOpen(true)} titleAr={"برنامج الأمان الإلكتروني"} titleEn={"Al Riyadh Municipality"} image={LogoHeader}/>
        <Ecard withIconsAction={false} goPage={()=>history.push("/programs/1/settings")} onClick={()=>history.push("/programs/1/program")} withLabel={false} withSetting={true} withTitle={true} showButtons={true} type={"نشط"} status={true} withImage={false} year={"2024"} items={[{title:"تاريخ البدء",detail:"15/04/2023"},{title:"تاريخ الانتهاء",detail:"15/04/2023"},{title:"الحالة",detail:"نشط"},{title:"الخبراء",detail:"خبير 1"}]} openDelete={()=>setOpenDelete(true)} openEdit={()=>setOpen(true)} titleAr={"برنامج الأمان الإلكتروني"} titleEn={"Al Riyadh Municipality"} image={LogoHeader}/>
        <Ecard withIconsAction={false} goPage={()=>history.push("/programs/1/settings")} onClick={()=>history.push("/programs/1/program")} withLabel={false} withSetting={true} withTitle={true} showButtons={true} type={"نشط"} status={true} withImage={false} year={"2024"} items={[{title:"تاريخ البدء",detail:"15/04/2023"},{title:"تاريخ الانتهاء",detail:"15/04/2023"},{title:"الحالة",detail:"نشط"},{title:"الخبراء",detail:"خبير 1"}]} openDelete={()=>setOpenDelete(true)} openEdit={()=>setOpen(true)} titleAr={"برنامج الأمان الإلكتروني"} titleEn={"Al Riyadh Municipality"} image={LogoHeader}/> */}
            </div> 
              // { 
              //   name:i18n.language == Lang.AR ? ar.name : en.name, 
              //   selector: row => row.fullName, 
              //   header: i18n.language == Lang.AR ? ar.name : en.name,
              //   accessorKey: 'name',
              //   footer: props => props.column.id,
              //   cell: info => <div className="flex flex-row justify-between items-center w-[100%]"><div className="rounded-full w-[50px] h-[50px]"><img className="rounded-full w-[50px] h-[50px]" src={info.profilePicture == null ? avatar : `${process.env.REACT_APP_FRONT_URL}${info.profilePicture}`}/></div><div className="flex flex-col justify-between"><span className="text-sm text-black">{info.fullName}</span><span className="text-sm text-[#7F7F7F]">{info.emailAddress}</span></div></div>,
              //   width:"250px",
              //   sortable: true,
              // },
            : <div className="bg-white p-10">
          <NewTable
              data={innovativeAnswers}
              columns={[
                  // {name: 'رقم', selector: row => row.id, sortable: true},
                  {name:'أسم الجهة',accessorKey: 'agencyTitle', selector: row => row.agencyTitle, sortable: true,cell:info => <div className="text-sm text-[#292069]">{info.agencyTitle}</div>},
                  {name:'التاريخ',accessorKey: 'creationTime', selector: row => row.creationTime, sortable: true,cell:info => <div className="text-sm text-[#292069]">{formatDate(new Date(info.creationTime))}</div>},

                //   {name:'المرحلة', accessorKey: 'phase', selector: row => row.currentPhase?.titleAr,cell:info => <div className="text-sm text-[#1CC081]">{info.currentPhase?.titleAr == null ? "لا يوجد" : info.currentPhase?.titleAr}</div> , sortable: true},
                //   {name:'تاريخ البدء', accessorKey: 'startDate',selector: row => row.startDate,cell:info => <div className="text-sm text-[#848A9C]">{formatDate(new Date(info.startDate))}</div>, sortable: true},
                //   {name:'تاريخ الانتهاء',accessorKey: 'endDate', selector: row => row.endDate,cell:info => <div className="text-sm text-[#848A9C]">{formatDate(new Date(info.endDate))}</div>, sortable: true},
                  // {name:'معايير المؤشر', selector: row => row.status, sortable: true,cell:info => <div></div>},
                //   {name:'إجراءات', selector: row => row.status, sortable: true,cell:info => <div className="flex flex-row items-center justify-around w-[100%]"><div onClick={() => history.push(`/programs/${info.id}/settings`)}><img src={settingNew} className="w-[40px] cursor-pointer"/></div><div onClick={()=>
                //     permissionsCheck("Pages.UserType.POC") || permissionsCheck("Pages.UserType.CIO") || permissionsCheck("Pages.UserType.Delegate")
                //       ? history.push(
                //           `/programs/${info.id}/program/${localStorage.getItem(
                //             "agencyId"
                //           )}/entity`
                //         )
                //       : permissionsCheck("Pages.UserType.Expert") ||
                //         permissionsCheck("Pages.UserType.Reviewer")
                //       ? history.push(
                //           `/programs/${info.id}/standards`
                //         )
                //       : history.push(`/programs/${info.id}/program`)
                //   }><img src={viewNew} className="w-[40px] cursor-pointer"/></div></div>}
                ]}
              // selectableRows={true}
            //   onSelectedRowsChange={handleChangeEntitiesFilter}
              pagination={false}
          />
        </div>}
            </>
            )}
            </div>
          )}
          <div className="pb-5 pt-5">
            <ReactPaginate
              nextLabel={loading == true ? null : t("general.next")}
              onPageChange={handlePageClick}
              initialPage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={totalPages}
              previousLabel={loading == true ? null : t("general.previous")}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item-previous"
              previousLinkClassName="page-link-previous"
              nextClassName="page-item-next"
              nextLinkClassName="page-link-next"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
//   <div className="bg-white rounded-[30px] m-5">
//       <NewHeader/>
//       <Title title={"النماذج الابداعية"} withoutButton={true} iconTitle={programsIcon} subTitle={"النموذج"} subTitleThired={"تقييم النموذج"} thired={true} />
//             {loading ? <Skeleton animated={true} color={"#2B296969"} width={"100%"} height={"500px"} count={1} /> :
//                 (
// <div className='p-5'>
//             <div className="bg-[#F9F9FF] mt-5 rounded-xl ">
//                 <div className="flex flex-row cursor-pointer justify-between py-8 px-5 items-center text-[#292069] text-[18px] transition ease-in-out duration-200" onClick={handleMenu}>
//                     تصفية حسب
//                     <FontAwesomeIcon className="text-[#7D8BAB]" icon={showMenu ? faAngleDown : faAngleUp} />
//                 </div>
//                 <div className="bg-white w-[100%] h-[2px] p-0"></div>
//                 {showMenu && <div className="grid sm:grid-cols-1 py-7 px-4 md:grid-cols-2 lg:grid-cols-4 mb-8">
//                     {arr.map(element => <Filter
//                         label={element.label}
//                         withoutLabel={element.withoutLabel}
//                         filter={element.filter}
//                         isSearchable={element.isSearchable}
//                         isMultiple={element.isMultiple}
//                         isClearable={element.isClearable}
//                         key={element.label}
//                         value={selectedEntities[arr.indexOf(element)]}
//                         options={element.options}
//                         handleChange={""}
//                         placeholder={element.placeholder}
//                     />
//                     )}
//                     <div className='flex flex-col justify-end mb-1 mr-1'>
//                         <label className='mb-2 text-[#7A7A7A]'>تاريخ</label>
//                         <input type='date' placeholder='من' className='w-[230px] h-12 rounded-3xl text-[#7A7A7A] border-[#A3AED0]' />
//                     </div>
//                     <div className='flex flex-col justify-end items-center mb-1'>
//                         <input type='date' placeholder='الى' className='w-[230px] h-12 rounded-3xl text-[#7A7A7A] border-[#A3AED0]' />
//                     </div>
//                     <div className='w-full col-start-4 col-span-4'>
//                         <Button withIcon={false} icon={search} style={{ padding: 10, borderColor: '#2B2969', marginLeft: 'auto', marginTop: '5px', backgroundColor: '#2B2969', alignSelf: 'left' }} styleText={{ color: 'white' }} title={t("modules.main.users.users.filter.search")} typeColor="light" />
//                     </div>
//                 </div>}
//             </div>
//             <div className='mb-4 p-5'>
//                 <section className='my-5'>
//                     <label className='text-[14px] text-[#7D8BAB]'>{number} عنصر بالقائمة</label>
//                 </section>
//                 <section className='grid grid-cols-3 gap-4'>
//                     {cardArr1.map((card) => (
                   
//                         <Card key={card.id} id={card.id} onClick={() => console.log("btn clicked")} name={card.name} ratio={card.formFinalDegree} date={card.CreationTime} reviewed="completed" />

//                     ))}
//                 </section>
//                         </div>
//                         </div>
//                 )
//             }
//         </div>
    )
  
}

export default CreativeCards