import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { permissionsCheck } from "../helpers/utils";

const IndicatorCard = ({
  id,
  title,
  body,
  buttonTxt = "دخول الان",
  bgColor,
  isActive,
  chooseIndicator,
  // loading,
}) => {
  const [loading, setLoading] = useState(false);

  const cardClass = `relative h-full mx-auto min-h-[360px] max-w-[700px] justify-between flex-1 p-6 text-center border rounded-xl shadow flex flex-col gap-5 transition-all duration-300 ${
    // !isActive ? "filter grayscale blur-[1px]" : ""
    // !isActive ? "filter grayscale" : ""
    ""
  }`;
  const btnClass = ` ${
    isActive || permissionsCheck("Pages.UserType.Admin")
      ? "bg-white/10 hover:bg-white/20 cursor-pointer"
      : "cursor-not-allowed  bg-black/30 hover:bg-black/50 text-gray-100"
    // : "cursor-not-allowed bg-red-800 text-black bg-opacity-60"
  } transition-opacity inline-flex items-center px-14 py-2 pb-4 text-sm font-medium text-center text-white rounded-3xl `;

  return (
    <div
      className={cardClass}
      style={{
        backgroundColor: bgColor,
      }}
    >
      {!isActive && (
        <div className="absolute top-5 left-5 flex flex-row-reverse gap-2 items-center bg-slate-100 bg-opacity-20 rounded-full p-2 px-4">
          <FontAwesomeIcon icon={faLock} className="text-2xl  " />
          <p className="mb-1">غير نشط</p>
        </div>
      )}

      <h5 className="mb-2  text-lg  font-bold tracking-tight text-white">
        {title}
      </h5>
      <p className="mb-3 font-normal text-gray-100">{body}</p>
      <div className="flex justify-center">
        <button
          className={btnClass}
          onClick={() => {
            chooseIndicator(id);
            setLoading(true); // Set loading to true when button is clicked
          }}
          disabled={
            loading || (!permissionsCheck("Pages.UserType.Admin") && !isActive)
          }
        >
          {loading ? (
            <div className="flex flex-row  justify-center items-center">
              <Spinner aria-label="Info spinner example" color="info" />
              <span className="pl-3 pr-3 text-sm text-white">جاري التحميل</span>
            </div>
          ) : (
            buttonTxt
          )}
        </button>
      </div>
    </div>
  );
};

export default IndicatorCard;
