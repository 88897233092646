import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {Formik} from "formik";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {programsIcon, removeIcon} from "../../../assets/icons/Index";
import {DropdownMultiSelect, InputLabel,} from "../../../components";
import UploadAdapter from "../../../components/UploadAdapter";
import environment from "../../../services/environment";
import {get, put} from "../../../services/Request";
import {toast} from "react-toastify";
import AppButton from "../../../components/buttons/appButton";
import HeaderWithBreadcrumbLayout from "../../../layouts/headerWithBreadcrumbLayout";
import useAxiosGetRequest from "../../../helpers/customHooks/useAxiosGetRequest";
import NewDropdownMultiSelect from "../../../components/NewDropDownMultiSelect";
import NewTooltip from "../../../components/NewTooltip";
import {errorToast} from "../../../helpers/toast";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
import {formatDate, permissionsCheck} from "../../../helpers/utils";

const EditVisit = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [dateStyle, setDateStyle] = useState({ border: "2px solid #f4f7fe" });

  const [arrayOfDates, setArrayOfDates] = useState(
    [
      {
        date: formatDate(new Date(state?.visitDate)),
        startTime: state?.startTime,
        endTime: state?.endTime,
      },
    ] || []
  );

  const [date, setDate] = useState(
    formatDate(new Date(state?.visitDate)) || ""
  );
  const [startTime, setStartTime] = useState(state?.startTime || "");
  const [endTime, setEndTime] = useState(state?.endTime || "");

  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [description, setDescription] = useState(
    state?.visitRequirements || ""
  );

  const [selectedTeam, setSelectedTeam] = useState(
    {
      label: state?.visitTeam?.teamName?.toString() || "",
      value: state?.visitTeamId || "",
    } || ""
  );
  const [selectedType, setSelectedType] = useState(
    {
      label: state?.meetingAndVisitType?.nameInArabic?.toString() || "",
      value: state?.meetingAndVisitTypeId || "",
    } || ""
  );

  const [selectedClassification, setSelectedClassification] = useState(
    {
      label: state?.agencyClassification?.nameInArabic.toString() || "",
      value: state?.agencyClassification?.id || "",
    } || ""
  );
  const [selectedSector, setSelectedSector] = useState(
    {
      label: state?.agencySector?.nameInArabic.toString() || "",
      value: state?.agencySector?.id || "",
    } || ""
  );

  const [urlMeeting, setUrlMeeting] = useState(state?.locationOrLink || "");
  const [urlLocation, setUrlLocation] = useState(state?.locationOrLink || "");

  // -------------------- //
  const [teams, setTeams] = useState([]);
  const [types, setTypes] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const [classifications, setClassifications] = useState([]);
  const [sectors, setSectors] = useState([]);

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  const handleChangeUrlMeeting = (e) => {
    setUrlMeeting(e.target.value);
  };
  const handleChangeUrlLocation = (e) => {
    setUrlLocation(e.target.value);
  };

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getVisitTeam, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        let newTeams = [];
        res.data.result.items.forEach((team, index) => {
          let obj = { label: team.teamName, id: team.id, value: team.id };
          newTeams.push(obj);
        });
        setTeams(newTeams, getSectors());
      }
    });
  };

  const getSectors = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencySectors, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(t("general.serverError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let newSectors = [];
        res.data.result.items.forEach((sector, index) => {
          let obj = {
            label: sector?.nameInArabic?.toString() || "",
            id: sector?.id,
            value: sector?.id,
          };
          newSectors.push(obj);
        });
        setSectors(newSectors, getClassifications());
      }
    });
  };

  const getClassifications = (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencyClassification, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"));
      } else if (res.status == 500) {
        toast.error(t("general.serverError"));
      } else if (res.status == 200) {
        console.log(res.data.result.items, "res.data.result.items");
        let newClassifications = [];
        res.data.result.items.forEach((classification, index) => {
          let obj = {
            label: classification?.nameInArabic.toString() || "",
            id: classification?.id,
            value: classification?.id,
          };
          newClassifications.push(obj);
        });
        setClassifications(newClassifications, getMeetingTypes());
      }
    });
  };

  const getAgencies = (sectorId, classificationId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    let sectorIdQuery = "";
    let classificationIdQuery = "";

    if (sectorId) {
      sectorIdQuery = `AgencySectorId=${sectorId}`;
    }
    if (classificationId) {
      sectorIdQuery = `&AgencyClassificationId=${classificationId}`;
    }

    get(
      environment.getAllAgencyWithoutPagination +
        "?" +
        sectorIdQuery +
        classificationIdQuery,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t("general.authError"));
        } else if (res.status == 500) {
          toast.error(t("general.serverError"));
        } else if (res.status == 200) {
          let newAgencies = [];
          res.data.result.forEach((agency, index) => {
            console.log(res.data.result, "newAgencies");
            let obj = {
              label: agency?.name?.toString(),
              id: agency?.id,
              value: agency?.id,
            };
            newAgencies.push(obj);
          });
          setAgencies(newAgencies);
        }
      }
    );
  };

  // ---------------------------------------------------- //
  const [selectedAgency, setSelectedAgency] = useState(
    state?.agencyVisitSlots.map((ele) => {
      return {
        label: ele?.agencyNameInArabic?.toString(),
        value: ele?.agencyId,
      };
    }) || []
  );
  // const [selectedAgency, setSelectedAgency] = useState("");

  const handleAllAgenciesChange = (value) => {
    setSelectedAgency(value || []);
    // setSelectedAgency(value || "");
  };

  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination, {
      AgencyClassificationId: selectedClassification.value,
      AgencySectorId: selectedSector.value,
    });
  }, [selectedClassification.value, selectedSector.value]);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item?.nameInArabic?.toString() || "",
        value: item?.id,
        name: item?.name || "",
      };
    });

  // ---------------------------------------------------- //

  const getMeetingTypes = (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getMeetingsType, config, (res) => {
      if (res.status == 403) {
        toast.error(t("general.authError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(t("general.serverError"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let newMeetingsType = [];
        res.data.result.items.forEach((meetingType, index) => {
          let obj = {
            label: meetingType?.nameInArabic?.toString(),
            id: meetingType?.id,
            value: meetingType?.id,
          };
          newMeetingsType.push(obj);
        });
        setTypes(newMeetingsType);
        setLoading(false);
      }
    });
  };

  const handleChangeTeam = (value) => {
    setSelectedTeam(value);
  };

  const handleChangeAgency = (value) => {
    setSelectedAgency(value);
  };

  const handleChangeSector = (value) => {
    setSelectedSector(value || "");
  };

  const handleChangeClassification = (value) => {
    setSelectedClassification(value || "");
    // getAgencies(selectedSector.value, value.id)
  };

  const handleChangeType = (value) => {
    setSelectedType(value || "");
  };

  const handleChangeStartTime = (e) => {
    setStartTime(e.target.value);
  };

  const handleChangeEndTime = (e) => {
    setEndTime(e.target.value);
  };

  const editVisit = (values) => {
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: state.id,
      visitDate: values.visitDate,
      startTime: values.startTime,
      endTime: values.endTime,
      visitTeamId: values.visitTeamId,
      visitRequirements: values.visitRequirements,
      agencySectorId: values.agencySectorId,
      agencyClassificationId: values.agencyClassificationId,
      meetingAndVisitTypeId: values.meetingAndVisitTypeId,
      locationOrLink: values.locationOrLink,
      meetingAndVisitGoalId: values.targetOfVisit,
      agencyVisitSlots: values.agencyVisitSlots,
      standardVisitSlots: values.standardVisitSlots,
    };
    put("services/app/VisitSlots/Update", data, config, (res) => {
      setAddLoading(false);
      if (res.status == 200) {
        toast.info("تم تحديث موعد بنجاح", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        history.push("/visitsAndMeetings");
      } else {
        toast.error(res?.response?.data?.error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  //LATER
  // useEffect(() => {
  //   if (
  //     selectedClassification.value == 1 ||
  //     selectedClassification.value == 11
  //   ) {
  //     setSelectedType({ label: "حضوري", id: 1, value: 1, disabled: false });
  //   }
  // }, [selectedClassification.value]);

  // -------------------------------------- //
  const addVisitBreadcumb = [
    {
      icon: programsIcon,
      title: "الزيارات",
      url: "/visitsAndMeetings",
    },
    {
      title: "تعديل موعد الحجز",
    },
  ];

  // --------------- targetOfVisi ---------------
  const [selectedTargetOfVisit, setSelectedTargetOfVisit] = useState(
    {
      label: state?.meetingAndVisitGoal?.nameInArabic || "",
      value: state?.meetingAndVisitGoal?.id || "",
    } || ""
  );

  const handleselectedTargetOfVisitChange = (value) => {
    setSelectedTargetOfVisit(value || "");
  };

  const [
    getAllVisitTargets,
    allVisitTargets,
    // getAllVisitTargetsLoading,
  ] = useAxiosGetRequest();

  useEffect(() => {
    getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
  }, []);

  const allVisitTargetsOptions =
    allVisitTargets?.result?.items &&
    allVisitTargets?.result?.items.map((item) => {
      return {
        label: item?.nameInArabic?.toString() || "",
        value: item?.id,
        name: item?.name || "",
      };
    });

  // --------------- targetOfVisi ---------------

  // ------------------------------------------- //

  const [selectedVisitTeamStandards, setSelectedVisitTeamStandards] = useState(
    state?.standardVisitSlots.map((ele) => {
      return {
        label: ele?.standardTitleArabic?.toString(),
        value: ele?.standardId,
      };
    }) || []
  );

  // for multiSelect
  const [getAllStandards, allStandards] = useAxiosGetRequest();

  useEffect(() => {
    getAllStandards(environment.getAllStandard, {
      MaxResultCount: 1000,
    });
  }, []);

  const allStandardsOptions =
    allStandards?.result?.items &&
    allStandards?.result?.items?.map((item) => {
      return { label: item?.code || "", value: item?.id };
    });

  const handleChangeStandards = (value) => {
    setSelectedVisitTeamStandards(value || []);
  };
  // ------------------------------------------- //

  const handleAddDate = (newDate) => {
    if (!newDate.date || !newDate.startTime || !newDate.endTime) {
      errorToast("برجاء ادخال تاريخ ووقت الموعد");
      return;
    } else if (newDate.date >= "2025-01-01") {
      errorToast("أقصى تاريخ لحجز موعد هو 2024");
    } else if (new Date(newDate.date) < new Date()) {
      errorToast("لا يمكن حجز موعد في الماضي");
    } else if (newDate.startTime > newDate.endTime) {
      errorToast("لابد ان يكون وقت البداية قبل وقت النهاية");
      return;
    } else if (newDate.endTime < newDate.startTime) {
      errorToast("لابد ان يكون وقت النهاية بعد وقت البداية");
      return;
    }

    setArrayOfDates([newDate]);

    // setDate("");
    // setStartTime("");
    // setEndTime("");
  };
  const handleDeleteDate = (index) => {
    let updatedArray = [...arrayOfDates];
    updatedArray = updatedArray.filter((_, i) => i !== index);
    setArrayOfDates(updatedArray);
  };

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={addVisitBreadcumb}>
      <Formik
        initialValues={{
          visitDate: [],
          startTime: [],
          endTime: [],
          meetingAndVisitTypeId: "",
          visitTeamId: "",
          visitRequirements: "",
          agencySectorId: "",
          agencyClassificationId: "",
          targetOfVisit: "",
          locationOrLink: "",
          agencyVisitSlots: [],
          standardVisitSlots: [],
        }}
        validate={(values) => {
          const errors = {};

          if (!arrayOfDates.length) {
            if (!date) {
              errors.visitDate = t("تاريخ الموعد مطلوب");
            }
            if (date >= "2025-01-01") {
              errors.visitDate = "أقصى تاريخ لحجز موعد هو 2024";
            }
            if (new Date(date) < new Date()) {
              errors.visitDate = "لا يمكن حجز موعد في الماضي";
            }
            if (!startTime) {
              errors.startTime = t("وقت البداية مطلوب");
            }
            if (startTime > endTime) {
              errors.startTime = t("لابد ان يكون وقت البداية قبل وقت النهاية");
            }
            if (!endTime) {
              errors.endTime = t("وقت النهاية مطلوب");
            }
            if (endTime < startTime) {
              errors.endTime = t("لابد ان يكون وقت النهاية بعد وقت البداية");
            }
          }

          if (!selectedSector) {
            errors.agencySectorId = "القطاعات مطلوبة";
          }
          if (!selectedClassification) {
            errors.agencyClassificationId = "تصنيف الجهات مطلوب";
          }

          if (!selectedAgency.length) {
            errors.agencyVisitSlots = "الجهات المشمولة في الزيارة مطلوبة";
          }

          if (!selectedTeam) {
            errors.visitTeamId = t("يجب اختيار فريق");
          }

          if (!selectedType) {
            errors.meetingAndVisitTypeId = t("نوع الموعد مطلوب");
          }

          if (!selectedType) {
            errors.onSite = t("نوع الموعد مطلوب");
          }

          // if (!urlLocation && !urlMeeting) {
          //   errors.locationOrLink = t("الرابط مطلوب");
          // }

          if (!description) {
            errors.visitRequirements = t("الاجندة مطلوبة");
          }

          if (!selectedTargetOfVisit) {
            errors.targetOfVisit = "الهدف من الزيارة مطلوب";
          }

          if (selectedTargetOfVisit.label == "زيارات من توصيات الخبير") {
            if (!selectedVisitTeamStandards?.length) {
              errors.standardVisitSlots =
                "المعايير مطلوبة في هذا النوع من الزيارات";
            }
          }

          return errors;
        }}
        onSubmit={(values) => {
          values.visitDate = arrayOfDates[0].date;
          values.startTime = arrayOfDates[0].startTime;
          values.endTime = arrayOfDates[0].endTime;
          values.meetingAndVisitTypeId = selectedType.value;
          values.visitRequirements = description;
          values.visitTeamId = selectedTeam.value;
          values.agencyClassificationId = selectedClassification.value;
          values.agencySectorId = selectedSector.value;
          values.targetOfVisit = selectedTargetOfVisit.value;
          values.agencyVisitSlots = selectedAgency.map((ele) => {
            return {
              agencyId: ele.value,
            };
          });
          values.standardVisitSlots = selectedVisitTeamStandards.map((ele) => {
            return { standardId: ele.value };
          });
          values.locationOrLink =
            selectedType.value == 1 ? urlLocation : urlMeeting;

          editVisit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="bg-white rounded-3xl gap-5 pb-5 flex flex-col justify-between items-center"
          >
            <div className="w-full flex flex-col md:flex-row gap-5 ">
              <section className="w-full flex flex-col">
                <InputLabel
                  placeholder={"22-10-2023"}
                  label={"تاريخ الموعد"}
                  onChange={handleChangeDate}
                  value={date}
                  type="date"
                  style={dateStyle}
                />
                <div className="text-red-700 mt-1">
                  {errors.visitDate && touched.visitDate && errors.visitDate}
                </div>
              </section>
              <section className="w-full flex flex-col">
                <InputLabel
                  placeholder={"10:00pm"}
                  label={"وقت البدء"}
                  withoutLabel={false}
                  onChange={handleChangeStartTime}
                  value={startTime}
                  type="time"
                  style={dateStyle}
                />
                <div className="text-red-700 mt-1">
                  {errors.startTime && touched.startTime && errors.startTime}
                </div>
              </section>
              <section className="w-full flex flex-col">
                <InputLabel
                  placeholder={"10:00pm"}
                  label={"وقت الانتهاء"}
                  withoutLabel={false}
                  onChange={handleChangeEndTime}
                  value={endTime}
                  type="time"
                  style={dateStyle}
                />
                <div className="text-red-700 mt-1">
                  {errors.endTime && touched.endTime && errors.endTime}
                </div>
              </section>
              <div className="flex flex-col items-end">
                <div className="mb-[2.5rem]"></div>
                {arrayOfDates.length > 1 ? null : (
                  <AppButton
                    onClick={() => {
                      handleAddDate({
                        date: date,
                        startTime: startTime,
                        endTime: endTime,
                      });
                    }}
                  >
                    {arrayOfDates.length == 0 ? "اٍضافة" : "تعديل"}
                  </AppButton>
                )}
              </div>
            </div>

            <div className="w-full grid gap-3">
              {arrayOfDates.map((ele, index) => (
                <div className="py-5 pr-4 pl-6 bg-blue rounded-lg">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-2 items-center cursor-pointer flex-grow">
                      <div className="w-11 h-11 bg-[#D2F2E6] rounded-full flex justify-center items-center">
                        <p className="mb-1">{index + 1}</p>
                      </div>
                      <div className="flex gap-5">
                        <p className={`mb-1 select-none ${"text-white"}`}>
                          تاريخ الموعد: {ele.date}
                        </p>
                        <p className={`mb-1 select-none ${"text-white"}`}>
                          وقت البداية: {ele.startTime}
                        </p>
                        <p className={`mb-1 select-none ${"text-white"}`}>
                          وقت النهاية: {ele.endTime}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-4 items-center">
                      <NewTooltip content={"حذف"}>
                        <div
                          onClick={() => handleDeleteDate(index)}
                          className="rounded-full p-1 bg-[#FEEDEF] cursor-pointer"
                        >
                          <img src={removeIcon} alt="delete icon" />
                        </div>
                      </NewTooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedTargetOfVisit}
                handleChange={handleselectedTargetOfVisitChange}
                label="الهدف من الزيارة"
                options={allVisitTargetsOptions || []}
                isMultiple={false}
                isRequired={true}
              />
              <div className="text-red-700 mt-1">
                {errors.targetOfVisit &&
                  touched.targetOfVisit &&
                  errors.targetOfVisit}
              </div>
            </div>

            <div className="w-full grid sm:grid-cols-1 gap-5">
              <div className="">
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedClassification}
                  handleChange={handleChangeClassification}
                  label="تصنيف الجهات"
                  options={classifications}
                  isMultiple={false}
                  isRequired={true}
                />
                <div className="text-red-700 mt-1">
                  {errors.agencyClassificationId &&
                    touched.agencyClassificationId &&
                    errors.agencyClassificationId}
                </div>
              </div>

              <div className="">
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedSector}
                  handleChange={handleChangeSector}
                  label="قطاعات الجهات"
                  options={sectors}
                  isMultiple={false}
                  isRequired={true}
                />
                <div className="text-red-700 mt-1">
                  {errors.agencySectorId &&
                    touched.agencySectorId &&
                    errors.agencySectorId}
                </div>
              </div>

              <div className="w-full flex flex-col">
                <NewDropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  isMultiple={true}
                  value={selectedAgency}
                  options={allAgenciesOptions || []}
                  handleChange={handleAllAgenciesChange}
                  placeholder="اختر الجهات الحكومية"
                  label="الجهات الحكومية"
                  isRequired={true}
                />

                <div className="text-red-700 mt-1">
                  {errors.agencyVisitSlots &&
                    touched.agencyVisitSlots &&
                    errors.agencyVisitSlots}
                </div>
              </div>

              <div className="w-full flex flex-col">
                <NewDropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  isMultiple={true}
                  value={selectedVisitTeamStandards}
                  options={allStandardsOptions || []}
                  handleChange={handleChangeStandards}
                  // placeholder="اختر الجهات الحكومية"
                  label="المعايير"
                  isRequired={
                    selectedTargetOfVisit.label == "زيارات من توصيات الخبير"
                  }
                />

                <div className=" text-red-600 mt-1">
                  {errors.standards && touched.standards && errors.standards}
                </div>
              </div>
            </div>

            <section className="w-full flex flex-col">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedTeam}
                handleChange={handleChangeTeam}
                label="الفريق"
                options={teams}
                isMultiple={false}
                isRequired={true}
              />
              <div className="text-red-700 mt-1">
                {errors.visitTeamId &&
                  touched.visitTeamId &&
                  errors.visitTeamId}
              </div>
            </section>

            <section className="w-full flex flex-col">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedType}
                handleChange={handleChangeType}
                label="نوع الموعد"
                options={types || []}
                isMultiple={false}
                isRequired={true}
              />
              <div className="text-red-700 mt-1">
                {errors.meetingAndVisitTypeId &&
                  touched.meetingAndVisitTypeId &&
                  errors.meetingAndVisitTypeId}
              </div>
            </section>

            {selectedType.value == 2 ? (
              <section className="w-full flex flex-col">
                <label className="text-[16px] text-[#292069] mb-3">
                  رابط الاجتماع
                </label>
                <input
                  style={dateStyle}
                  onChange={handleChangeUrlMeeting}
                  value={urlMeeting}
                  type="url"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                />
                <div className="text-red-700 mt-1">
                  {errors.locationOrLink &&
                    touched.locationOrLink &&
                    errors.locationOrLink}
                </div>
              </section>
            ) : selectedType.value == 1 ? (
              <section className="w-full flex flex-col">
                <label className="text-[16px] text-[#292069] mb-3">
                  رابط المكان
                </label>
                <input
                  style={dateStyle}
                  onChange={handleChangeUrlLocation}
                  value={urlLocation}
                  type="url"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                />
                <div className="text-red-700 mt-1">
                  {errors.locationOrLink &&
                    touched.locationOrLink &&
                    errors.locationOrLink}
                </div>
              </section>
            ) : null}
            <div className="w-full">
              <label className="text-[16px] text-[#292069]">
                أجندة الإجتماع
              </label>
              <div className="mt-3">
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onReady={(editor) => {
                    setDescription(editor.getData());
                    editor.plugins.get(
                      "FileRepository"
                    ).createUploadAdapter = function(loader) {
                      return new UploadAdapter(loader);
                    };
                  }}
                  config={{
                    language: "ar",
                    simpleUpload: {
                      uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                      withCredentials: true,
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                      },
                    },
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                    editor.plugins.get(
                      "FileRepository"
                    ).createUploadAdapter = function(loader) {
                      return new UploadAdapter(loader);
                    };
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
              <div className="text-red-700 mt-1">
                {errors.visitRequirements &&
                  touched.visitRequirements &&
                  errors.visitRequirements}
              </div>
            </div>
            {permissionsCheck("Pages.VisitSlots.Edit")?(
            <div className="w-full flex flex-row justify-end">
              <AppButton loading={addLoading} type="submit">
                تعديل موعد
              </AppButton>
            </div>):null}
          </form>
        )}
      </Formik>
    </HeaderWithBreadcrumbLayout>
  );
};

export default EditVisit;
