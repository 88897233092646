import { useState } from "react";
import React from "react";
import SelectFilterInput from "../../../components/SelectFilterInput/SelectFilterInput";

const AxisWeights = () => {
  const inputRef = React.createRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const AXIS = [
    { axisName: "العنصر الاول", currentWeight: 20.65, id: "0c234c2340234c" },
    { axisName: "العنصر الثاني", currentWeight: 15.3, id: "1a2b3c4d5e6f7g" },
    { axisName: "العنصر الثالث", currentWeight: 25.4, id: "2b3c4d5e6f7g8h" },
    { axisName: "العنصر الرابع", currentWeight: 10.75, id: "3c4d5e6f7g8h9i" },
    { axisName: "العنصر الخامس", currentWeight: 30.2, id: "4d5e6f7g8h9i0j" },
    { axisName: "العنصر السادس", currentWeight: 18.5, id: "5e6f7g8h9i0j1k" },
    { axisName: "العنصر السابع", currentWeight: 22.9, id: "6f7g8h9i0j1k2l" },
    { axisName: "العنصر الثامن", currentWeight: 19.45, id: "7g8h9i0j1k2l3m" },
    { axisName: "العنصر التاسع", currentWeight: 28.35, id: "8h9i0j1k2l3m4n" },
    { axisName: "العنصر العاشر", currentWeight: 12.6, id: "9i0j1k2l3m4n5o" },
    {
      axisName: "العنصر الحادي عشر",
      currentWeight: 24.7,
      id: "0j1k2l3m4n5o6p",
    },
    {
      axisName: "العنصر الثاني عشر",
      currentWeight: 21.15,
      id: "1k2l3m4n5o6p7q",
    },
  ];

  return (
    <>
      <button
        type="button"
        onClick={toggleModal}
        className="border-2 border-blue h-[76px] rounded-xl w-full text-blue font-semibold text-lg leading-7 hover:border-blue_hover"
      >
        العناصر
      </button>
      {isModalOpen && (
        <>
          <div className="fixed w-screen h-screen bg-blue opacity-30 top-0 left-0 z-10"></div>
          <div className="flex flex-col gap-6 fixed border-t-[20px] border-blue top-1/2 left-[40%] -translate-x-1/2 -translate-y-1/2 w-[620px] max-h-2/3 bg-white rounded-xl p-6 z-10 shadow-[0px_8px_8px_-4px_#1018280A,0px_20px_24px_-4px_#1018281A]">
            <div className="flex flex-col gap-1 items-center">
              <h3 className="text-[#101828] leading-7 text-lg font-semibold">
                اوزان العناصر
              </h3>
              <span className="text-[#475467] font-normal text-sm leading-5">
                نص وهمي يمكن استبداله بآي نص آخر
              </span>
            </div>

            <form
              onSubmit={(e) => e.preventDefault()}
              className="flex flex-col gap-4"
            >
              <div className=" overflow-auto max-h-96 pe-1">
                <div className="flex  mb-2 px-2 gap-3 items-center sticky top-0 bg-white shadow-sm pb-3 ">
                  <p className="w-2/3">العناصر</p>
                  <p className="w-28">الوزن الحالي</p>
                  <p className="w-28">الوزن الجديد</p>
                  <p className="w-10">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.09 7.414A9.143 9.143 0 1 1 2.856 12"
                        stroke="#292069"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.571 7.428H4V2.857"
                        stroke="#292069"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </p>
                </div>
                {AXIS.map((axis) => (
                  <div
                    key={axis.id}
                    className="flex border rounded-lg mb-2 px-2 py-1 gap-3 items-center "
                  >
                    <p className="w-2/3">{axis.axisName}</p>
                    <p className="w-28 text-center text-[#868686]">
                      {axis.currentWeight}
                    </p>
                    <input
                      type="number"
                      placeholder="الوزن الجديد"
                      className="w-24 text-center leading-6 text-sm border-none rounded-lg bg-[#F9F9F9] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none "
                    />
                    <button className="px-3">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.09 7.414A9.143 9.143 0 1 1 2.856 12"
                          stroke="#292069"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.571 7.428H4V2.857"
                          stroke="#292069"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </form>

            <div className="flex gap-3 justify-center w-full items-center">
              <button
                type="submit"
                className="w-full p-2.5 pb-3.5 border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.06%)] text-white font-semibold text-base leading-6 hover:bg-[linear-gradient(90deg,#292069_7.39%,#4B3CB7_104.08%)]"
              >
                محاكاة
              </button>
              <button
                type="button"
                onClick={toggleModal}
                className="w-full p-2.5 pb-3.5 border border-blue rounded-lg shadow-[0px_1px_2px_0px_#1018280D] bg-white text-blue font-semibold text-base leading-6 hover:bg-blue hover:text-white"
              >
                الغاء
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AxisWeights;
