import { useState } from "react";
import { Table } from "flowbite-react";
import DashboardTable from "../../components/DashboardTable/DashboardTable";
import DashboardTabs from "../../components/DashboardTabs/DashboardTabs";
import DoughnutChart from "../../components/charts/DoughnutChart/DoughnutChart";
import BarChart from "../../components/charts/BarChart/BarChart";
import LineChart from "../../components/charts/LineChart/LineChart";
import { useQuery } from "@tanstack/react-query";
import TableSkeleton from "../../../../components/table/TableSkeleton";
import TableSkeletonBody from "../../../../components/table/TableSkeletonBody";

const RESULTS = {
  result: [
    {
      id: 1,
      agencyName: "وزارة العدل",
      finalResult: 92.37,
      difference: 16.98,
      stage: "الايداع",
    },
    {
      id: 2,
      agencyName: "وزارة الموارد البشرية والتنمية الاجتماعية",
      finalResult: 89.77,
      difference: 10.32,
      stage: "الايداع",
    },
    {
      id: 3,
      agencyName: "هيئة الاتصالات والفضاء والتقنيه",
      finalResult: 92.37,
      difference: 16.98,
      stage: "التكامل",
    },
    {
      id: 4,
      agencyName: "جامعة القصيم",
      finalResult: 92.37,
      difference: 16.98,
      stage: "التكامل",
    },
    {
      id: 5,
      agencyName: "جامعة القصيم",
      finalResult: 92.37,
      difference: 16.98,
      stage: "الإتاحة",
    },
    {
      id: 6,
      agencyName: "جامعة القصيم",
      finalResult: 92.37,
      difference: 16.98,
      stage: "البناء",
    },

    {
      id: 1,
      agencyName: "وزارة العدل",
      finalResult: 92.37,
      difference: 16.98,
      stage: "الايداع",
    },
    {
      id: 2,
      agencyName: "وزارة الموارد البشرية والتنمية الاجتماعية",
      finalResult: 89.77,
      difference: 10.32,
      stage: "الايداع",
    },
    {
      id: 3,
      agencyName: "هيئة الاتصالات والفضاء والتقنيه",
      finalResult: 92.37,
      difference: 16.98,
      stage: "التكامل",
    },
    {
      id: 4,
      agencyName: "جامعة القصيم",
      finalResult: 92.37,
      difference: 16.98,
      stage: "التكامل",
    },
    {
      id: 5,
      agencyName: "جامعة القصيم",
      finalResult: 92.37,
      difference: 16.98,
      stage: "الإتاحة",
    },
    {
      id: 6,
      agencyName: "جامعة القصيم",
      finalResult: 92.37,
      difference: 16.98,
      stage: "البناء",
    },
  ],
  targetUrl: null,
  success: true,
  error: null,
  unAuthorizedRequest: false,
  __abp: true,
};

const HEADERS = ["اسم الجهة", "النتيجة", "الفرق", "المرحلة"];

const TABS = [
  {
    id: 1,
    label: "القطاعات",
    value: "sectors",
  },
  {
    id: 2,
    label: "الجهات",
    value: "agencies",
  },
];

const resultsTypes = {
  deposit:
    "border-2 border-[#11A186] text-[#11A186] bg-[#E0FFF3] rounded-3xl pt-0.5 px-4 pb-1.5 flex items-center justify-center",
  integration:
    "border-2 border-[#00ABAF] text-[#00ABAF] bg-[#DAFEFF] rounded-3xl pt-0.5 px-4 pb-1.5 flex items-center justify-center",
  evaluation:
    "border-2 border-[#FFA92B] text-[#FFA92B] bg-[#FFF2E0] rounded-3xl pt-0.5 px-4 pb-1.5 flex items-center justify-center",
  availability:
    "border-2 border-[#FF8658] text-[#FF8658] bg-[#FFE6DD] rounded-3xl pt-0.5 px-4 pb-1.5 flex items-center justify-center",
  building:
    "border-2 border-[#B40000] text-[#B40000] bg-[#FFD6D6] rounded-3xl pt-0.5 px-4 pb-1.5 flex items-center justify-center",
};

const Results = ({ year }) => {
  const [data, setData] = useState(RESULTS.result);
  const [tabs, setTabs] = useState(TABS);
  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const [showDetails, setShowDetails] = useState(null);

  // pagination state
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(3);

  const {
    data: beData = {
      result: [],
    },
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["finalResults", page],
    queryFn: async () => {
      const searchParams = new URLSearchParams();
      searchParams.append("surveyId", 198);
      searchParams.append("stageId", 570);
      searchParams.append("SkipCount", (page - 1) * perPage);
      searchParams.append("MaxResultCount", 10);

      const res = await fetch(
        `${process.env.REACT_APP_BI_URL}DashboardStatistics/GetAgenciesCurrentResults?` +
          searchParams.toString()
      );
      return await res.json();
    },
  });

  const totalPages = Math.ceil(beData?.result?.totalCount / perPage);
  function handleNextPage() {
    // set limit
    const nextPageNumber = page + 1;
    setPage(nextPageNumber);
  }

  function handlePreviousPage() {
    // set limit
    const previousPageNumber = page - 1;
    setPage(previousPageNumber);
  }

  // if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;

  const getClassName = (stage) => {
    switch (stage) {
      case "الايداع":
        return resultsTypes.deposit;
      case "التكامل":
        return resultsTypes.integration;
      case "التحسين":
        return resultsTypes.evaluation;
      case "الإتاحة":
        return resultsTypes.availability;
      case "البناء":
        return resultsTypes.building;
      default:
        return "";
    }
  };

  const handleDetails = (result) => {
    setShowDetails(result.id);
  };

  return (
    <div className="">
      <div className="flex flex-col gap-6 xl:flex-row">
        <div className="flex-auto">
          <DashboardTable
            data={data}
            headers={HEADERS}
            tableBody={
              isLoading ? (
                <Table.Row>
                  <Table.Cell colSpan={4} className="p-0">
                    <TableSkeletonBody />
                  </Table.Cell>
                </Table.Row>
              ) : (
                beData?.result?.items?.map((result, index) => (
                  <Table.Row
                    className={`cursor-pointer ${
                      showDetails === result.id
                        ? "bg-[#F2F1FF] hover:bg-[#F2F1FF]"
                        : ""
                    }`}
                    key={result.id}
                    onClick={() => handleDetails(result)}
                  >
                    <Table.Cell>{data[index].agencyName}</Table.Cell>
                    <Table.Cell className="text-center">
                      {data[index].finalResult}
                    </Table.Cell>
                    <Table.Cell className="text-center">
                      {data[index].difference}
                    </Table.Cell>
                    <Table.Cell
                      className={`text-center flex justify-center items-center`}
                    >
                      <span
                        className={`${getClassName(data[index].stage)} w-fit`}
                      >
                        {data[index].stage}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                ))
              )
            }
            tablePagination={
              <div className="flex items-center justify-between py-3 px-6 w-full mt-auto">
                <span className="text-[#344054] font-semibold text-lg leading-5 pb-2">
                  {`${page} من ${totalPages}`}
                </span>
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    disabled={totalPages === page ? true : false}
                    className={`${
                      totalPages === page
                        ? "pointer-events-none"
                        : "pointer-events-auto"
                    } w-[100px] bg-blue text-white rounded-lg border border-blue flex justify-center items-center font-semibold text-sm leading-5 pb-2 h-10 hover:bg-blue_hover`}
                    onClick={handleNextPage}
                  >
                    التالى
                  </button>
                  <button
                    type="button"
                    disabled={page === 1 ? true : false}
                    className={`${
                      page === 1 ? "pointer-events-none" : "pointer-events-auto"
                    } w-[100px] bg-blue text-white rounded-lg border border-blue flex justify-center items-center font-semibold text-sm leading-5 pb-2 h-10 hover:bg-blue_hover`}
                    onClick={handlePreviousPage}
                  >
                    السابق
                  </button>
                </div>
              </div>
            }
          >
            <header className="py-5 px-6 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h2 className="text-blue dark:text-gray-400 text-lg font-semibold pb-2">
                  النتائج حسب الجهة لعام {year}
                </h2>
                <div className="pt-0.5 px-2 text-xs leading-[18px] font-medium flex justify-center items-center border border-bi_purple_light text-bi_purple rounded-2xl bg-[#F9F5FF]">
                  <span className="inline-block pb-1">{data.length} نتيجة</span>
                </div>
              </div>
              <DashboardTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </header>
          </DashboardTable>
        </div>
        <div className=" flex-grow flex flex-col gap-6 justify-start">
          <div className="flex flex-col gap-4 shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A] border border-[#EAECF0] rounded-xl px-6 py-4">
            <div className="flex justify-between items-center">
              <h3 className="pb-2 text-blue font-semibold leading-7 text-base">
                مؤشر التحول الرقمي للجهة المحددة
              </h3>
              <button
                type="button"
                className="w-5 h-5 flex justify-center items-center"
                onClick={() => setShowDetails(null)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full h-full fill-[#667085]"
                  viewBox="0 0 384 512"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </div>
            <div className="h-[152px] w-full flex justify-center">
              <DoughnutChart />
            </div>
            <div className="flex-1 flex justify-between items-end">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center justify-between">
                  <span className={resultsTypes.deposit}>الايداع</span>
                  <span className="pb-1">20%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className={resultsTypes.integration}>التكامل</span>
                  <span className="pb-1">30%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className={resultsTypes.evaluation}>التحسين</span>
                  <span className="pb-1">20%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className={resultsTypes.availability}>الإتاحة</span>
                  <span className="pb-1">15%</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className={resultsTypes.building}>البناء</span>
                  <span className="pb-1">15%</span>
                </div>
              </div>
            </div>
          </div>
          <div className=" h-12">
            <div className="border-gray-300 shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A] rounded-xl py-6 px-8">
                  <h3 className="text-blue font-semibold text-lg leading-7 pb-2">
                    الناتج حسب السنوات
                  </h3>
                  <div className="justify-center items-center flex max-h-[300px]">
                    <div className="">
                    <LineChart />
                      </div>  
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;

