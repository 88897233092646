import React, { useContext, useEffect, useState } from "react";
import {
  axiosGetRequest,
  axiosPostRequest,
  axiosPutRequest,
  post,
} from "../../../../../services/Request";
import environment from "../../../../../services/environment";
import { successToast } from "../../../../../helpers/toast";
import Inquiries from "../../../../../components/Inquiries";
import { useParams } from "react-router-dom";
import { StandardProgramContext } from "../../../../../contexts/StandardProgramContext";
import { useGetInquiryList } from "./useGetInquiryList";
import AppLoader from "../../../../../components/loader";

const StandardInquiry = ({
  loading,
  setLoading,
  inquiries,
  setInquiries,
  standard,
  setStandard,
  selectedStatusInquiry,
  setSelectedStatusInquiry,
}) => {
  const { getStandardInquiryList } = useGetInquiryList(
    selectedStatusInquiry,
    setInquiries,
    setStandard,
    setLoading
  );
  const [addLoading, setAddLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [selectedFileComment, setSelectedFileComment] = useState("");
  const statusesInquiry = [
    { id: 1, label: "اختر حالة", value: null },
    { id: 2, label: "تجاوز مستوي الخدمة", value: 3 },
    { id: 3, label: "تحت الاجراء ", value: 1 },
    { id: 2, label: "جديد", value: 4 },
    { id: 3, label: "مغلق ", value: 2 },
  ];

  const changeStatusInquiry = async (inquiryId, status) => {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //   },
    // };
    let data = {
      id: inquiryId,
      ticketStatus: status,
    };
    // console.log(status  , "🤣🤣🤣🤣🤣🤣🤣")
    const res = await axiosPutRequest(environment.updateTicketStatus, data);
    if (res?.success) {
      successToast("تم تغيير حالة الاستفسار بنجاح");
      getStandardInquiryList();
    }
    // put(environment.updateInquiryStatus, data, config, (res) => {
    //   setMessage(
    //     { type: "success", message: t("تم تغيير حالة الاستفسار بنجاح") },
    //     setOpenConfirmModal(
    //       true,
    //       setOpenQuestionModel(false, getStandardInquiryList())
    //     )
    //   );
    // });
  };

  const getCommentInquiry = (value) => {
    setComment(value);
  };
  const onFileUploadComment = (inquiryId) => {
    if (!selectedFileComment) return;
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const formData = new FormData();
    formData.append("Name", selectedFileComment.name);
    formData.append("FileType", "image/*");
    formData.append("EntityType", "");
    formData.append("File", selectedFileComment);
    post(environment.attachmentFile, formData, config, (res) => {
      setAddLoading(false);
      addComment(inquiryId, res.data.result.id);
    });
  };

  const addComment = async (inquiryId, attachmentId) => {
    let data = {
      comment: comment,
      attachmentId: attachmentId,
      ticketId: inquiryId,
    };
    // return true

    const res = await axiosPostRequest(environment.replyOnTicket, data);
    if (res?.success) {
      setComment("");
      setSelectedImage("");
      getStandardInquiryList();
    }
  };

  const getSelectedStatusInquiry = (value) => {
    setSelectedStatusInquiry(value);
  };

  const getSelectedFile = (file) => {
    setSelectedFileComment(file);
  };

  useEffect(() => {
    getStandardInquiryList();
  }, []);

  return (
    <div className="bg-[#F9F9FF] flex flex-row items-center justify-around mr-3 rounded-xl p-3">
      <div className="w-[100%]">
        {console.log(loading)}
        {loading ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <AppLoader />
          </div>
        ) : (
          <Inquiries
            statusesInquiry={statusesInquiry}
            changeStatusInquiry={changeStatusInquiry}
            addComment={!selectedFileComment ? addComment : onFileUploadComment}
            getComment={getCommentInquiry}
            loading={false}
            getSelectedStatus={getSelectedStatusInquiry}
            getStandardInquiryList={getStandardInquiryList}
            inquiries={inquiries}
            code={standard?.code}
            getSelectedFile={getSelectedFile}
          />
        )}
      </div>
    </div>
  );
};

export default StandardInquiry;
