import "./App.css";
import { routes } from "./routes/Routes";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Home, Login } from "./routes/Index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RaqmiPage from "./modules/main/home/RaqmiPage";
import { GlobalProvider } from "./contexts/globalContext";
import BILayout from "./modules/bi/pages/Layout/Layout";
import LoginWithNafath from "./modules/auth/LoginWithNafath";
import HelloToQiyas from "./modules/main/home/HelloToQiyas";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import IndicatorAfterLogin from "./modules/main/home/IndicatorAfterLogin";
import NoSurveyFound from "./routes/NoSurveyFound";
import ProtectedRoute from "./routes/ProtectedRoute ";
import { useEffect } from "react";
import * as signalR from "@microsoft/signalr";

const signalRConnect = async  () => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_URL}/dashboardReportHub`, signalR.HttpTransportType.LongPolling)
    .withAutomaticReconnect()
    .build();

   connection.start().then((res1) => {
    localStorage.setItem("connectionId", connection.connectionId);
    connection.on("ReceivingAnswerReports", (message) => {
      document.dispatchEvent(new CustomEvent("reportReady", { detail: message }));
    });
  });

  return connection;
};


function App() {
  const location = useLocation();
  useEffect(() => {
    const connection = signalRConnect();
    return () => {
      if (connection && typeof connection.stop === "function") {
        connection.stop();
      }
    };
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <GlobalProvider>
          <div className="flex w-[100%]">
            {location.pathname !== "/login" &&
            localStorage.getItem("accessToken") != null &&
            localStorage.getItem("surveyId") != null &&
            location.pathname !== "/ChooseCurruntIndicator" &&
            location.pathname !==
              `/survey/${localStorage.getItem("surveyId")}/polices/accept` &&
            location.pathname !== "/hello" &&
            location.pathname !== "/notFound" &&
            location.pathname !== "/NoSurveyFound" &&
            location.pathname !== "/login" ? (
              <>
              <Home />
              </>
            ) : null}
            <div
              className={
                location.pathname !== "/login" &&
                localStorage.getItem("accessToken") != null
                  ? location.pathname === "/ChooseCurruntIndicator" ||
                    location.pathname ===
                      `/survey/${localStorage.getItem(
                        "surveyId"
                      )}/polices/accept` ||
                    location.pathname === "/hello" ||
                    location.pathname === "/notFound" ||
                    location.pathname === "/qyias/auth/admin"
                    ? "w-[100%] h-[100vh] overflow-y-scroll"
                    : "w-[100%] h-[100vh] overflow-y-scroll bg-[#292069]"
                  : "w-[100%]"
              }
            >
              {!localStorage.getItem("accessToken") ? (
                <Switch>  
                  <Route path="/bi" component={BILayout} />
                  <Route path="/hello" component={LoginWithNafath} />
                  <Route path="/Nafath" component={RaqmiPage} />
                  <Route path="/8G7kJ2fL1pQxR9sD0wZ4bC6tN5vM8uY" component={Login} />
                  <Redirect to={"/hello"} />
                </Switch>
              ) : !localStorage.getItem("surveyId") ? (
                <Switch>
                  <Route
                    path="/ChooseCurruntIndicator"
                    component={IndicatorAfterLogin}
                  />
                  <Route path="/NoSurveyFound" component={NoSurveyFound} />

                  <Redirect to={"/NoSurveyFound"} />
                </Switch>
              ) : (
                <Switch>
                  {routes.map((route, index) => (
                    <ProtectedRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                  <Redirect to={"/"} />
                </Switch>
              )}
            </div>
          </div>
        </GlobalProvider>
        <ToastContainer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
