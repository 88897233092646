import { useContext } from "react";
import { StageContext } from "../../../../../../../contexts/stageContext";
import {
  axiosGetRequest,
  axiosPostRequest,
} from "../../../../../../../services/Request";
import environment from "../../../../../../../services/environment";
import { noAnswerDegreeId, useAnswerContext } from "../../answerContext";
import { isAdmin, isInquiryManager, isPocOfficer } from "../../../../../../../helpers/isPermission";

const usePrevAgencyAnswer = () => {
  const {
    setLoadPrevAgencyAnswer,
    setNewAgencyAnswer,
    agencyDegree,
    setSelfEvaluation,
    getSelfEvaluation,
    newAgencyAnswer,
    newExpertAnswer,
    newExpertManagerAnswer,
    newReviewerAnswer,
    newPocOfficerAnswer,
    newAdmin,
  } = useAnswerContext();
  const { currentStage, stageForDashboard } = useContext(StageContext);
  const surveyStageId = currentStage?.id;
  const phaseOneStageId = currentStage?.stageIds?.[0];
  
  // HACK: Work Around for POC Officer
  const currentStageId = (isPocOfficer) ? phaseOneStageId : stageForDashboard || surveyStageId || 0; 

  const getPrevAgencyAnswer = async (standardId, entityId, stageId) => {
    setLoadPrevAgencyAnswer(true);
    const res = await axiosPostRequest(environment.prepareStandardForAnswer, {
      StandardId: standardId,
      AgencyId: entityId,
      StageId: currentStageId,
    });

    //  check if current stage third stage or not
    let firstStageAnswerRes = null;
    if (currentStage.stageIds && currentStage.stageIds[2] === currentStage.id){
      firstStageAnswerRes = await axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage.stageIds[0].toString(),
        }
      );
    }

    newAgencyAnswer.agencyName =
      res?.result?.agencyName == ""
        ? "الجهة الحكومية"
        : res?.result?.agencyName;
    newExpertAnswer.agencyName =
      res?.result?.agencyName == ""
        ? "الجهة الحكومية"
        : res?.result?.agencyName;
    newExpertManagerAnswer.agencyName =
      res?.result?.agencyName == ""
        ? "الجهة الحكومية"
        : res?.result?.agencyName;
    newPocOfficerAnswer.agencyName =
      res?.result?.agencyName == ""
        ? "الجهة الحكومية"
        : res?.result?.agencyName;
    newReviewerAnswer.agencyName =
      res?.result?.agencyName == ""
        ? "الجهة الحكومية"
        : res?.result?.agencyName;
    newAdmin.agencyName =
      res?.result?.agencyName == ""
        ? "الجهة الحكومية"
        : res?.result?.agencyName;

    newAdmin.interlocutorId = res?.result?.interlocutorId;
    newAdmin.perspectiveId = res?.result?.perspectiveId;

    if (res?.result) {
      // * Add Agency Prev checked Requirements to (newAgencyAnswer)
      const agencyRequirementsAnswer = res?.result?.standardRequirements?.map(
        (requirement) => {
          const reqAnswer =
            res?.result?.surveyAgencyAnswer?.agencyRequirementAnswers?.find(
              (reqAns) => reqAns.standardRequirementId === requirement?.id
            );

          if (reqAnswer?.agencySubRequirementAnswers) {
            const { agencySubRequirementAnswers, ...reqs } = reqAnswer;

            const subRequirements = requirement?.subStandardRequirements?.map(
              (subReq) => {
                const sub = agencySubRequirementAnswers?.find(
                  (item) => subReq?.id === item?.subStandardRequirementId
                );

                return {
                  ...subReq,
                  ...sub,
                  id: subReq.id,
                  textFields: JSON.parse(sub?.textFields)
                };
              }
            );

            return {
              ...requirement,
              ...reqs,
              id: requirement.id,
              recommendation: "",
              subStandardRequirements: [...subRequirements],
            };
          }

          return {
            ...requirement,
          };
        }
      );

      // * Add Agency Prev Uploaded files to (newAgencyAnswer)
      const agencyPrevUploadedFilesAnswer = res?.result?.standardProofs?.map(
        (proofFile) => {
          const proofAnswer =
            res?.result?.surveyAgencyAnswer?.agencyProofAnswers?.find(
              (proofAns) => proofAns.standardProofId === proofFile?.id
            );

          console.log(proofAnswer, "proofAnswer proofAnswer 👍");

          return {
            ...proofFile,
            ...proofAnswer,
            pastSelectedFiles: [],
          };
        }
      );

      let degreeValues = [];
      const degreesResult = await axiosGetRequest(
        environment.getCommitmentLevels,
        {
          standardId,
          StageId: localStorage.getItem("surveyStageId"),
        }
      );

      if (degreesResult?.success) {
        degreeValues = degreesResult?.result
          ?.filter((item) => item?.id !== noAnswerDegreeId)
          ?.map((degree) => ({
            ...degree,
            label: degree.title,
            value: degree.id,
          }));
      }

      const surveyDegrees = [...degreeValues];
      const noCommitmentDegree =
        surveyDegrees?.filter((item) => item?.title == "عدم التزام")?.[0] || "";

      const donotApplyDegree =
        surveyDegrees?.filter((item) => item?.title == "لا ينطبق")?.[0] || "";

      const checkedAgencyDegree =
        surveyDegrees?.filter(
          (item) =>
            item?.id ==
            res?.result?.surveyAgencyAnswer?.finalCommitmentLevel?.id
        )?.[0] || null;

      const updatedAnswer = {
        ...res?.result,
        standardRequirements:
          agencyRequirementsAnswer || res?.result?.standardRequirements,

        standardProofs:
          agencyPrevUploadedFilesAnswer || res?.result?.standardProofs,

        surveyDegrees,
        checkedAgencyDegree,
        noCommitmentDegree,
        donotApplyDegree,

        selfEvaluationCommitmentLevel:
          res?.result?.surveyAgencyAnswer?.selfEvaluationCommitmentLevel,
        selfEvaluationDegree:
          res?.result?.surveyAgencyAnswer?.selfEvaluationDegree,
        agencyDonotApply: res?.result?.surveyAgencyAnswer?.donotApply,
        firstStageAnswer: firstStageAnswerRes?.result?.surveyAgencyAnswer,
      };

      setNewAgencyAnswer(updatedAnswer);
      setSelfEvaluation({
        ...agencyDegree,
        selfEvaluationCommitmentLevel:
          res?.result?.surveyAgencyAnswer?.selfEvaluationCommitmentLevel,
        selfEvaluationDegree:
          res?.result?.surveyAgencyAnswer?.selfEvaluationDegree,
      });
      setLoadPrevAgencyAnswer(false);

      return updatedAnswer;
    }
    setLoadPrevAgencyAnswer(false);
  };



  return { getPrevAgencyAnswer };
};

export default usePrevAgencyAnswer;
