import {pen, removeIcon} from "../../../../assets/icons/Index";
import React, {useEffect, useRef, useState} from "react";
import CModal from "../../../../components/CModal";
import {useTranslation} from "react-i18next";
import SModal from "../../../../components/SModal";
import FModal from "../../../../components/FModal";
import InputLabel from "../../../../components/InputLabel";
import {get, post, put, remove} from "../../../../services/Request";
import environment from "../../../../services/environment";
import useText from "../../../../helpers/customHooks/useText";
import DropdownMultiSelect from "../../../../components/DropdownMultiSelect";
import {Formik} from "formik";
import i18n from "../../../../languages/i18n";
import {Lang} from "../../../../helpers/utils";
import ReactSwitch from "react-switch";
import NewTooltip from "../../../../components/NewTooltip";
import {toast} from "react-toastify";
import NewDropdownMultiSelect from "../../../../components/NewDropDownMultiSelect";
import {permissionsCheck} from "./../../../../helpers/utils";

let ar = {
  number: "الرقم التسلسلي",
  name: "الهوية",
  permission: "الدور",
  staticRoleName: "الصلاحية الاساسية",
  edit: "تعديل",
  delete: "حذف",
  status: "الحالة",
};
let en = {
  number: "Number",
  name: "Identity",
  permission: "Role",
  staticRoleName: "Static role",
  edit: "Edit",
  delete: "Delete",
  status: "Status",
};
const List = [
  {
    name: i18n.language == Lang.AR ? ar.number : en.number,
    selector: (row) => row.id,
    header: i18n.language == Lang.AR ? ar.number : en.number,
    accessorKey: "number",
    footer: (props) => props.column.id,
    sortable: true,
  },
  {
    name: i18n.language == Lang.AR ? ar.name : en.name,
    selector: (row) => row.fullName,
    header: i18n.language == Lang.AR ? ar.name : en.name,
    accessorKey: "identity",
    footer: (props) => props.column.id,
    cell: (info) => (
      <div className="flex flex-row justify-between items-center w-[100%]">
        {info.identityNumber}
      </div>
    ),
    sortable: true,
  },
  {
    name: i18n.language == Lang.AR ? ar.staticRoleName : en.staticRoleName,
    selector: (row) => row.staticRoleName,
    header: i18n.language == Lang.AR ? ar.staticRoleName : en.staticRoleName,
    accessorKey: "staticRoleName",
    footer: (props) => props.column.id,
    cell: (info) => (
      <div className="flex flex-row justify-between items-center w-[100%]">
        {info.staticRoleName}
      </div>
    ),
    sortable: true,
  },
  // {
  //   name: i18n.language == Lang.AR ? ar.permission : en.permission,
  //   selector: (row) => row.identityRoles,
  //   header: i18n.language == Lang.AR ? ar.permission : en.permission,
  //   accessorKey: "permission",
  //   footer: (props) => props.column.id,
  //   cell: (info) => (
  //     <div className="grid grid-cols-2">
  //       {" "}
  //       {info.identityRoles.map((item, index) => (
  //         <div className="flex p-1 bg-[#1F925433] justify-center items-center rounded m-1">
  //           <span key={index} className="text-[10px] text-[#1F9254]">
  //             {item.roleName}
  //           </span>
  //         </div>
  //       ))}
  //     </div>
  //   ),
  //   width: "250px",
  // },
  {
    name: i18n.language == Lang.AR ? ar.edit : en.edit,
    header: i18n.language == Lang.AR ? ar.edit : en.edit,
    accessorFn: (row) => row.status,
    footer: (props) => props.column.id,
    cell: (info) => <EditAction user={info} />,
  },
  {
    name: i18n.language == Lang.AR ? ar.delete : en.delete,
    header: i18n.language == Lang.AR ? ar.delete : en.delete,
    accessorFn: (row) => row.status,
    footer: (props) => props.column.id,
    cell: (info) => <DeleteAction user={info} />,
  },
  //   {
  //     name: i18n.language == Lang.AR ? ar.status : en.status,
  //     header: i18n.language == Lang.AR ? ar.status : en.status,
  //     accessorFn: (row) => row.status,
  //     footer: (props) => props.column.id,
  //     cell: (info) => <StatusAction user={info} />,
  //   },
];

function EditAction({ user }) {
  const [values, handleChange] = useText(user);
  const { t, i18n } = useTranslation();
  const [openConfirmModal, setOpenConfirmModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [entity, setEntity] = useState(null);
  const [rolesArr, setRolesArr] = useState([]);
  const [experts, setExperts] = useState([]);

  const [selectedFile, setSelectedFile] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [message, setMessage] = useState({});
  const [roleStatic, setRoleStatic] = useState(null);
  const formIdentityRef = useRef(null);

  const handleSubmitEditUser = () => {
    if (formIdentityRef.current) {
      formIdentityRef.current?.handleSubmit();
    }
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    user.identityRoles.forEach((item, index) => {
      let obj = { label: item.roleName, value: item.id };
      rolesArr.push(obj);
    });
    setRole(rolesArr);
    setEntity({ label: user.agencyTitle, value: user.agencyId });
    setRoleStatic({ label: user.staticRoleName, value: user.staticRoleId });
  }, []);
  const openConfirmationModal = () => {
    setOpenConfirmModal("popup-modal");
  };

  const [roles, setRoles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [rolesStatic, setRolesStatic] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState([]);

  const handleChangeExperts = (value) => {
    setSelectedExperts(value);
  };
  const handleChangeRoles = (value) => {
    setRole(value);
  };

  const handleChangeEntities = (value) => {
    setEntity(value);
  };

  const getAllRolesStatic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllRoles + "?isStatic=" + true, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        let newArr = [];
        res.data.result.items.forEach((role) => {
          arr.push({ id: role.id, label: role.name, value: role.id });
        });
        newArr = arr.filter(
          (item, index) => item.id != 40 && item.id != 39 && item.id != 41
        );
        console.log(arr);
        setRolesStatic(newArr, getAllAgency());
      }
    });
  };

  const handleChangeRoleStatic = (value) => {
    setRoleStatic(value);
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllRoles + "?isStatic=" + false, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((role) => {
          arr.push({ label: role.name, value: role.id });
        });
        setRoles(arr, getAllRolesStatic());
      }
    });
  };

  const getAllAgency = () => {
    setLoading(100);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.forEach((agency) => {
          arr.push({ label: agency.title, value: agency.id });
        });
        setEntities(arr, getAllExperts());
      }
    });
  };
  const getAllExperts = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    get(environment.getAllExpertLookUp, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((user) => {
          arr.push({ id: user.id, label: user.fullName, value: user.name });
        });
        setExperts(arr, setLoading(false));
      }
    });
  };
  const editIdentity = (values, profilePictureId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let roleArr = [];
    role.forEach((role, index) => {
      roleArr.push({ roleId: role.value });
    });
    role.push({ label: roleStatic.value, value: roleStatic.value });
    let newExperts = [];
    if (entity.value == "") {
      selectedExperts.forEach((expert) => {
        newExperts.push(expert.id);
      });
    }
    let newEntity = {
      id: entity.value,
      title: entity.label,
      titleArabic: entity.label,
    };
    let data = {
      id: user.id,
      identityNumber:
        values.identityNumber == null
          ? user.identityNumber
          : values.identityNumber,
      staticRoleId: roleStatic == null ? user.staticRoleId : roleStatic.id,
      // identityRoles:role == null ? role : role.map((roletest, index) => roletest.value)
    };

    put(environment.editIdentity, data, config, (res) => {
      toast.success(t("modules.main.users.users.messages.editSuccess"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.location.reload(false);
    });
  };

  const onChangeFile = (event) => {
    setSelectedFile(
      event.target.files[0],
      setImageUrl(URL.createObjectURL(event.target.files[0]))
    );
  };

  return (
    <>
      <div className="flex justify-center items-center space-x-2">
        <div></div>
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <FModal
          content={
            <div>
              <Formik
                innerRef={formIdentityRef}
                initialValues={{
                  identityNumber: user.identityNumber,
                  identityRoles: role,
                  staticRole: user.staticRoleId,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.identityNumber) {
                    errors.identityNumber = t("رقم الهوية مطلوب");
                  }

                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  editIdentity(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <div>
                    <InputLabel
                      value={values.identityNumber}
                      onChange={handleChange}
                      name="identityNumber"
                      type={"text"}
                      label={t("رقم الهوية")}
                      placeholder={t("ادخل رقم الهوية")}
                      style={{
                        borderColor: errors.identityNumber
                          ? "red"
                          : values.identityNumber != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.identityNumber &&
                        touched.identityNumber &&
                        errors.identityNumber}
                    </div>
                    {/* <InputLabel
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      type={"text"}
                      label={t("modules.main.users.users.form.name")}
                      placeholder={t(
                        "modules.main.users.users.placeholder.name"
                      )}
                      style={{
                        borderColor: errors.name
                          ? "red"
                          : values.name != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.name && touched.name && errors.name}
                    </div>
                    <InputLabel
                      value={values.surname}
                      onChange={handleChange}
                      name="surname"
                      type={"text"}
                      label={t("modules.main.users.users.form.surname")}
                      placeholder={t(
                        "modules.main.users.users.placeholder.surname"
                      )}
                      style={{
                        borderColor: errors.surname
                          ? "red"
                          : values.surname != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.surname && touched.surname && errors.surname}
                    </div>
                    <InputLabel
                      disabled={true}
                      style={{
                        borderColor: errors.emailAddress
                          ? "red"
                          : values.emailAddress != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                      value={values.emailAddress}
                      onChange={handleChange}
                      name="emailAddress"
                      type={"email"}
                      label={t("modules.main.users.users.form.email")}
                      placeholder={t(
                        "modules.main.users.users.placeholder.email"
                      )}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.emailAddress &&
                        touched.emailAddress &&
                        errors.emailAddress}
                    </div> */}

                    {/* <NewDropdownMultiSelect
                      isSearchable={true}
                      value={role}
                      handleChange={handleChangeRoles}
                      placeholder={t(
                        "modules.main.users.users.placeholder.permission"
                      )}
                      label={t("modules.main.users.users.form.permissions")}
                      options={roles}
                      isMultiple={true}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.roleNames &&
                        touched.roleNames &&
                        errors.roleNames}
                    </div> */}
                    <DropdownMultiSelect
                      isSearchable={true}
                      value={roleStatic}
                      handleChange={handleChangeRoleStatic}
                      placeholder={t(
                        "modules.main.users.users.placeholder.permissionStatic"
                      )}
                      label={t(
                        "modules.main.users.users.form.permissionsStatic"
                      )}
                      options={rolesStatic}
                      isMultiple={false}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.roleNames &&
                        touched.roleNames &&
                        errors.roleNames}
                    </div>
                    {roleStatic?.value == "POC" ||
                    roleStatic?.value == "CIO" ||
                    roleStatic?.value == "Delegate" ? (
                      <div>
                        <DropdownMultiSelect
                          isSearchable={true}
                          value={entity}
                          handleChange={handleChangeEntities}
                          placeholder={t(
                            "modules.main.users.users.placeholder.entity"
                          )}
                          label={t("modules.main.users.users.form.entity")}
                          options={entities}
                          isMultiple={false}
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {errors.agencyId &&
                            touched.agencyId &&
                            errors.agencyId}
                        </div>
                      </div>
                    ) : null}

                    {roleStatic?.value == "Reviewer" ? (
                      <div>
                        <NewDropdownMultiSelect
                          label={"الخبراء"}
                          placeholder={"اختر خبير"}
                          options={experts}
                          handleChange={handleChangeExperts}
                          value={selectedExperts}
                          isMultiple={true}
                        />
                        <div className="text-sm text-red-600 mt-3">
                          {errors.users && touched.users && errors.users}
                        </div>
                      </div>
                    ) : null}

                    {/* <InputLabel
                      style={{
                        borderColor: errors.mobile
                          ? "red"
                          : values.mobile != ""
                          ? "#22bb33"
                          : "transparent",
                      }}
                      value={values.mobile}
                      onChange={handleChange}
                      type={"text"}
                      name="mobile"
                      label={t("modules.main.users.users.form.phone")}
                      placeholder={t(
                        "modules.main.users.users.placeholder.phone"
                      )}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.mobile && touched.mobile && errors.mobile}
                    </div> */}
                  </div>
                )}
              </Formik>
            </div>
          }
          open={open}
          titleButton={t("تعديل")}
          setOpen={() => setOpen(false)}
          type={"submit"}
          action={handleSubmitEditUser}
          headerTitle={t("تعديل الهوية")}
        />
        {permissionsCheck("Pages.Identities.Edit") ? (
          <NewTooltip content={"تعديل"}>
            <div
              onClick={() => setOpen(true)}
              className="rounded-full bg-[#FFF6DC] p-3 cursor-pointer"
            >
              <img src={pen} />
            </div>
          </NewTooltip>
        ) : null}
      </div>
    </>
  );
}

function DeleteAction({ user }) {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const removeUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    remove(environment.deleteIdentity + "?Id=" + user.id, config, (res) => {
      toast.success("تم حذف الهوية بنجاح", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.location.reload(false);
    });
  };
  return (
    <>
      <div className="flex justify-center items-center space-x-2">
        <div></div>
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <CModal
          confirmModal={() => removeUser()}
          open={openDelete}
          setOpen={() => setOpenDelete(false)}
          subTitle={`${t("modules.main.users.users.messages.confirmMessage") +
            " " +
            user.identityNumber}!`}
        />
        {permissionsCheck("Pages.Identities.Delete") ? (
          <NewTooltip content={"حذف"}>
            <div
              onClick={() => setOpenDelete(true)}
              className="rounded-full bg-[#FEEDEF] p-3 cursor-pointer"
            >
              <img src={removeIcon} />
            </div>
          </NewTooltip>
        ) : null}
      </div>
    </>
  );
}

function StatusAction({ user }) {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState({});
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [checked, setChecked] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const changeStatusUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    let data = {
      id: user.id,
    };
    let url = "";
    if (user.isActive == true) {
      url = environment.UnActiveUser;
    } else {
      url = environment.ActiveUser;
    }
    post(url, data, config, (res) => {
      toast.success("تم تغيير حالة المستخدم", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.location.reload(false);
    });
  };

  const handleChange = (checked) => {
    setOpenChangeStatus(true, setChecked(checked));
  };
  return (
    <>
      <div className="flex justify-center items-center space-x-2">
        <div></div>
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <CModal
          status={true}
          confirmModal={() => changeStatusUser()}
          open={openChangeStatus}
          setOpen={() => setOpenChangeStatus(false)}
          subTitle={`${t(
            "modules.main.users.users.messages.confirmMessageStatus"
          ) +
            " " +
            user.name}!`}
        />
        {permissionsCheck("Pages.Identities.Status") ? (
          <ReactSwitch
            onChange={handleChange}
            checked={user.isActive}
            onColor={"#1F9254"}
            offColor="#E92C2C"
          />
        ) : null}
      </div>
    </>
  );
}

export default List;
