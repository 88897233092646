import { ReactComponent as HomepageIcon } from "../../../assets/icons/BI/homepage.svg";
import { ReactComponent as StartingPhaseIcon } from "../../../assets/icons/BI/starting-phase.svg";
import { ReactComponent as FirstVerificationIcon } from "../../../assets/icons/BI/first-verification-phase.svg";
import { ReactComponent as DataAnalysisIcon } from "../../../assets/icons/BI/data-analysis.svg";
import { ReactComponent as FinalResultsIcon } from "../../../assets/icons/BI/final-results.svg";
import { ReactComponent as StepsIcon } from "../../../assets/icons/BI/steps.svg";

const NavigationIcon = ({ icon, iconType = "fill" }) => {
  const Icon = fetchIcon();

  function fetchIcon() {
    switch (icon) {
      case "homepage":
        return HomepageIcon;
      case "starting-phase":
        return StartingPhaseIcon;
      case "first-verification":
        return FirstVerificationIcon;
      case "data-analysis":
        return DataAnalysisIcon;
      case "final-results":
        return FinalResultsIcon;
      case "steps":
        return StepsIcon;
      default:
        return "";
    }
  }

  return (
    <>
      {Icon ? (
        <Icon
          className={`group-[.active]:[&_path]:${iconType}-white [&_path]:${iconType}-blue w-6 h-6`}
        />
      ) : null}
    </>
  );
};

export default NavigationIcon;
