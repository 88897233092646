import DashboardTab from "../DashboardTab/DashboardTab";

const DashboardTabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="shadow-[0px_1px_2px_0px_#1018280D] overflow-hidden border border-[#D0D5DD] rounded-lg">
      {tabs.map((tab, index) => (
        <DashboardTab
          key={index}
          tab={tab}
          isActive={activeTab === tab.id}
          isLast={index === tabs.length - 1}
          setActiveTab={() => setActiveTab(tab.id)}
        />
      ))}
    </div>
  );
};

export default DashboardTabs;
