import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { AddStandardContext } from "../addStandardContext";
import AddStandardDimensions from "./addStandardDimensions";
import StandardRequirementList from "./standardRequirementList";
import { useQuery } from "@tanstack/react-query";
import { axiosGetRequest } from "../../../../../../services/Request";
import environment from "../../../../../../services/environment";

const StandardRequirements = ({ disabled = true, from }) => {
  const { surveyId } = useParams();
  const { getClassifications } = useContext(AddStandardContext);

  useEffect(() => {
    getClassifications(surveyId);
  }, []);

  const { data } = useQuery({
    queryKey: ["getStructureSubStandardRequirementDirectly"],
    queryFn: () =>
      axiosGetRequest(environment.getStructureSubStandardRequirementDirectly, {
        surveyId,
      }),
  });

  const isSpecial = data?.result?.structureSubStandardRequirementDirectly; 
  return (
    <div className="mt-8">
      {!isSpecial && <h2 className="font-semibold mb-4">بيانات البعد</h2>}
      {!disabled && !isSpecial && <AddStandardDimensions from={from} />}
      <StandardRequirementList disabled={disabled} isSpecial={isSpecial} />
    </div>
  );
};

export default StandardRequirements;
