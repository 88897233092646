import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
  const data = {
    datasets: [
      {
        data: [200, 50, 100, 40, 120],
        backgroundColor: [
          "#00ABAF",
          "#B40000",
          "#FF8658",
          "#11A16C",
          "#FFA92B",
        ],
        borderWidth: 0,
      },
    ],
  };

  const config = {
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const labels = [
              " التكامل",
              " البناء",
              " الإتاحة",
              " الايداع",
              " التحسين",
            ];
            const label = labels[tooltipItem.dataIndex];
            return ` ${label}`;
          },
        },
      },
    },
  };

  return <Doughnut data={data} options={config} />;
};

export default DoughnutChart;
