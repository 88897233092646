import React, { useContext } from "react";
import StandardSubRequirementItem from "./subRequirementItem";
import { AddStandardContext } from "../../addStandardContext";

const SubRequirementList = ({ subReq, requirementIndx, disabled }) => {
  const { addFinalValue } = useContext(AddStandardContext);
  const renderedSubRequirements =
    addFinalValue.standardRequirements[requirementIndx].subStandardRequirements
      ?.length > 0 &&
    addFinalValue.standardRequirements[
      requirementIndx
    ].subStandardRequirements.map((item, indx) => (
      <StandardSubRequirementItem
        key={indx}
        {...item}
        requirementIndx={requirementIndx}
        index={indx}
        number={
          addFinalValue.standardRequirements[requirementIndx]
            .subStandardRequirements?.length -
          (indx + 1) +
          1
        }
        disabled={disabled}
      />
    ));
  return (
    <div>
      {renderedSubRequirements || (
        <p className="text-sm">
          لا يوجد أسئلة
        </p>
      )}
    </div>
  );
};

export default SubRequirementList;
