import DataTable from 'react-data-table-component';
import { emptyTable } from '../assets/imgs/Index';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-skeleton-loader';

const customStyles = {
  table: {
    style: {},
  },
  rows: {
    style: {
      color: "black",
      fontSize: 14,
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "0px",
        borderBottomColor: "white",
      },
    },
  },
  headRow: {
    style: {},
  },
  rows: {
    style: {
      color: "black",
      backgroundColor: "white",
      minHeight: "72px",
      color: "black",
      fontSize: 14,
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "0px",
        borderBottomColor: "white",
      },
    },
    stripedStyle: {
      color: "black",
      backgroundColor: "#F4F7FE",
      minHeight: "72px",
      color: "black",
      fontSize: 14,
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "0px",
        borderBottomColor: "white",
      },
    },
  },
  headCells: {
    style: {
      padding: "8px",
      backgroundColor: "#2B2969",
      minHeight: "40px",
      display: "flex",
      justifyContent: "center",
      color: "#FFFFFF",
    },
  },
  cells: {
    style: {
      padding: "8px",
      minHeight: "72px",
      display: "flex",
      justifyContent: "center",
    },
  },
  pagination: {
    style: {
      color: "#656565",
      fontSize: "13px",
      minHeight: "56px",
      backgroundColor: "white",
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "white",
      borderRadius: "15px",
      marginTop: "3%",
    },
    pageButtonsStyle: {
      borderRadius: "50%",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "px",
      cursor: "pointer",
      transition: "0.4s",
      color: "#656565",
      fill: "#656565",
      backgroundColor: "transparent",
    },
  },
};

const Table = ({
  columns,
  data,
  selectableRows,
  currentPage,
  onPageChange,
  totalPages,
  table,
  other,
  loading,
  pagination,
  handlePerRowsChange,
  handlePageChange,
  totalRows,
}) => {
  console.log(totalPages, loading);
  const { t } = useTranslation();
  return (
    <div>
      {loading ? (
        <Skeleton
          animated={true}
          color={"#2B296969"}
          width={"100%"}
          height={"500px"}
          count={1}
        />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={
            <div className="flex flex-col items-center content-center p-5">
              <img src={emptyTable} />
              <span className="text-base text-[#656565]">
                {t("general.noFoundTable")}
              </span>
            </div>
          }
          customStyles={customStyles}
          selectableRows={selectableRows}
          striped={true}
        />
      )}

      {pagination == false ? null : (
        <div className="w-[100%] m-auto bg-white p-5">
          <ReactPaginate
            nextLabel={loading == true ? null : t("general.next")}
            onPageChange={onPageChange}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel={loading == true ? null : t("general.previous")}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
