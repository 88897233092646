// import openBook from "../../../../../../../assets/icons/openBook.svg";
import requirements from "../../../../../../../assets/icons/requirements.svg";

const RequirementHeader = ({ label = "الأسئلة" }) => {
  return (
    <div className="w-[100%]  flex flex-row justify-between items-start">
      <div className="flex flex-row items-center">
        <img
          className="bg-transparent ml-2"
          src={requirements}
          alt="requirements"
        />
        <div>
          <h1 className="text-[#292069] font-black text-[20px] mr-2">
            {label}
          </h1>
          {/* <div className="flex flex-row mr-2 mt-2">
            <img className="bg-transparent ml-2" src={openBook} alt="book" />
            <p className="text-[#292069] text-[15px] mb-2">
              تمكنك من مشاهدة إرشادات المؤسسة بجوار كل سؤال
            </p>
          </div> */}
          <div />
        </div>
      </div>
    </div>
  );
};

export default RequirementHeader;
