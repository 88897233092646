type tenants = "Qiays" | "ET" 
const Tenant: tenants = "ET"



const hideFeatures = {
    "PREVIOUS_SURVEYS": ["ET"],
    "VISITS_AND_MEETINGS": ["ET"],
    "TICKETS": ["ET"],
    "NOTIFICATIONS": ["ET"],
    "REPORTS": ["ET"],
    "AI": ["ET"],
    "SETTINGS": ["ET"]
}

type feature = keyof typeof hideFeatures

function createIsFeatureForTenant(tenant: tenants) {
    return function isFeatureHidden(feature: feature): boolean {
        return hideFeatures[feature].includes(tenant)
    }
}


export const isFeatureHidden = createIsFeatureForTenant(Tenant)


function createFunctionToGetImages(tenant: tenants) {
    return function getImage(imageName: string): string {
        return `/${tenant}/${imageName}`
    }
}

export const getImage = createFunctionToGetImages(Tenant)
