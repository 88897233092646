import AppRadio from "../../../../../../../components/form/appRadio";
import AppTextarea from "../../../../../../../components/form/appTextarea";

const SubRequirement = ({
  title = "",
  checked,
  onChange,
  subRequirementDonotApplyChecked,
  handleDonotApplySubRequirementChange,
  readOnly = false,
  disableDonotApply = false,
  handleSubReqChange = () => {},
  ...subRequirement
}) => {
  console.log("checked", checked)
  if (subRequirement.requirementType === 2) {
    return (
      <div className="px-5 my-3 flex flex-col mb-3 py-2">
        <h2>{title}</h2>
        <div className="flex flex-col gap-4">
          <br />
          {range(subRequirement.numOfTextFields).map((i) => {
            return (
              <div className="border-2 border-dashed">
                <AppTextarea
                  // label={}
                  name={title}
                  onChange={(e) =>
                    handleSubReqChange(
                      subRequirement.id,
                      "textFields",
                      e.target.value,
                      i
                    )
                  }
                  isRequired={true}
                  value={(subRequirement.textFields || [])[i]}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 px-5 my-3 flex flex-row mb-3 py-2">
      <div className="flex flex-col items-start w-full">
        {/* <label className="text-[16px] w-[85%] mr-3 mb-1 flex-grow">
          {title}
        </label> */}
        {/* <div className="mr-3">
          <NewTooltip content="لا ينطبق" placement="right">
            <AppSwitch
              checked={subRequirementDonotApplyChecked}
              handleChange={handleDonotApplySubRequirementChange}
              disabled={disableDonotApply}
            />
          </NewTooltip>
        </div> */}

        {/* <input
          type="checkbox"
          className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-lg w-[24px] mt-2 h-[24px]"
          checked={checked}
          onChange={onChange}
          disabled={readOnly}
        /> */}

        <h2 className="mb-8 text-lg text-blue-text ">
          {title}
        </h2>

        <AppRadio
          label="نعم"
          name={"decision"}
          id={1}
          value="1"
          checked={checked === 1}
          onChange={onChange}
        />
        <AppRadio
          label="لا"
          name={"decision"}
          id={2}
          value="2"
          checked={checked === 0}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SubRequirement;

function range(to) {
  return new Array(to).fill(0).map((_, i) => i);
}
