import * as Yup from "yup";

export const addStandardValidations = Yup.object().shape({
  title: Yup.string().required("يجب ادخال اسم المعيار"),
  // titleArabic: "",
  royalOrdersAndCabinetDecisions: "",
  answerWay: "",
  // code: Yup.string()
  //   .matches(/^\d+\.\d+\.\d+$/, "تنسيق رقم المعيار على الطريقة 1.0.0")
  //   .required("يجب ادخال رقم المعيار"),
  target: Yup.string().required("يجب ادخال وصف المعيار"),
  // weight: Yup.number()
  //   .nullable()
  //   .transform((value, originalValue) => {
  //     if (originalValue === "") {
  //       return null;
  //     }
  //     return value;
  //   })
  //   .required("يجب ادخال وزن المعيار")
  //   .min(0, "اقل وزن هو صفر")
  //   .max(100, "أعلى وزن هو 100"),
  // standardRelations: Yup.array().nullable(),
  // agencyStandards: Yup.array()
  //   .min(1, "يجب ادخال جهة حكومية واحدة على الأقل")
  //   .required("يجب ادخال جهة حكومية واحدة على الأقل"),
  // commonAgencies: Yup.array().nullable(),
  // mainAgency: Yup.object().when("commonAgencies", {
  //   is: (array) => array && array.length > 0,
  //   then: (schema) => schema.required("يجب اختيار جهة رئيسية"),
  //   otherwise: (schema) => schema.nullable(),
  // }),
});

export const addStandardRequirementValidations = Yup.object().shape({
  title: Yup.string().required("يجب ادخال عنوان البعد"),
  recommendation: Yup.string().required("يجب ادخال التوصية"),
});

export const addStandardProofsValidations = Yup.object().shape({
  title: Yup.string().required("يجب ادخال اسم المستند"),

  // standardProofTypeId: Yup.string().required("يجب اختيار القيود"),
  // standardProofFileSizeTypeId: Yup.string()
  //   .nullable()
  //   .transform((value, originalValue) => {
  //     if (originalValue === "") {
  //       return null;
  //     }
  //     return value;
  //   })
  //   .required("يجب اختيار نوع حجم الملف"),
  // standardProofFileTypeAssociations: Yup.array()
  //   .min(1, "يجب اختيار نوع الملف")
  //   .required("يجب اختيار نوع الملف"),
});
