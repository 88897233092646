import { toast } from "react-toastify";
import { axiosPostRequest, axiosPutRequest } from "../../../services/Request";
import environment from "../../../services/environment";
import ApproveIcon from "../../../components/approveIcon";
import EditIcon from "../../../components/EditIcon";
import DeleteIcon from "../../../components/DeleteIcon";
import UploadAdapter from "../../../components/UploadAdapter";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState } from "react";

function ReplyActions({ reFetching, item }) {
  const [editedDescription, setEditedDescription] = useState("");

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  // -----------------------UPDATE COMMENT---------------------------- //
  const updateReply = async (itemId, newDescription) => {
    setEditLoading(true);
    const res = await axiosPutRequest(environment.updateReplyComment, {
      id: itemId,
      comment: newDescription,
    });

    if (res?.success) {
      toast.success("تم تعديل الرد بنجاح");
      reFetching();
    }
    setEditLoading(false);
  };
  // -----------------------UPDATE COMMENT---------------------------- //

  // -----------------------Reject COMMENT---------------------------- //
  const rejectReply = async (itemId) => {
    setDeleteLoading(true);
    const res = await axiosPostRequest(environment.rejectTicketReply, null, {
      id: itemId,
    });

    if (res?.success) {
      toast.success("تم رفض الرد");
      reFetching();
    }
    setDeleteLoading(false);
  };
  // -----------------------Reject COMMENT---------------------------- //

  // -----------------------Approve COMMENT---------------------------- //
  const approveReply = async (itemId) => {
    setApproveLoading(true);
    const res = await axiosPostRequest(environment.approveTicketReply, null, {
      id: itemId,
    });

    if (res?.success) {
      toast.success("تم قبول الرد بنجاح");
      reFetching();
    }
    setApproveLoading(false);
  };
  // -----------------------approve COMMENT---------------------------- //
  return (
    <>
      <ApproveIcon
        subTitle="هل تريد قبول هذا الرد؟"
        loading={approveLoading}
        onClick={() => approveReply(item.id)}
      />

      <EditIcon
        editFunction={() => updateReply(item.id, editedDescription)}
        loading={editLoading}
        initialValuesFunction={() => setEditedDescription(item.comment)}
        headerTitle={"تعديل الرد"}
        editModalContent={
          <div>
            <div className="py-5 px-1">
              <label className="text-violet-950 text-lg ml-4">الرد</label>
            </div>

            <div className="p-0 w-full ">
              <CKEditor
                editor={ClassicEditor}
                data={editedDescription}
                onReady={(editor) => {
                  setEditedDescription(editor.getData());
                  editor.plugins.get(
                    "FileRepository"
                  ).createUploadAdapter = function(loader) {
                    console.log(loader);
                    return new UploadAdapter(loader);
                  };
                }}
                config={{
                  language: "ar",
                  simpleUpload: {
                    uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
                    withCredentials: true,
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  },
                  styles: {
                    height: "100px",
                    margin: "50px",
                  },
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditedDescription(data);
                  editor.plugins.get(
                    "FileRepository"
                  ).createUploadAdapter = function(loader) {
                    console.log(loader);
                    return new UploadAdapter(loader);
                  };
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </div>
          </div>
        }
      />
      <DeleteIcon
        tooltipContent="رفض"
        subTitle="هل تريد رفض هذا الرد؟"
        deleteFunction={() => rejectReply(item.id)}
        loading={deleteLoading}
      />
    </>
  );
}

export default ReplyActions;
