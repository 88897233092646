import React, { useContext, useState } from "react";
import {
  AppInput,
  DropdownMultiSelect,
  InputLabel,
} from "../../../../../../../components";
import AppButton from "../../../../../../../components/buttons/appButton";
import { AddStandardContext } from "../../addStandardContext";
import { produce } from "immer";
import { errorToast } from "../../../../../../../helpers/toast";
import NewDropdownMultiSelect from "../../../../../../../components/NewDropDownMultiSelect";

const AddQuestion = ({ subReq, requirementIndx }) => {
  const { setSubRequirementCount, setAddFinalValue } =
    useContext(AddStandardContext);

  const [values, setValues] = useState({
    title: "",
    recommendation: "",
    subStandardRequirementAnswerMandatory: "",
    subStandardRequirementType: "",
    subStandardRequirementHaveAttachments: "",
    numOfTextFields: 1,
  });

  const [errors, setErrors] = useState({
    title: "",
    recommendation: "",
    subStandardRequirementAnswerMandatory: "",
    subStandardRequirementType: "",
    subStandardRequirementHaveAttachments: "",
  });

  const [selectedClassifications, setSelectedClassifications] = useState("");

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    setValues({
      ...values,
    });
  };

  const handleChangeSubStandardRequirementAnswerMandatory = (value) => {
    setValues({
      ...values,
      subStandardRequirementAnswerMandatory: value,
    });
  };

  const handleChangeSubStandardRequirementType = (value) => {
    setValues({
      ...values,
      subStandardRequirementType: value,
    });
  };

  const handleChangeSubStandardRequirementHaveAttachments = (value) => {
    setValues({
      ...values,
      subStandardRequirementHaveAttachments: value,
    });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (!values?.title || !values?.recommendation) {
      errorToast("يجب ادخال البيانات المطلوبة للسؤال ");
      return;
    }

    if (
      values.numOfTextFields < 1 &&
      values.subStandardRequirementType?.value === 2
    ) {
      errorToast("يجب ادخال عدد النصوص");
      return;
    }

    setSubRequirementCount((prev) => prev + 1);

    setAddFinalValue(
      produce((draft) => {
        const newSubRequirement = {
          title: values.title,
          recommendation: values.recommendation,
          numOfTextFields: values.numOfTextFields,
          answerMandatory:
            values.subStandardRequirementAnswerMandatory?.value ?? 1,
          requirementType: values.subStandardRequirementType?.value ?? 1,
          haveAttachments:
            values.subStandardRequirementHaveAttachments?.value ?? 1,
        };

        if (
          !draft.standardRequirements[requirementIndx].subStandardRequirements
        ) {
          draft.standardRequirements[requirementIndx].subStandardRequirements =
            [];
        }
        draft.standardRequirements[
          requirementIndx
        ].subStandardRequirements.push(newSubRequirement);
      })
    );

    setValues({
      title: "",
      recommendation: "",
      subStandardRequirementAnswerMandatory: "",
      subStandardRequirementType: "",
      subStandardRequirementHaveAttachments: "",
      numOfTextFields: 1,
    });
    setSelectedClassifications("");
  };

  function handleNumberOfTextBoxes(e) {
    const numOfTextFields = e.target.value;
    console.log("numOfTextFields", numOfTextFields);
    setValues({
      ...values,
      numOfTextFields: numOfTextFields,
    });
  }

  return (
    <div className="bg-white p-5 pb-7 rounded-lg mt-7">
      <h2 className="font-semibold mb-4">إضافة سؤال </h2>

      <div className="row gap-y-3">
        {/* <div className="col-md-6 ">
          <AppInput
            variant="gray"
            label="عنوان"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className="col-xl-5">
          <AppInput
            variant="gray"
            label="التوصية"
            placeholder="اكتب هنا"
            name="recommendation"
            value={values.recommendation}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedClassifications}
            handleChange={handleChangeClassifications}
            label="تصنيف"
            placeholder="اكتب هنا"
            options={classificationOptions}
            isMultiple={false}
          />
        </div> */}
        <div className="col-md-5 ">
          <AppInput
            variant="gray"
            label="عنوان السؤال"
            placeholder="اكتب هنا"
            name="title"
            value={values.title}
            onChange={handleChange}
            // errorMessage={errors.title}
          />
        </div>
        <div className="col-md-5 ">
          <AppInput
            variant="gray"
            label="وصف السؤال"
            placeholder="اكتب هنا"
            name="recommendation"
            value={values.recommendation}
            onChange={handleChange}
            // errorMessage={errors.title}
          />
        </div>
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={values.subStandardRequirementAnswerMandatory}
            handleChange={handleChangeSubStandardRequirementAnswerMandatory}
            label="هل الإجابة على السؤال اختياري، أم إجباري"
            placeholder="اجباري"
            options={[
              { label: "اجباري", value: 1 },
              { label: "اختياري", value: 2 },
            ]}
            isMultiple={false}
            isRequired={true}
            // errorMessage={errors.standardRequirementClassificationId}
          />
        </div>
        {/* {from === "add" ? null : (
          <div className="col-xl-5">
            <AppInput
              variant="gray"
              label="رقم السؤال"
              placeholder="اكتب هنا"
              name="َrecommendation"
              onChange={handleChange}
              errorMessage={errors.recommendation}
            />
          </div>
        )} */}
        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={values.subStandardRequirementType}
            handleChange={handleChangeSubStandardRequirementType}
            label="نوع السؤال"
            placeholder="اختار نوع السؤال"
            //  openEneded Questions and close ended questions
            options={[
              { label: "نعم او لا ", value: 1 },
              { label: "نص ", value: 2 },
            ]}
            isMultiple={false}
            isRequired={true}
            // errorMessage={errors.standardRequirementClassificationId}
          />
        </div>
        {/* Number of text fields */}
        {values.subStandardRequirementType?.value === 2 && (
          <div className="col-md-5">
            <InputLabel
              min={1}
              value={values.numOfTextFields}
              type={"number"}
              onChange={handleNumberOfTextBoxes}
              name={"numOfTextFields"}
              label={"عدد النصوص"}
            />
          </div>
        )}

        <div className="col-md-5">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            value={values.subStandardRequirementHaveAttachments}
            handleChange={handleChangeSubStandardRequirementHaveAttachments}
            label="هل له مرفقات"
            options={[
              { label: "نعم", value: 1 },
              { label: "لا", value: 2 },
            ]}
            isMultiple={false}
            isRequired={true}
            // errorMessage={errors?.agencyStandardRequirements}
          />
        </div>
      </div>

      <div className="row gap-y-4 mt-8">
        <div className="col-xl-3 align-self-end">
          <div className="text-end">
            <AppButton onClick={handleSubmit}>إضافة سؤال</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestion;
