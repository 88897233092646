import { useEffect, useState, useCallback } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNav/SideNav";
import { NAV_LINKS } from "../../models/SideNav";
import useQueryParams from "../../../../hooks/useQueryParams";

const navLinks = NAV_LINKS.flatMap((link) => {
  return link.children
    ? link.children.map((child) => ({ ...child, parentPath: link.path }))
    : [{ ...link, parentPath: null }];
});

const Layout = () => {
  const [currentLink, setCurrentLink] = useState({});

  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();

  const setValueToQuery = useCallback(
    (key, value) => {
      const params = new URLSearchParams({ [key]: value });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [history, location.pathname]
  );

  const handleRouteChange = useCallback(() => {
    const path = location.pathname;
    const currentLink = navLinks.find((item) => path.startsWith(item.path));

    setCurrentLink(currentLink);
  }, [location.pathname]);

  useEffect(() => {
    // Handle Route Change to set the title property to be displayed on Header
    handleRouteChange();

    if (!query.get("year")) {
      setValueToQuery("year", 2024);
    }

    // Set Window Title based on current Child Route
    const setWindowTitle = () => (document.title = currentLink?.label || "BI");
    setWindowTitle();
    return setWindowTitle;
  }, [query, setValueToQuery, handleRouteChange, currentLink?.label]);

  return (
    <div className="flex min-h-screen gap-8 bg-blue">
      <SideNav />
      <main className="flex flex-col flex-1 gap-6">
        <Header link={currentLink} />
        <Switch>
          {navLinks.map((link) => (
            <Route
              key={link.id}
              path={link.path}
              render={(props) => (
                <link.component {...props} year={query.get("year")} />
              )}
            />
          ))}
          <Redirect to="/bi/final-results/all" />
        </Switch>
      </main>
    </div>
  );
};

export default Layout;
